<template>
  <div class="AccordionItem" :class="{open: current === index, 'show-prefix': showOrder || hasImage, 'prefix-order': showOrder, 'prefix-image': hasImage}">
    <header ref="header" :class="['header', {disabled: disabled}]" @click="!disabled ? toggleItem() : null">
      <template v-if="showOrder || hasImage">
        <div
          v-if="hasImage"
          class="img-wrapper"
        >
          <slot name="image"></slot>
        </div>
        <div
          v-else
          class="order"
        >
          {{ visualOrder }}.
        </div>
      </template>
      <div class="header-wrapper">
        <slot name="header"></slot>
      </div>
      <icon v-if="!disabled" class="arrow" name="angle-down"></icon>
    </header>
    <div
      v-if="!disabled"
      ref="content"
      class="expandable"
      v-expand="index === current"
    >
      <div class="content">
        <slot name="content"></slot>
        <div class="mt-4 slot-wrapper" v-if="!!$slots['link']">
          <slot name="link"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/atoms/icon';
import { MobileHandlers } from '@mixins/mobile-handlers';
import ViewportMixins from '@mixins/viewport_mixins';

export default {
  name: 'AccordionItem',
  components: {Icon},
  mixins: [
    MobileHandlers,
    ViewportMixins
  ],
  data() {
    return {
      visualOrder: null
    };
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    showOrder: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    current(value) {
      if (value === this.index) {
        this.$refs['content'].addEventListener('transitionend', this.scrollInViewport);
      }
    }
  },
  computed: {
    current() {
      return this.$parent.current;
    },
    hasImage() {
      return !!(this.$slots['image'] || [])[0];
    }
  },
  methods: {
    scrollInViewport() {
      if (!this.isElementXPercentInViewport(this.$refs['header'], 100)) {
        this.$scrollTo(this.$refs['header'], 800, {
          easing: 'ease-out',
          cancelable: false,
          offset: this.isTablet ? -70 : -125
        });
      }
      this.$refs['content'].removeEventListener('transitionend', this.scrollInViewport);
    },
    getIndex(node) {
      let children = node.parentNode.childNodes;
      let num = 0;
      for (let i = 0; i < children.length; i++) {
        if (children[i] === node) {
          return num;
        }
        // Count only AccordionItem element for inline editing
        if (children[i].nodeType === 1 && children[i].className.indexOf('AccordionItem') > -1) {
          num++;
        }
      }
      return -1;
    },
    toggleItem() {
      this.$emit('accordion-toggled', {
        index: this.index,
        slug: this.slug,
        event: (this.index === this.current) ? 'close' : 'open'
      });
      this.$parent.$emit('accordion-toggled', this.index);
    }
  },
  mounted() {
    this.visualOrder = this.showOrder ? this.getIndex(this.$el) + 1 : null;
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";
@import "~$scss/utility";

.show-prefix {
  .header {
    align-items: center;
  }
}

.header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  transition: margin-bottom .5s ease;

  @media (min-width: $bootstrap-lg) {
    padding: 30px 0;
  }

  &.disabled {
    justify-content: normal;

    .header-wrapper {
      width: 100%;
    }
  }
}

.invisible {
  opacity: 0
}

.show-prefix {
  &.prefix-order {
    $order-font-sizes: (
      $bootstrap-sm: 24px,
      $bootstrap-md: 32px,
      $bootstrap-lg: 40px
    );

    $order-widths: (
      $bootstrap-sm: 55px,
      $bootstrap-md: 90px,
      $bootstrap-lg: 110px
    );

    .order {
      @include fontCaecilia();
      font-size: 40px;
      line-height: 52px;
      @include responsive-property(font-size, $order-font-sizes);
      @include responsive-property(flex-basis, $order-widths);
      flex-shrink: 0;
      flex-grow: 0;
      color: $color-blue;
    }

    .expandable .content {
      @include responsive-property(margin-left, $order-widths);
    }
  }

  &.prefix-image {
    $image-widths: (
      $bootstrap-sm: 70px,
      $bootstrap-md: 90px,
      $bootstrap-lg: 110px,
      $bootstrap-xl: 130px,
    );

    .img-wrapper {
      @include responsive-property(flex-basis, $image-widths);
      flex-shrink: 0;
      flex-grow: 0;
      padding-right: 20px;

      img,
      .icon {
        max-width: 100%;
        height: auto;
      }
    }

    .expandable .content {
      @include responsive-property(margin-left, $image-widths);
    }
  }

  .header-wrapper {
    flex-grow: 1;
  }
}

.arrow {
  display: block;
  font-size: 48px;
  color: $color-blue;
  transition: transform .3s ease;
}

.content {
  padding-bottom: 32px;
}

.AccordionItem {
  border-bottom: 1px solid $color-grey-medium-2;

  &:first-child {
    border-top: 0;
  }

  &.open {
    > .header {
      margin-bottom: -16px;

      .arrow {
        transform: rotate(180deg);
      }
    }
  }
}

// Theme: tall-heading
.AccordionItem.tall-heading.open > .header {
  margin-bottom: 0;
}

// Theme: short-content
.AccordionItem.short-content > .expandable > .content {
  padding-bottom: 0;
}
</style>
