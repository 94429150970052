<template>
  <article
    class="SocialCard d-flex flex-column"
    role="feed"
  >
    <figure class="user">
      <div class="square-pic">
        <img class="pic" :src="image.src" :alt="image.alt">
      </div>
      <figcaption class="user-details">
        <slot name="user-details"></slot>
      </figcaption>
      <a class="external-link" :href="link" target="_blank">
        <icon name="arrow-top-right"></icon>
      </a>
    </figure>

    <div class="content flex-fill">
      <slot name="content"></slot>
    </div>

    <div class="hashtags">
      <slot name="hashtags"></slot>
    </div>
  </article>
</template>

<script>
  import Icon from '@components/atoms/icon'

  export default {
    name: 'SocialCard',
    components: {
      Icon
    },
    props: {
      image: {
        type: Object,
        required: true
      },
      link: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/_variables";

  .SocialCard {
    padding: 25px;
    border: 1px solid fade-out($color-grey, 0.75);
    background-color: $color-white;
    min-height: 100%;
    box-sizing: border-box;
  }

  .square-pic {
    position: relative;
    display: inline-block;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    overflow: hidden;

    .pic {
      position: absolute;
      max-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .user {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  .user-details {
    margin-left: 15px;

    .name {
      font-size: 16px;
      line-height: 1.2em;
      margin-bottom: 4px;
    }

    .id {
      color: $color-grey;
      font-size: 14px;
      line-height: 1.2em;
    }
  }

  .external-link {
    align-self: flex-start;
    margin-left: auto;
  }

  .content {
    margin-bottom: 14px;
  }

  .hashtags {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-right: .5em;
      color: $color-blue;
    }
  }
</style>
