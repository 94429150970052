export function loadComponents (ctx) {
  let componentList = {}
  ctx.keys().forEach(key => {
    let compOptions = ctx(key).default
    componentList[compOptions.name] = compOptions
  })

  return componentList
}

export const components = loadComponents(require.context('@components/', true, /\.vue$/));
