<template>
  <aside v-if="show" class="ShareBar">
    <ul class="share-buttons">
      <!--      <li class="share-button" v-if="shareOptions.linkedin">-->
      <!--        <a @click.prevent="shareWindow(linkedInUrl)" href="#">-->
      <!--          <icon name="linkedin"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="share-button" v-if="shareOptions.twitter">-->
      <!--        <a @click.prevent="shareWindow(twitterUrl)" href="#">-->
      <!--          <icon name="twitter"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="share-button" v-if="shareOptions.instagram">-->
      <!--        <a @click.prevent="shareWindow(instagramUrl)" href="#">-->
      <!--          <icon name="instagram"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="share-button" v-if="shareOptions.facebook">-->
      <!--        <a :href="facebookUrl" target="_blank">-->
      <!--          <icon name="facebook"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="share-button" v-if="shareOptions.whatsapp">-->
      <!--        <a :href="whatsappUrl" target="_blank">-->
      <!--          <icon name="whatsapp"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="share-button" v-if="shareOptions.telegram">-->
      <!--        <a :href="telegramUrl" target="_blank">-->
      <!--          <icon name="telegram"></icon>-->
      <!--        </a>-->
      <!--      </li>-->
      <li class="share-button" v-if="shareOptions.email">
        <a :href="mailUrl" target="_blank">
          <icon name="envelope"></icon>
        </a>
      </li>
      <li class="share-button" v-if="shareOptions.print">
        <a href="#" @click.prevent="print">
          <icon name="print"></icon>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
  import Icon from '@components/atoms/icon'
  import IconLink from '@components/atoms/icon-link'

  export default {
    name: 'ShareBar',
    components: {Icon, IconLink},
    props: {
      shareOptions: {type: Object, default: () => ({})}
    },
    computed: {
      show () {
        return Object.keys(this.shareOptions).length > 0
      },
      linkedInUrl () {
        const linkedin = this.shareOptions.linkedin
        return linkedin ?
          `https://www.linkedin.com/shareArticle?mini=true&url=${linkedin.url}` :
          null
      },
      twitterUrl () {
        const twitter = this.shareOptions.twitter
        return twitter ?
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitter.text)}&url=${twitter.url}` :
          null
      },
      instagramUrl () {
        // TODO Instagram url
        const instagram = this.shareOptions.instagram
        return instagram ?
          `#` :
          null
      },
      whatsappUrl () {
        const whatsapp = this.shareOptions.whatsapp
        return whatsapp ?
          `whatsapp://send?text=${encodeURIComponent(whatsapp.text)} ${encodeURIComponent(whatsapp.url)}` :
          null
      },
      facebookUrl () {
        const facebook = this.shareOptions.facebook
        return facebook ?
          `https://www.facebook.com/sharer/sharer.php?u=${facebook.url}` :
          null
      },
      telegramUrl () {
        const telegram = this.shareOptions.telegram
        return telegram ?
          `https://telegram.me/share/url?url=${telegram.url}&text=${telegram.text}` :
          null
      },
      mailUrl () {
        const email = this.shareOptions.email
        return email ?
          `mailto:?subject=${encodeURIComponent(email.subject)}&body=${encodeURIComponent(email.body)}` :
          null
      }
    },
    methods: {
      shareWindow (url) {
        let screenWidth = screen.width,
          screenHeight = screen.height,
          popupWidth = screenWidth - (screenWidth * .2),
          popupHeight = screenHeight - (screenHeight * .2),
          left = (screenWidth / 2) - (popupWidth / 2),
          top = (screenHeight / 2) - (popupHeight / 2),
          parameters = `toolbar=0,status=0,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`

        return window.open(url, '', parameters) && false
      },
      print () {
        window.print()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';

  .share-buttons {
    display: flex;
    margin: 0 -0.5em 40px;

    @media (min-width: $bootstrap-md) {
      margin: 0 -0.5em 30px;
    }

    .share-button {
      padding: 0 0.5em;
      font-size: 20px;
      text-align: center;
      color: fade-out($color-dark, 0.5);
      transition: color 0.25s ease-out;

      &:hover {
        color: $color-dark;
      }
    }
  }
</style>
