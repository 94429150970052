<template>
<div class="SlideBox d-md-flex flex-md-column">
  <container class="py-0 overflow-visible flex-md-fill height-fill" :background-image="bgImages">
    <div class="main-row row align-items-end align-items-md-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="box bg-color-white">
          <slot name="box"></slot>
        </div>
      </div>
    </div>
  </container>
</div>
</template>

<script>
import Container from "@components/layout/container"

export default {
  name: 'SlideBox',
  components: { Container },
  props: {
    image: { type: String | null, default: null },
    imageXs: { type: String | null, default: null },
    imageSm: { type: String | null, default: null },
    imageMd: { type: String | null, default: null },
    imageLg: { type: String | null, default: null },
    imageXl: { type: String | null, default: null },
  },
  computed: {
    bgImages() {
      return this.$viewportWidth < 576 && this.imageXs ? this.imageXs :
        this.$viewportWidth < 768 && this.imageSm ? this.imageSm :
        this.$viewportWidth < 992 && this.imageMd ? this.imageMd :
        (this.$viewportWidth >= 992 && this.$viewportWidth < 1332) && this.imageLg ? this.imageLg :
        this.$viewportWidth >= 1332 && this.imageXl ? this.imageXl :
        this.image;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~$scss/variables";

.SlideBox {
  padding-bottom: 40px;
  min-height: 100%;

  @media (min-width: $bootstrap-md) {
    padding-bottom: 0;
  }

  &.mobile-home-gutter {
    padding-bottom: 28px;

    @media (min-width: $bootstrap-md) {
      padding-bottom: 0;
    }

    .box {
      margin-right: 16px;
      margin-left: 16px;

      @media (min-width: $bootstrap-md) {
        margin-right: 0;
        margin-left: 6px;
      }
    }

  }
}

.box {
  padding: 35px 25px;
  box-shadow: 0 3px 10px rgba($color-dark, .1);
  margin-bottom: -40px;

  @media (min-width: $bootstrap-md) {
    padding: 40px 60px 47px 60px;
    margin-bottom: auto;
  }
}

.main-row {
  min-height: 460px;
  box-sizing: border-box;
  flex: 1 1 auto;

  @media (min-width: $bootstrap-md) {
    min-height: 680px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
</style>
