<template>
  <div
    class="FlexGrid"
    :style="{
      marginLeft: `${-currentGutter/2}px`,
      marginRight: `${-currentGutter/2}px`,
      marginTop: `${-currentVerticalGutter/2}px`,
      marginBottom: `${-currentVerticalGutter/2}px`,
      justifyContent: `${currentJustifyContent}`,
      alignItems: `${currentAlignItems}`
    }"
  >
    <template v-if="!autoWrap">
      <slot></slot>
    </template>
    <slot-wrapper wrapper-tag="flex-col" v-else>
      <slot></slot>
    </slot-wrapper>
  </div>
</template>

<script>
  import { fallback } from '@helpers/responsive-utilities'
  import SlotWrapper from '@components/layout/slot-wrapper'

  export default {
    name: 'FlexGrid',
    components: {SlotWrapper},
    props: {
      autoWrap: {type: Boolean, default: false},
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      },
      gutter: {type: Object, default: () => ({xs: 24})},
      verticalGutter: {type: Object, default: () => ({xs: 0})},
      columns: {type: Object, default: () => ({xs: 12})},
      justifyContent: {type: Object, default: () => ({xs: 'flex-start'})},
      alignItems: {type: Object, default: () => ({xs: 'flex-start'})}
    },
    computed: {
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      currentColumns () {
        return fallback(this.columns, this.currentStep)
      },
      currentGutter () {
        return fallback(this.gutter, this.currentStep)
      },
      currentVerticalGutter () {
        return fallback(this.verticalGutter, this.currentStep)
      },
      currentJustifyContent () {
        return fallback(this.justifyContent, this.currentStep)
      },
      currentAlignItems () {
        return fallback(this.alignItems, this.currentStep)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .FlexGrid {
    display: flex;
    flex-wrap: wrap;
  }
</style>
