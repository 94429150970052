<template>
  <a :href="href" class="SearchItem">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <div class="content">
            <slot name="date"></slot>
            <div v-if="!!$slots['title']" class="mt-4">
              <slot name="title"></slot>
            </div>
            <div v-if="!!$slots['text']" class="mt-3">
              <slot name="text"></slot>
            </div>
          </div>
          <separator></separator>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
  import Container from "@components/layout/container";
  import Separator from "@components/layout/separator";

  export default {
    name: 'SearchItem',
    components: {Separator, Container},
    props: {
      href: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .content {
    padding-top: 50px;
    padding-bottom: 50px;
    color: $color-blue;
    transition: color .3s ease;
  }

  .SearchItem {
    display: block;
    position: relative;
    z-index: 1;
    background: $color-white;
    transition: box-shadow .5s ease;

    &:hover {
      z-index: 2;
      box-shadow: 0 0 20px rgba($color-dark, .1);

      .content {
        color: $color-blue-lochmara;
      }
    }
  }

</style>