<template>
  <header
    :class="[
      'BaseHeader',
      this.hasCmsMenu ? 'cms-toolbar' : 'no-toolbar',
      { open, onTop, searchMobile }
    ]"
    :style="{
      top: top + 'px',
      backgroundColor: bgColor
    }"
  >
    <div v-if="isTablet" class="bar" :style="{backgroundColor: bgColor}">&nbsp;</div>
    <div class="container">
      <div class="main-row row align-content-center">
        <div class="mcol col col-lg-3 d-flex align-items-center">
          <div class="logo">
            <a :href="homeUrl" class="logo-link" aria-labelledby="company-name">
              <slot name="logo-seo">
                <!-- TODO h1 accessibilita non può essere hidden -->
                <h2 id="company-name" class="hidden">Barilla</h2>
              </slot>
            </a>
          </div>
        </div>
        <nav class="menu d-flex flex-column flex-lg-row col-lg-6 align-items-lg-center justify-content-lg-center"
             aria-label="Main header">
          <div v-if="isTablet" class="menu-header">
            <button type="button" class="menu-toggle" @click="closeMenu">
              <icon name="times"></icon>
              <span class="hidden">{{l10n.menuClose}}</span>
            </button>
            <free-search
              class="mobile"
              :autocomplete-disabled="freeSearchDisableAutocomplete"
              :search-config="searchConfig"
              :use-fixture="freeSearchFixture"
              :l10n="l10n"
              :focused="false"
              @inputfocus="searchMobile = true"
              ref="MobileSearchComp"
            ></free-search>
          </div>
          <ul v-if="!searchMobile" class="menu-items d-lg-flex justify-content-lg-center">
            <li v-for="item in menuOrFixture" class="menu-item"
                :class="{active: isActive(item.id), 'submenu-open': (!hideSubmenu || isTablet) && (!search || isTablet) && isActive(item.id)}">
              <a :href="item.link" :target="item.external ? '_blank' : false" class="menu-item-link">{{item.label}}</a>
              <transition name="submenu">
                <div
                  v-if="(!hideSubmenu || isTablet) && (!search || isTablet) && isActive(item.id) && item.children && item.children.length"
                  class="submenu">
                  <ul class="submenu-items d-lg-flex justify-content-lg-center">
                    <li v-for="subitem in item.children" :class="['submenu-item', {active: isActive(subitem.id)}]">
                      <a :href="subitem.link" :target="subitem.external ? '_blank' : false" class="submenu-item-link">{{subitem.label}}</a>
                    </li>
                    <li v-if="item.cta && isTablet" :class="['submenu-item', {active: isActive(item.cta.id)}]">
                      <a :href="item.cta.link" :target="item.cta.external ? '_blank' : false" class="submenu-item-link">{{item.cta.label}}</a>
                    </li>
                  </ul>
                  <btn v-if="item.cta && !isTablet"
                       :class="['submenu-cta', 'blue-lochmara', 'small', {active: isActive(item.cta.id)}]"
                       :href="item.cta.link"
                       :target="item.cta.external ? '_blank' : false"
                  >{{item.cta.label}}
                  </btn>
                </div>
              </transition>
            </li>
          </ul>
          <div v-if="isTablet" class="menu-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto">
                <!-- <socials :list="socials" position="header"></socials> -->
              </div>
              <div class="col-auto">
                <languages class="simple no-borders" :list="languages" :short="true"></languages>
              </div>
            </div>
          </div>
        </nav>
        <div v-if="isTablet" class="mcol col-auto d-flex align-items-center">
          <button type="button" class="menu-toggle" @click="openMenu">
            <icon name="bars"></icon>
            <span class="hidden">{{l10n.menu}}</span>
          </button>
        </div>
        <div v-else class="col-3 d-flex align-items-center justify-content-end">
          <!-- <socials :class="{inverted: onTop && inverted}" :list="socials" position="header"></socials> -->
          <languages :class="['simple', {inverted: onTop && inverted}]" :list="languages" :short="true"></languages>
          <button type="button" class="search-toggle" @click="search = !search">
            <icon :name="search ? 'times' : 'search'"></icon>
            <span class="hidden">
              <template v-if="!search">{{l10n.search}}</template>
              <template v-else>{{l10n.searchClose}}</template>
            </span>
          </button>
        </div>
      </div>
    </div>
    <transition name="submenu">
      <div v-if="!isTablet && search" class="search-bar">
        <div class="container">
          <free-search
            :autocomplete-disabled="freeSearchDisableAutocomplete"
            :use-fixture="freeSearchFixture"
            :l10n="l10n"
            :search-config="searchConfig"
          ></free-search>
        </div>
      </div>
    </transition>
    <div v-if="isTablet && mobileCta && !isActive(mobileCta.id)"
         ref="cta"
         class="mobile-cta"
         :style="{bottom: bottom + 'px'}"
    >
      <btn class="submenu-cta blue-lochmara block"
           :href="mobileCta.link"
           :target="mobileCta.external ? '_blank' : false"
      >{{mobileCta.label}}
      </btn>
    </div>
  </header>
</template>

<script>
  import anime from 'animejs'
  import FreeSearch from '@components/free-search'
  import Icon from '@components/atoms/icon'
  // import Socials from '@components/socials'
  import Languages from '@components/languages'
  import Btn from '@components/atoms/btn'

  import { MobileHandlers } from '@mixins/mobile-handlers'
  import ViewportMixins from '@mixins/viewport_mixins'
  import { EventBus } from '@helpers/event-bus'

  export default {
    name: 'BaseHeader',
    components: {
      Btn,
      Languages,
      // Socials,
      Icon,
      FreeSearch
    },
    mixins: [
      MobileHandlers,
      ViewportMixins
    ],
    data () {
      return {
        open: false,
        search: false,
        searchMobile: false,
        hasCmsMenu: false,
        inverted: false,
      }
    },
    props: {
      freeSearchDisableAutocomplete: { type: Boolean, default: false },
      freeSearchFixture: { type: Boolean, default: false },
      freeSearchApi: { type: String },
      searchConfig: { type: Object, required: true },
      active: { type: Array },
      homeUrl: { type: String, default: '/' },
      menu: { type: Array },
      backgroundColor: { type: String, default: 'blue' },
      l10n: {
        type: Object,
        default () {
          return {
            noResults: 'No results found',
            search: 'Search',
            searchClose: 'Close search',
            menu: 'Menu',
            menuClose: 'Close',
            searchPlaceholder: 'Search in barillagroup.com',
          }
        },
      },
      // socials: { type: Array },
      languages: { type: Array },
      hideSubmenu: { type: Boolean, default: false },
      animation: { type: Object, default: () => null }
    },
    asyncComputed: {
      async menuOrFixture () {
        return this.menu ? this.menu :
          (await import('@fixtures/header-fixture')).headerMenuFixture
      }
    },
    computed: {
      isActive () {
        return id => !!this.active.find(val => val === id)
      },

      headerOffset () {
        return this.isTablet ? -60 : -70
      },
      top () {
        let toolbar = document.querySelector('.cms-toolbar')

        if (this.search) {
          return 0
        }
        const currentTop = this.$el ?
          this.$el.getBoundingClientRect().top : 0
        const currentScroll = this.$currentScrollPosition
        const previousScroll = this.$lastScrollPosition
        const deltaScroll = previousScroll - currentScroll

        if (currentScroll < 0) {
          /* this control is necessary to prevent undesired behaviors on overscroll/bouncing in iOS */
          if (previousScroll > currentScroll && currentTop > this.headerOffset) {
            return Math.max(currentTop - deltaScroll, this.headerOffset + (this.hasCmsMenu ? toolbar.offsetHeight : 0))
          } else if (currentScroll > previousScroll && currentTop < 0) {
            return Math.min(currentTop - deltaScroll, this.hasCmsMenu ? toolbar.offsetHeight : 0)
          } else if (this.hasCmsMenu) {
            return toolbar.offsetHeight
          }
        } else {
          if (this.hasCmsMenu) {
            return toolbar.offsetHeight
          }
          return 0
        }
      },

      bottom () {
        const currentBottom = this.$refs['cta'] ? (this.$refs['cta'].getBoundingClientRect().bottom - document.documentElement.clientHeight) * -1 : 0
        const currentScroll = this.$currentScrollPosition
        const previousScroll = this.$lastScrollPosition
        const deltaScroll = previousScroll - currentScroll

        if (previousScroll < currentScroll && currentBottom > -66) {
          return Math.max(currentBottom + deltaScroll, -66)
        } else if (currentScroll < previousScroll && currentBottom < 0) {
          return Math.min(currentBottom + deltaScroll, 0)
        }
      },
      bgColor () {
        if (this.backgroundColor === 'transparent') {
          return this.top > 0 ? 'rgba(0, 52, 123, 0)' : `rgba(0, 52, 123, ${Math.min(Math.abs(this.$currentScrollPosition / 120), 1)})`
        } else {
          return '#00347B'
        }
      },
      onTop () {
        return this.backgroundColor === 'transparent' && Math.min(Math.abs(this.$currentScrollPosition / 120), 1) < .3
      },
      mobileCta () {
        if (this.menuOrFixture) {
          return this.menuOrFixture.filter(item => item.cta && this.isActive(item.id)).map(item => item.cta)[0]
        } else {
          return null
        }
      }
    },
    methods: {
      openMenu () {
        this.open = true
        document.body.style.overflowY = 'hidden'
      },
      closeMenu () {
        if (this.searchMobile) {
          this.searchMobile = false
          this.$refs['MobileSearchComp'].cleanup()
        } else {
          this.open = false
          document.body.style.overflowY = 'auto'
        }
      },
      initResizeHandler () {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
      },
      customResizeClbk () {
        document.querySelector('body').classList.add('disable-css-transitions')
        setTimeout(function () {
          document.querySelector('body').classList.remove('disable-css-transitions')
        }, 500)
      }
    },
    mounted () {
      if (!!document.querySelector('.cms-toolbar')) {
        this.hasCmsMenu = true
      }
      this.inverted = this.$el.className.includes('inverted')

      this.initResizeHandler()

      if (this.animation) {
        this.$el.style.opacity = 0
        EventBus.$once(this.animation.triggerEventName, () => {
          anime.timeline()
            .add({
              targets: this.$el,
              opacity: [0, 1],
              easing: 'easeInOutQuad',
              duration: 1250,
            })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  .BaseHeader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 320px;
    z-index: 100;
  }

  .main-row {
    height: 70px;
  }

  .logo {
    .logo-link {
      display: block;
      width: 106px;
      height: 38px;
      background: url(~@images/barilla.svg) no-repeat 50% 50%;
      background-size: cover;
      transition: opacity .3s ease;

      &:hover {
        opacity: .75;
      }
    }
  }

  .search-toggle {
    color: $color-white;
    font-size: 18px;
    width: 44px;
    height: 44px;
    transition: opacity .3s ease, color .6s ease;
    text-align: center;
    margin-right: -11px;

    &:hover {
      opacity: .75;
    }
  }

  .menu-toggle {
    color: $color-white;
    font-size: 24px;
    width: 44px;
    height: 44px;
    transition: opacity .3s ease, color .6s ease;
    text-align: center;
    margin-right: -11px;

    &:hover {
      opacity: .75;
    }
  }

  .search-bar {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    background: $color-white;
    box-shadow: 0 3px 10px rgba($color-dark, .1);
    max-height: calc(100vh - 70px);
    display: flex;
  }

  @media (min-width: $bootstrap-lg) {
    .BaseHeader {
      background: $color-blue;
      color: $color-white;
    }

    .submenu {
      position: absolute;
      left: 0;
      top: 100%;
      overflow: hidden;
      width: 100%;
      z-index: 2;

      .submenu-items {
        background: $color-white;
        border-bottom: 1px solid $color-grey-medium;
        position: relative;
        z-index: 1;

        .submenu-item {
          .submenu-item-link {
            display: block;
            padding: 0 16px;
            font-size: 16px;
            font-weight: $normal;
            line-height: 55px;
            color: rgba($color-dark, .75);
            transition: color .3s ease;
            position: relative;

            &:after {
              content: ' ';
              display: block;
              height: 1px;
              position: absolute;
              left: 16px;
              right: 16px;
              bottom: -1px;
              background: transparent;
              transition: background-color .3s ease;
            }
          }

          &.active {
            .submenu-item-link {
              color: $color-dark;

              &:after {
                background: $color-dark;
              }
            }
          }
        }

        &:hover {
          .submenu-item {
            &:hover {
              .submenu-item-link {
                color: $color-dark;
              }
            }
          }
        }
      }

      .submenu-cta {
        position: absolute;
        right: 24px;
        top: 50%;
        margin-top: -18px;
        z-index: 2;
      }
    }

    .menu {
      position: static;

      .menu-items {
        .menu-item {
          .menu-item-link {
            padding: 0 16px;
            display: block;
            font-size: 18px;
            line-height: 70px;
            font-weight: $normal;
            position: relative;
            color: rgba($color-white, .75);
            transition: color .3s ease;
          }

          &:hover, &.active {
            .menu-item-link {
              color: $color-white;
            }
          }

          &.active {
            .menu-item-link {
              &:after {
                content: ' ';
                border: transparent 10px solid;
                border-bottom-color: $color-white;
                position: absolute;
                left: 50%;
                bottom: 0;
                margin-left: -10px;
                width: 0;
                height: 0;
                pointer-events: none;
                opacity: 0;
                transition: opacity .3s ease;
              }
            }
          }

          &.submenu-open {
            .menu-item-link {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $bootstrap-lg - 1) {
    $menu-anim-time: .6s;

    .cms-toolbar .menu {
      height: calc(100vh - 46px);
    }

    .no-toolbar .menu {
      top: 0;
      bottom: 0;
    }

    .menu {
      position: absolute;
      background: $color-blue;
      padding: 0;
      right: 0;
      width: 0;
      z-index: 3;
      opacity: 0;
      transition: width .01s linear $menu-anim-time, opacity $menu-anim-time ease;
      overflow: hidden;

      .menu-items {
        flex: 1 1 100%;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 20px;
        position: relative;
        @include ScrollTouchFix();

        .menu-item {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($color-grey, .25);

          &:first-child {
            border-top: 1px solid transparent;
          }

          &:last-child {
            border-bottom-color: transparent;
          }

          .menu-item-link {
            display: block;
            font-size: 18px;
            line-height: 44px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: $bold;
            color: rgba($color-white, .75);
            transition: color .3s ease;

            &:hover {
              color: $color-white;
            }
          }

          &.active {
            .menu-item-link {
              color: $color-white;
            }
          }
        }
      }

      .submenu-items {
        .submenu-item {
          .submenu-item-link {
            display: block;
            font-size: 16px;
            line-height: 44px;
            transition: color .3s ease;
            color: rgba($color-white, .6);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 12px;
            position: relative;

            &:before {
              content: ' ';
              display: none;
              width: 1px;
              height: 20px;
              background: $color-white;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -10px;
            }

            &:hover {
              color: $color-white;
            }
          }

          &.active {
            .submenu-item-link {
              color: $color-white;

              &:before {
                display: block;
              }
            }
          }
        }
      }
    }

    .logo {
      .logo-link {
        width: 91px;
        height: 32px;
      }
    }

    .BaseHeader {
      height: 60px;
      /*background: none !important;*/
      transition: height .01s linear $menu-anim-time, background-color $menu-anim-time ease;

      .main-row {
        height: 60px;
      }

      .bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 60px;
        background: $color-blue;
        z-index: 1;
      }

      .main-row {
        position: static;

        .mcol {
          height: 60px;
          position: relative;
          z-index: 2;
        }
      }

      &.open {
        &.toolbar {
          top: 46px !important;
        }

        &.no-toolbar {
          top: 0 !important;

          @supports not (-webkit-overflow-scrolling: touch) {
            height: 100vh;
          }
        }

        height: 100%;
        transition: height .01s linear, background-color $menu-anim-time ease .01s, top $menu-anim-time ease .01s;

        .container {
          height: 100%;
        }

        .main-row {
          height: 100%;
        }

        .menu {
          width: 100%;
          opacity: 1;
          transition: width .01s linear, opacity $menu-anim-time ease .01s;
        }
      }
    }

    .menu-header {
      padding: 70px 20px 25px;
      height: 145px;
      flex: 0 0 auto;
      position: relative;
      transition: padding .5s ease;
      overflow: hidden;

      .menu-toggle {
        color: $color-white !important;
        position: absolute;
        right: 20px;
        top: 8px;
        margin-right: -14px;
        z-index: 2;
        transition: top .5s ease, margin-right .5s ease, right .5s ease;
      }

      &:after {
        content: ' ';
        display: block;
        height: 1px;
        position: absolute;
        left: 20px;
        bottom: 0;
        right: 20px;
        background: rgba($color-white, .2);
      }
    }

    .menu-footer {
      padding: 0 20px;
      flex: 0 0 auto;
      color: $color-white;
      position: relative;

      > .row {
        height: 60px;
      }

      &:after {
        content: ' ';
        display: block;
        height: 1px;
        position: absolute;
        left: 20px;
        bottom: 100%;
        right: 20px;
        background: rgba($color-white, .2);
      }
    }

    .BaseHeader.searchMobile {
      .menu-header {
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
        flex: 1 1 auto;
        display: flex;
      }

      .menu-toggle {
        top: 23px;
        margin-right: 0;
        right: 23px;
      }
    }
  }

  .submenu-enter-active, .submenu-leave-active {
    transition: transform .5s ease, opacity .5s ease;
  }

  .submenu-enter, .submenu-leave-to {
    opacity: 0;
    transform: translate(0, -20px);
  }

  .mobile-cta {
    position: fixed;
    left: 0;
    bottom: -66px;
    width: 100%;
    min-width: 320px;
    z-index: 99;
    background: $color-white;
    box-shadow: 0 0 6px rgba($color-black, .16);
    padding: 10px 20px;
  }

  // Brands specific classes
  .brands {
    @media (min-width: $bootstrap-lg) {
      .menu {
        .menu-items {
          .menu-item {
            position: relative;

            &.active:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 1px;
              background-color: $color-white;
            }
          }
        }
      }
    }

    &.white {
      .menu {
        .menu-items {
          .menu-item {
            .menu-item-link {
              color: $color-white;
            }
          }
        }
      }
    }

    &.inverted {
      .menu .menu-items .menu-item.active {
        border-bottom: 1px solid $color-blue;
      }
    }
  }

  // Theme inverted
  .inverted {
    &.BaseHeader {
      background: transparent
    }

    &.onTop {
      .menu-toggle, .search-toggle {
        color: $color-blue;
      }

      .logo {
        .logo-link {
          background-image: url(~@images/barilla-blue.svg);
        }
      }

      @media (min-width: $bootstrap-lg) {
        .menu {
          .menu-items {
            .menu-item {
              .menu-item-link {
                color: rgba($color-blue, .75);
              }

              &:hover, &.active {
                .menu-item-link {
                  color: $color-blue;
                }
              }
            }
          }
        }

        &.home .menu {
          .menu-items {
            .menu-item {
              .menu-item-link {
                color: $color-blue;
              }

              &:hover {
                .menu-item-link {
                  color: rgba($color-blue, 0.75);
                }
              }
            }
          }
        }
      }
    }
  }
</style>
