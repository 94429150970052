<template>
  <section class="Tabs" v-if="theme === 'side-left'">
    <div class="row justify-content-start tab-container" ref="tab-container">
      <div class="col-12 col-md-3 col-lg-2 d-flex flex-wrap flex-row flex-md-column pp-pb-12 pp-pb-md-0 mb-5">
        <template v-if="$viewportWidth >= 768">
          <div
            v-for="tab in tabs"
            :key="tab.slug"
            :class="['tabHead', activeTab === tab.slug ? 'active' : '']"
            @click="onGuardClick(tab)"
          >
            <div class="tab-category">
              <slot :name="tabHeadSlotName(tab)"></slot>
            </div>
          </div>
          <slot name="guards"></slot>
        </template>
        <template v-else>
          <div class="flexWrap">
            <span class="label-filter">{{ topicLabel }}</span>
            <div class="div-select">
              <span class="div-select-label">{{ getActiveTabName }}</span>
              <img class="div-select-icon" :src="$context.assetsRoot+'img/arrow-down.svg'" />
              <select
                ref="$select"
                @change="onGuardChange"
              >
                <option
                  v-for="tab in tabs"
                  :key="tab.slug"
                  :value="tab.slug"
                  :selected="activeTab === tab.slug"
                >
                  {{ tab.name }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-8 offset-md-1 col-lg-9 offset-lg-1">
        <div class="tab-content" ref="tab-content">
          <transition
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
            :css="false"
            :key="tabContentSlotName"
          >
            <slot :name="tabContentSlotName"></slot>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Velocity from 'velocity-animate';

export default {
  name: 'Tabs',
  props: {
    theme: {type: String, default: 'side-left'},
    initialTab: {type: String},
    tabs: {type: Array},
    dataLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: this.initialTab,
      initialTabChanged: 0,
      height: 0
    };
  },
  computed: {
    topicLabel() {
      return this.dataLanguage === 'it' ? 'Seleziona un argomento e scopri le Q&A' : 'Choose a topic to see the Q&A';
    },
    tabContentSlotName() {
      return `tab-active-panel-${this.activeTab}`;
    },
    getActiveTabName() {
      let tab = null;
      if (this.tabs && this.tabs.length) {
        tab = this.tabs.find(e => e.slug === this.activeTab);
      }
      return tab ? tab.name : '';
    }
  },
  methods: {
    tabHeadSlotName(name) {
      return `tab-panel-${name.slug}`;
    },
    onGuardClick(tab) {
      this.activeTab = tab.slug;
      if (!this.initialTabChanged) {
        this.initialTabChanged = 1;
      }
    },
    onGuardChange() {
      const mySelect = this.$refs.$select;
      this.activeTab = mySelect.options[mySelect.selectedIndex].value;
      if (!this.initialTabChanged) {
        this.initialTabChanged = 1;
      }
    },
    async getTabContainerHeight(tabChanged = false) {
      await this.$nextTick();
      this.$refs['tab-container'].style.height = 'auto';
      if (this.$refs['tab-content'].getBoundingClientRect().height >= this.$refs['tab-container'].getBoundingClientRect().height) {
        if (!tabChanged) {
          this.$refs['tab-container'].style.minHeight = this.$refs['tab-content'].getBoundingClientRect().height + 'px';
        }
      } else {
        this.$refs['tab-container'].style.minHeight = 0;
      }
      this.height = this.$refs['tab-container'].getBoundingClientRect().height + 'px';
    },
    beforeEnter(el) {
      if (this.initialTabChanged) {
        el.style.opacity = 0;
      }
    },
    enter(el, done) {
      if (this.initialTabChanged) {
        el.style.position = 'absolute';
        Velocity(el, {opacity: 1}, {
          duration: 300, delay: 300, complete: () => {
            el.style.position = 'static';
            done();
          }
        });
      }
      this.getTabContainerHeight();
    },
    afterEnter(el) {
      this.getTabContainerHeight(true);
    },
    leave(el, done) {
      el.style.position = 'absolute';
      Velocity(el, {opacity: 0}, {duration: 300, complete: done});
    }
  },
  watch: {
    height: {
      handler(val, oldVal) {
        const el = this.$refs['tab-container'];
        if (el) {
          el.style.height = oldVal;
          Velocity(el, {height: val}, {
            duration: 200, complete: () => {
              el.style.height = 'auto';
              el.style.minHeight = val;
            }
          });
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";

.Tabs {
  .tab-container {
    overflow: hidden;
    flex-direction: column;

    .tabHead {
      padding-right: $grid-gutter-width;

      @media (min-width: $bootstrap-md) {
        max-width: 33.33333%;
      }

      &:last-child {
        padding-right: 0;
      }

      @media (min-width: $bootstrap-md) {
        max-width: none;
        padding-right: 0;
      }

      .tab-category {
        padding: 20px 0 16px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: transparent;

        * {
          color: $color-dark;
          opacity: .5;
        }

        .IconLink {
          padding-left: 20px;
        }
      }

      &.active {
        .tab-category {
          * {
            color: $color-dark;
            opacity: 1;
          }
        }
      }
    }

    .tab-content {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  @media (min-width: $bootstrap-md) {
    .tab-container {
      flex-direction: row;

      .tabHead {
        &:first-child {
          .tab-category {
            padding-top: 0;
          }
        }

        .tab-category {
          border-bottom-color: fade-out($color-grey, 0.75);
        }
      }
    }
  }

  .flexWrap {
    width: 100%;

    .label-filter {
      font-family: 'Bliss Pro';
      font-size: 20px;
      color: #616365;

      @media (min-width: $bootstrap-md) {
        line-height: 1;
        padding: 0 0 1px;
      }
    }

    .div-select {
      display: inline-block;
      position: relative;
      text-align: left;
      font-family: 'Bliss Pro';
      font-size: 20px;
      line-height: 52px;
      height: 56px;
      color: #00142F;
      border: 1px solid #BFC4CA;
      border-radius: 5px;
      padding: 0 25px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      width: 100%;
      margin-top: 20px;

      @media (min-width: $bootstrap-md) {
        margin-left: 20px;
        margin-top: 0;
        width: 192px;
      }

      select {
        cursor: pointer;
        position: absolute;
        left: -1px;
        top: -1px;
        opacity: 0;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
      }

      &-icon {
        position: absolute;
        right: 26px;
        top: 21px;
        width: 16px;
        height: 10px;
      }

      &-label {
        display: inline-block;
        width: calc(100% - 25px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
