<template>
  <div class="Separator">
    <hr class="line">
    <icon
      v-if="icon"
      :name="icon"
      class="sep-icon"
    ></icon>
  </div>
</template>

<script>
  import Icon from '@components/atoms/icon'

  export default {
    name: 'Separator',
    components: {Icon},
    props: {
      icon: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/_variables.scss";

  .Separator {
    position: relative;
  }

  .line {
    border-top-color: fade-out($color-grey, 0.75);
    position: relative;
    z-index: 1;
  }

  .sep-icon {
    color: $color-orange;
    font-size: 24px;
    width: 32px;
    text-align: center;
    display: block;
    background: $color-white;
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -12px;
    left: 50%;
    margin-left: -16px;
  }

  .Separator {
    &.blue {
      .line {
        border-top-color: $color-blue-lochmara;
      }
    }
  }
</style>