<template>
  <div class="Spinner">
    <div
      class="perspective-dots"
      v-if="type === 'perspective-dots'"
    >
      <div :style="{backgroundColor: color}" class="p-dot1"></div>
      <div :style="{backgroundColor: color}" class="p-dot2"></div>
    </div>

    <div
      class="dots"
      v-if="type === 'dots'"
    >
      <div :style="{backgroundColor: color}" class="bounce1"></div>
      <div :style="{backgroundColor: color}" class="bounce2"></div>
      <div :style="{backgroundColor: color}" class="bounce3"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
    props: {
      type: {type: String, default: 'dots'},
      color: {type: String, default: '#00347B'}
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/_variables.scss';

  .perspective-dots {
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    animation: sk-rotate 2.0s infinite linear;

    .p-dot1, .p-dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: $color-blue;
      border-radius: 100%;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .p-dot2 {
      top: auto;
      bottom: 0;
      animation-delay: -1.0s;
    }

    @keyframes sk-rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes sk-bounce {
      0%, 100% {
        transform: scale(0.0);
      }
      50% {
        transform: scale(1.0);
      }
    }
  }


  .dots {
    width: 70px;
    text-align: center;

    > div {
      width: 18px;
      height: 18px;
      background-color: $color-blue;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      animation-delay: -0.32s;
    }

    .bounce2 {
      animation-delay: -0.16s;
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1.0);
      }
    }
  }
</style>