<template>
  <div :class="['TitleText', {'blockquote-mode': blockquoteMode}]">
    <slot name="image"></slot>

    <template v-if="!blockquoteMode">
      <slot name="over-title"></slot>
    </template>

    <slot name="title"></slot>

    <template v-if="blockquoteMode">
      <slot name="over-title"></slot>
    </template>

    <slot name="text"></slot>
  </div>
</template>

<script>
import anime from 'animejs';
import { EventBus } from '@helpers/event-bus';

export default {
  name: 'TitleText',
  data() {
    return {
      animated: false
    };
  },
  props: {
    blockquoteMode: {type: Boolean, default: false},
    animation: {type: Object, default: () => null}
  },
  mounted() {
    if (this.animation) {
      if (this.$slots['title'] && this.$slots['text']) {
        EventBus.$once(this.animation['triggerEventName'], async () => {
          const $title = this.$slots['title'][0].elm;
          const $text = this.$slots['text'][0].elm;
          $title.innerHTML = $title.innerHTML.replace(/\S/g, `<span style="padding: 10px 0;">$&</span>`);

          const animation = anime.timeline()
            .add({
              targets: $title.children,
              opacity: [0, 1],
              easing: 'easeInOutQuad',
              duration: 800,
              delay: (el, i) => 40 * (i + 1)
            })
            .add({
              targets: $text,
              opacity: [0, 1],
              translateY: [10, 0],
              easing: 'easeInOutQuad',
              duration: 1500
            }, '-=1000');

          await animation.finished;
          EventBus.$emit(this.animation['completeEventName']);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";
@import "~$scss/utility";

.TitleText {
  text-align: center;

  &.align-left {
    text-align: left;
  }

  @media (min-width: $bootstrap-md) {
    &.align-left-md {
      text-align: left;
    }
  }

  .over-title {
    margin: 0 0 25px;
    color: $color-grey;
    font-weight: $extra-bold;
    font-size: 14px;
    letter-spacing: 0.1em;

    &.text-color-white {
      color: #fff
    }

    @media (min-width: $bootstrap-lg) {
      margin: 0 0 30px;
    }
  }

  $title-margin: 20px 0,
  30px 0,
  40px 0;

  @each $size,
  $value in $title-sizes {
    .title-#{$size} {
      @if $size <=30 {
        margin: nth($title-margin, 1);
      } @else if $size <=40 {
        margin: nth($title-margin, 2);
      } @else {
        margin: nth($title-margin, 3);
      }
    }
  };

  .text {
    margin: 20px 0;
  }

  .title,
  .text {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Theme white
  &.white {
    .over-title {
      color: $color-white;
    }

    .title,
    .text {
      color: $color-white;
    }
  }

  // Theme grey
  &.grey {
    .title,
    .text {
      color: $color-grey-2;
    }
  }

  // Theme over-title separator
  &.over-title-separator {
    .over-title {
      display: block;
      border-bottom: 1px solid $color-grey-medium-2;
      padding-bottom: 15px;
      margin: 0 0 25px !important;
    }
  }

  // Theme title separator
  &.title-separator {
    .title {
      border-bottom: 1px solid $color-grey-medium-2;
      padding-bottom: 20px;
    }

    .text {
      margin-top: 20px;
    }
  }

  // Theme blockquote-mode
  &.blockquote-mode {
    .title {
      position: relative;

      $quotes-ar: 1.12;
      $quotes-width: ($bootstrap-sm: 32px,
        $bootstrap-md: 48px,
        $bootstrap-lg: 65px);

      $quotes-height: ();
      $quotes-side-position: ();
      $quotes-top-position: ();

      @each $step,
      $value in $quotes-width {
        $quotes-height: map-merge($quotes-height, ($step: $value / $quotes-ar));
        $quotes-top-position: map-merge($quotes-top-position, ($step: -$value / $quotes-ar / 2));

        @if $step < $bootstrap-md {
          $quotes-side-position: map-merge($quotes-side-position, ($step: -$value));
        } @else {
          $quotes-side-position: map-merge($quotes-side-position, ($step: -$value - $grid-gutter-width));
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 65px;
        height: 58px;
        /* request, quote removed */
        /* background: url('~@images/quotes.svg') no-repeat;*/
        background-size: cover;

        @include responsive-property(width, $quotes-width);
        @include responsive-property(height, $quotes-height);
      }

      &:before {
        @include responsive-property(top, $quotes-top-position);
        @include responsive-property(left, $quotes-side-position);
      }

      &:after {
        transform: rotate(180deg);
        @include responsive-property(bottom, $quotes-top-position);
        @include responsive-property(right, $quotes-side-position);
      }
    }

    .over-title {
      margin-top: 40px;
    }
  }

  // Theme framed
  &.framed {
    .over-title {
      font-size: 32px;
      line-height: 36px;
      color: $color-blue;
      text-align: left;
      font-weight: $normal;
      @include fontCaecilia();
      text-transform: none !important;
      letter-spacing: normal;
      margin-bottom: 10px;
    }

    .title {
      font-size: 80px;
      line-height: 1;
      padding-bottom: 20px;
      text-align: left;

      @media (min-width: $bootstrap-lg) {
        font-size: 88px;
      }

      &.with-separator {
        border-bottom: 1px solid rgba($color-dark, 0.1);
      }
    }

    .text {
      margin-top: 20px;
    }

    &.bluish-frame {
      .title {
        &.with-separator {
          border-bottom: 1px solid rgba($color-white, 0.33);
        }
      }
    }
  }
}
</style>
