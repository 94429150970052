<template>
  <img
    :src="src"
    :srcset="srcset"
    :alt="alt"
  >
</template>

<script>
  /*
  * sources = {
  *   xs: {
  *     '1x': {src: 'xs/1x/image/url.png', alt: 'alt text'},
  *     '2x': {src: 'xs/2x/image/url.png', alt: 'alt text'},
  *     ...
  *   },
  *   sm: {
  *     ...
  *   }
  * }
  */
  import { fallback } from '@helpers/responsive-utilities'

  export default {
    name: 'ResponsiveImg',
    props: {
      sources: {type: Object, required: true},
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      }
    },
    computed: {
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      currentSrc () {
        return fallback(this.sources, this.currentStep)
      },
      srcset () {
        return Object.getOwnPropertyNames(this.currentSrc).reduce(
          (acc, density, idx) => {
            return idx === 0 ?
              `${this.currentSrc[density]['src']} ${density}` :
              `${acc}, ${this.currentSrc[density]['src']} ${density}`
          }, ''
        )
      },
      src () {
        const densities = Object.getOwnPropertyNames(this.currentSrc)
        return this.currentSrc[densities[densities.length - 1]]['src']
      },
      alt () {
        const densities = Object.getOwnPropertyNames(this.currentSrc)
        return this.currentSrc[densities[0]]['alt']
      }
    }
  }
</script>
