var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"ContainerBlock",class:{
    'with-image': _vm.halfImage,
    'image-left': _vm.halfImagePosition === 'left' && !_vm.isSmall,
    'pt-0 pb-0': _vm.noPad
  },style:(_vm.rootElStyle)},[(_vm.halfImage && !_vm.isSmall)?_c('div',{class:['half-image', _vm.imgAlignmentClasses],style:(_vm.imgStyle)},[_vm._v("\n     \n  ")]):_vm._e(),_vm._v(" "),(_vm.halfImage && _vm.isSmall)?_c('img',{staticClass:"mobile-image resp-image",attrs:{"src":_vm.halfImage,"alt":""}}):_vm._e(),_vm._v(" "),_c('div',{class:{'container': _vm.centered, 'pl-0 pr-0': _vm.noPad}},[_c('div',{staticClass:"wrapper"},[(_vm.halfImage)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6",class:{
              'pr-5': !_vm.isSmall && _vm.halfImage && _vm.halfImagePosition === 'right',
              'pl-5': !_vm.isSmall && _vm.halfImage && _vm.halfImagePosition === 'left',
              'offset-6': !_vm.isSmall && _vm.halfImagePosition === 'left'
            }},[_vm._t("default")],2)])]:[_vm._t("default")]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }