<template>
  <figure
    class="HeroContent"
    :style="{
      backgroundImage: isFixedHeight ? `url(${image.src})` : null
    }"
  >
    <img v-if="!isFixedHeight" :src="image.src" :alt="image.alt" class="image resp-image">
    <figcaption :class="['overlay', {over: !isFixedHeight}]">
      <div class="wrapper" :style="{maxWidth: overlayMaxWidth ? overlayMaxWidth : null}">
        <div
          :class="['overlay-row row flex-column', overlayVAlign]"
          :style="{minHeight: isFixedHeight ? isFixedHeight : null}"
        >
          <div class="col-auto" :style="{textAlign: horizontalAlign}">
            <slot name="subtitle"></slot>
            <slot name="title"></slot>
            <icon-link
              v-if="hasCtaSlot && link"
              :href="link"
              :target="linkTarget"
              :icon="linkIcon"
              class="cta white"
              :class="ctaClasses"
            >
              <slot name="cta"></slot>
            </icon-link>
          </div>
        </div>
      </div>
    </figcaption>
  </figure>
</template>

<script>
  import IconLink from '@components/atoms/icon-link'

  export default {
    name: 'HeroContent',
    components: {IconLink},
    props: {
      verticalAlign: {
        type: 'top' | 'center' | 'bottom',
        default: 'center',
      },
      horizontalAlign: {
        type: 'left' | 'center' | 'right',
        default: 'center',
      },
      image: {
        type: Object,
        required: true,
      },
      fixedHeight: {
        type: String,
      },
      mobileFixedHeight: {
        type: String,
        default: '400px',
      },
      link: {
        type: String,
      },
      linkIcon: {
        type: String,
        default: 'long-arrow-right',
      },
      linkTarget: {
        type: String,
      },
      ctaClasses: {
        type: String,
        default: 'big mt-3 mt-md-4 mt-lg-5',
      },
      overlayMaxWidth: {
        type: String,
      }
    },
    computed: {
      overlayVAlign () {
        switch (this.verticalAlign) {
          case 'top':
            return 'justify-content-start'
          case 'bottom':
            return 'justify-content-end'
          default:
            return 'justify-content-center'
        }
      },
      hasCtaSlot () {
        return !!this.$slots['cta']
      },
      isFixedHeight () {
        if (this.$viewportWidth < 768) {
          return this.mobileFixedHeight
        } else {
          return this.fixedHeight
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .HeroContent {
    position: relative;
    overflow: hidden;
    background: no-repeat 50% 50%;
    background-size: cover;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: rgba($color-black, .2);
    }

    .overlay {
      position: relative;
      z-index: 2;
      padding: 0 $grid-gutter-width;
      color: $color-white;
      height: 100%;

      &.over {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: auto;
      }

      .wrapper {
        margin: 0 auto;
        height: 100%;
      }

      .overlay-row {
        height: 100%;
        padding-top: $grid-gutter-width * 1.5;
        padding-bottom: $grid-gutter-width * 1.5;
      }

      @media (min-width: $bootstrap-md) {
        padding: 0 5%;

        .overlay-row {
          padding-top: 6%;
          padding-bottom: 6%;
        }
      }

      @media (min-width: $bootstrap-lg) {
        padding: 0 8%;

        .overlay-row {
          padding-top: 10%;
          padding-bottom: 10%;
        }
      }
    }

    .title, .subtitle {
      color: $color-white;
    }
  }
</style>
