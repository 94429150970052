<template>
  <label class="Checkbox" :for="labelId">
    <input
      :id="labelId"
      class="hidden-input"
      :type="type"
      :checked="shouldBeChecked"
      :value="value"
      :name="name"
      @change="updateValue"
      @click="updateNullableRadio"
      ref="input"
    >
    <span class="box">
      <transition name="fade">
        <icon name="check" v-if="value"></icon>
      </transition>
    </span>
    <span class="label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
  import Icon from '@components/atoms/icon'

  export default {
    name: 'Checkbox',
    components: {Icon},
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      type: {type: String, default: 'radio'},
      name: {type: String, required: true},
      value: {type: Boolean | String},
      modelValue: {default: ''},
      trueValue: {default: true},
      falseValue: {default: false},
      nullable: {default: false}
    },
    computed: {
      shouldBeChecked () {
        if (this.type === 'radio') {
          return this.modelValue === this.value
        } else if (this.type === 'checkbox') {
          if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value)
          } else {
            return this.modelValue === this.trueValue
          }
        }
      },
      labelId () {
        return `${this.name}-${this.type}-${this._uid}`
      }
    },
    methods: {
      updateValue (e) {
        if (this.type === 'checkbox') {
          const isChecked = e.target.checked
          if (this.modelValue instanceof Array) {
            const newValue = [...this.modelValue]
            if (isChecked) {
              newValue.push(this.value)
            } else {
              newValue.splice(newValue.indexOf(this.value), 1)
            }
            this.$emit('change', newValue)
          } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue)
          }
        } else if (this.type === 'radio') {
          this.$emit('change', this.value)
        }
      },
      updateNullableRadio () {
        // FIXME null emitted even when regularly changing the radio buttons (debounceTime fixes it)
        if (this.type === 'radio' && this.nullable && this.$refs['input'].checked) {
          this.$emit('change', null)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  $transition-time: 0.2s;

  .Checkbox {
    display: inline-block;
  }

  .hidden-input {
    display: none;
  }

  .box {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border: 1px solid fade-out($color-dark, 0.75);
    border-radius: 4px;
    background-color: transparent;
    color: $color-white;
    text-align: center;
    transition-property: border, background-color, color;
    transition-duration: $transition-time;
    transition-timing-function: ease-out;
  }

  .label {
    vertical-align: middle;
    color: $color-dark;
    transition: color $transition-time ease-out;
    font-size: 20px;
  }

  .hidden-input:checked + .box {
    background-color: $color-blue-lochmara;
    border-color: $color-blue-lochmara;

    & + .label {
      color: $color-blue-lochmara;
    }
  }
</style>