<template lang="pug">
  .SearchInterface
    .row
      .col-12(v-if="mobileLayout")
        btn.rounded-ghost(icon="sliders-h1", @click.native="filtersOpen = true")
          | {{$pgettext('search interface', 'Show filters')}}
        mounting-portal(mount-to="#dialog-root", append)
          transition(name="slide-fade")
            .filter-modal.bg-color-white(v-if="filtersOpen")
              .bottom-bar.bg-color-white
                .container
                  btn.blue-lochmara.block(@click.native="filtersOpen = false")
                    | {{$pgettext('search interface', 'Apply')}}
              .container.filter-modal-header
                .row.justify-content-between
                  .col-4.text-align-left
                    icon.text-30.close-icon(name="times" role="button" @click.native="filtersOpen = false")
                  .col-4.text-align-center
                    p.text-18 {{$pgettext('search interface', 'Filter By')}}
                  .col-4.text-align-right
                    a.text-18.text-color-blue-lochmara.text-20(href="#", @click.prevent="resetFilters") {{$gettext('Clear all')}}
              .container
                .row
                  .filter-container.col-12
                    include ./templates/search-interface/search-filters.pug

      .col-12.col-lg-3.filter-container(v-if="!mobileLayout")
        include ./templates/search-interface/search-filters.pug

      .col-12.col-lg-9.results-col
        .search-bar-wrapper(v-if="!mobileLayout")
          search-bar(ref="search-bar", class="search-input", v-stream:input="textQuery$")
        .results
          slot-wrapper.result-row.row(v-if="!asyncMode", wrapper-class="result-col col-sm-6 col-md-4")
            slot(name="results")
          transition-group.result-row.row(v-if="asyncMode && searchResults", name="staggered-fade", tag="div", :css="false", @before-enter="beforeEnter", @enter="enter", @leave="leave")
            .result-col.col-sm-6.col-md-4(v-for="(item, index) in searchResults.results", :key="item.id", :data-index="index")
              excerpt-card(v-if="entity === 'press-release'", :link="item.url ? item.url : '#'")
                template(slot="date")
                  p.date {{item.date}}
                template(slot="title")
                  h4.title.title-20.text-line-height-medium(v-line-clamp:27="3") {{item.title}}
                template(slot="excerpt")
                  p.excerpt {{item.excerpt}}
                template(slot="cta") {{$pgettext('search interface', 'Read')}}
              image-box.border-box.align-left.flex-grow-1.d-flex.flex-column.fill-height(
                v-if="entity === 'story'",
                style="height: 100%",
                text-container-class="flex-grow-1 d-flex flex-column",
                :image="item.image && item.image.thumbs ? {src: item.image.thumbs.news_small, alt: item.title} : {src: null, alt: item.title}",
                :link="item.url ? item.url : '#'"
              )
                template(slot="title")
                  h4.title.title-20.flex-grow-1.m-0(v-line-clamp:27="3") {{item.title}}
                template(slot="text")
                  p.date.text-15.text-color-grey {{item.publication_start}}

              video-box.search-result(
                v-if="entity === 'video'",
                :youtube-id="item.youtube_code",
                :share-from-preview="true"
              )

              picture-preview-box.search-result(
                class="search-result"
                v-if="entity === 'photo'"
                :image="{photoUrl: item.image_photo.file, webUrl: item.image_web.file, printUrl: item.image_print.file, alt: item.title}"
              )
                template(slot="title") {{item.title}}
                template(slot="modal-title") {{item.title}}

        pagination.pagination(:async-pages="paginationLinks")
          .links
            slot(name="pagination")
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  import {updateQueryString, getQueryStringParams, renderQueryStringToUrl} from '@helpers/query-string-helpers';
  import {
    delay, pluck, tap, map, catchError,
    debounceTime, distinctUntilChanged,
    mergeMap, skip, finalize, take
  } from 'rxjs/operators';
  import {of, from} from 'rxjs';

  import Accordion from '@components/accordion/accordion';
  import AccordionItem from '@components/accordion/accordion-item';
  import Checkbox from '@components/atoms/checkbox';
  import SearchBox from '@components/search-box';
  import SlotWrapper from '@components/layout/slot-wrapper';
  import ExcerptCard from '@components/excerpt-card';
  import ImageBox from '@components/image-box';
  import VideoBox from '@components/video-box';
  import PicturePreviewBox from '@components/picture-preview-box';
  import Pagination from './pagination';
  import Icon from '@components/atoms/icon';
  import Btn from '@components/atoms/btn';
  import IconLink from '@components/atoms/icon-link';
  import imagesLoaded from "imagesloaded";
  import Velocity from 'velocity-animate';

  export default {
    name: 'SearchInterface',
    components: {
      IconLink,
      Btn,
      Pagination,
      SearchBar: SearchBox,
      Accordion,
      AccordionItem,
      Checkbox,
      SlotWrapper,
      ExcerptCard,
      ImageBox,
      VideoBox,
      PicturePreviewBox,
      Icon
    },
    props: {
      filters: {type: Object},
      entity: {type: String, required: true},
      useFixture: {type: Boolean, default: false},
      apiEndpoint: {type: String, required: true},
      switches: {type: Array | null, default: () => null},
      maxCollapsedFilters: {type: Number, default: 5}
    },
    data() {
      return {
        axios: axios.create({baseURL: this.apiEndpoint}),
        asyncMode: false,
        filterStatus: {},
        filtersExpanded: {},
        filterHeight: 0,
        loading: false,
        filtersOpen: false
      };
    },
    domStreams: ['textQuery$'],
    subscriptions() {
      return {
        // FIXME text query field does not get pre-populated from filter status
        textQuery: this.textQuery$.pipe(
            debounceTime(200),
            pluck('event', 'msg'),
            map(query => query.trim()),
            distinctUntilChanged(),
            tap(query => this.filterStatus.search = query)
        ),
        searchResults: this.$watchAsObservable('filterStatus', {deep: true}).pipe(
            skip(1),
            debounceTime(10),
            pluck('newValue'),
            tap(() => this.loading = true),
            mergeMap(params => this.apiOrFixture(params).pipe(
                tap(() => updateQueryString(params)),
                catchError(error => of({current_page: 1, final_page: 1, results: []})),
                finalize(() => this.loading = false)
            ))
        ),
        setAsyncMode: this.$watchAsObservable('filterStatus', {deep: true}).pipe(
            skip(1),
            take(1),
            tap(() => this.asyncMode = true)
        )
      };
    },
    asyncComputed: {
      async filtersOrFixture() {
        return this.filters ? this.filters :
            (await import('@fixtures/filters-fixture')).filters;
      }
    },
    computed: {
      mobileLayout() {
        return this.$viewportWidth < 992;
      },
      paginationLinks() {
        if (this.searchResults) {
          let pageLinks = [];
          let locationParams = getQueryStringParams(location.href);
          for (let i = 0; i < this.searchResults.final_page; i++) {
            const query = {
              ...locationParams.query,
              page: i + 1
            };
            pageLinks.push({
              url: renderQueryStringToUrl(locationParams.url, query),
              label: i + 1,
              active: i === 0
            });
          }
          return pageLinks;
        } else {
          return false;
        }
      },
      tooManyFilters() {
        return (category, maxAmount = this.maxCollapsedFilters) => {
          return this.filtersOrFixture[category.key].items.length > maxAmount;
        };
      }
    },
    methods: {
      getFilterHeight() {
        // TODO calculate exact block height
        this.filterHeight = this.$refs['filter'] ?
            this.$refs['filter'][0].$el.clientHeight : 0;
      },
      initFilterStatus(filters) {
        this.filterStatus = Object.keys(filters).reduce((acc, category) => {
          const filter = filters[category];

          if (filter.items && filter.items.length > this.maxCollapsedFilters) {
            Vue.set(this.filtersExpanded, filter.key, false);
          } else {
            Vue.set(this.filtersExpanded, filter.key, true);
          }

          switch (filter.type) {
            case 'text':
              acc[category] = filter.value;
              break;
            case 'radio':
            case 'checkbox':
              // TODO handle arrays in case of checkbox mode
              acc[category] = filter.items.filter(f => f.checked).map(f => f.value)[0];
              break;
            default:
              break;
          }
          return acc;
        }, {});

        this.getFilterHeight();
      },
      apiOrFixture(params) {
        if (this.useFixture) {
          return this.entity === 'story' ?
              from(import('@fixtures/stories-fixture')).pipe(
                  pluck('searchResults'),
                  delay(1000)
              ) :
              this.entity === 'video' ?
                  from(import('@fixtures/videos-fixture')).pipe(
                      pluck('searchResults'),
                      delay(1000)
                  ) :
                  this.entity === 'photo' ?
                      from(import('@fixtures/photos-fixture')).pipe(
                          pluck('searchResults'),
                          delay(1000)
                      ) : '';
        } else {
          return from(this.axios.get('', {params})).pipe(
              pluck('data')
          );
        }
      },
      resetFilters() {
        this.filterStatus = Object.keys(this.filterStatus).reduce((acc, k) => {
          acc[k] = null;
          return acc;
        }, {});
        this.$refs['search-bar'].clear();
      },
      beforeEnter(el) {
        el.style.opacity = 0;
      },
      enter(el, done) {
        imagesLoaded(el, () => {
          const delay = el.dataset.index * 150;
          Velocity(
              el,
              {opacity: 1, translateY: [20, 0]},
              {delay, complete: done}
          );
        });
      },
      leave(el, done) {
        // const delay = el.dataset.index * 150;
        const delay = 150;
        Velocity(
            el,
            // {opacity: 0, translateY: [-20, 0]},
            {opacity: 0},
            {delay, complete: done}
        );
      }
    },
    watch: {
      filtersOpen(val) {
        val && setTimeout(() => this.getFilterHeight(), 10);
      }
    },
    mounted() {
      if (this.filtersOrFixture) {
        this.initFilterStatus(this.filtersOrFixture);
      } else {
        this.$watch('filtersOrFixture', filters => this.initFilterStatus(filters));
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  .filter-modal {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: scroll;
    padding-bottom: 60px;

    .bottom-bar {
      position: fixed;
      z-index: 11;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 0 20px;
      box-shadow: 0 0 6px fade-out($color-black, 0.84);
    }

    .filter-modal-header {
      border-bottom: 1px solid fade-out($color-grey, 0.75);
      margin-bottom: 25px;

      .row {
        min-height: 60px;
      }

      [class*="col-"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .filter-container {
      .search-input {
        margin-bottom: 50px;
      }
    }
  }

  .filter-container {
    @media (min-width: $bootstrap-md) {
      padding-right: 50px;
    }

    .clear {
      margin-bottom: 30px;
    }

    .switches {
      display: flex;
      margin-bottom: 50px;
      border: 1px solid fade-out($color-grey, 0.75);
      border-radius: 4px;
      padding: 3px;

      .switch {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 10px 0;
        border-radius: 4px;
        text-align: center;
        color: $color-blue-lochmara;
        font-size: 20px;

        &.active {
          background-color: $color-blue-lochmara;
          color: $color-white;
        }
      }
    }

    .filter-category {
      margin-bottom: 60px;

      &:last-child {
        margin: 0;
      }
    }

    .category-header {
      margin-bottom: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid fade-out($color-grey, 0.75);
      text-transform: uppercase;
      color: $color-grey;
      letter-spacing: 0.1em;
      font-size: 14px;
      font-weight: $extra-bold;
      line-height: 1.57em;
    }

    .filters {
      overflow: hidden;
      transition: max-height .5s ease-out;

      .filter {
        display: block;
        padding-bottom: 22px;
      }
    }

    .expand-filters {
      font-size: 20px;

      .chevron {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        font-size: 32px;
        transform: rotate(0);
        transition: transform .5s ease-out;
      }

      &.expanded .chevron {
        transform: rotate(180deg);
      }
    }
  }

  .result-row {
    @include responsive-property(padding-top, $section-padding);
    margin-bottom: -40px;
  }

  .pagination {
    text-align: center;
    border-top: 1px solid fade-out($color-grey, 0.75);
    margin-top: 60px;
    padding-top: 40px;

    @media (min-width: $bootstrap-lg) {
      margin-top: 120px;
    }

    .page, .page:visited {
      display: inline-block;
      font-size: 20px;
      margin-right: 35px;
      color: fade-out($color-dark, 0.5);
      line-height: 1.4em;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $color-blue-lochmara;
        border-bottom: 1px solid $color-blue-lochmara;
      }
    }
  }
</style>

<style lang="scss">
  @import "~$scss/variables";

  .SearchInterface {
    .result-col {
      padding-bottom: $grid-gutter-width;
    }
  }
</style>
