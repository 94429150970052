export default {
  install(Vue) {
    Vue.directive('expand', {
      inserted: function(el, binding) {
        if (binding.value !== null) {
          el.classList.add('expand');
          el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false');
          el.setAttribute('aria-hidden', (binding.value) ? 'false' : 'true');
          el.addEventListener('transitionend', () => {
            el.style.height = null;
          });
        }
      },
      update: function(el, binding) {
        if (binding.value !== null) {
          const currentState = el.getAttribute('aria-expanded');
          if ((currentState === 'true') === !!binding.value) {
            return;
          }
          el.classList.add('u-no-transition');
          el.removeAttribute('aria-expanded');
          el.style.height = null;
          el.style.height = el.clientHeight + 'px';
          el.setAttribute('aria-expanded', currentState);
          setTimeout(() => {
            el.classList.remove('u-no-transition')
            el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false');
            el.setAttribute('aria-hidden', (binding.value) ? 'false' : 'true');
          }, 10);
        }
      },
    });
  }
}
