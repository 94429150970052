<template>
  <nav v-if="totalPages > 1" class="Pagination" aria-label="Pagination">
    <ul>
      <template v-for="page in pagesList">
        <li v-if="page.label === totalPages && Math.abs(page.label - currentPage) > interval" class="item">
          <span class="number ellips">&hellip;</span>
        </li>
        <li v-if="Math.abs(page.label - currentPage) < interval || page.label === totalPages || page.label === 1" class="item">
          <a v-if="currentPage !== page.label" :href="page.url" class="number link">{{ page.label }}</a>
          <span v-else class="number current">{{ page.label }}</span>
        </li>
        <li v-if="page.label - 1 === 0 && Math.abs(page.label - currentPage) > interval" class="item">
          <span class="number ellips">&hellip;</span>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'Pagination',
    data() {
      return {
        pages: [],
      }
    },
    props: {
      interval: {type: Number, default: 3},
      asyncPages: {type: Array|Boolean, default: false}
    },
    mounted() {
      const pagination = this.$slots.default[0]
      pagination.children.forEach(item => {
        this.pages.push({
          url: item.data.attrs.href,
          label: parseInt(item.children[0].text),
          active: item.data.staticClass.includes('active'),
        })
      })
    },
    computed: {
      pagesList() {
        return this.asyncPages ? this.asyncPages : this.pages
      },
      totalPages() {
        return this.pagesList.length
      },
      currentPage() {
        return this.pagesList.find(item => item.active).label
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  $pagination-padding: (
    $bootstrap-sm: 40px,
    $bootstrap-md: 50px,
    $bootstrap-lg: 70px,
    $bootstrap-xl: 90px
  );

  .Pagination {
    text-align: center;
    font-size: 0;
    line-height: 1;
    @include responsive-property(padding-top, $pagination-padding);
  }

  .item {
    display: inline-block;
    vertical-align: middle;
  }

  .number {
    font-size: 20px;
    line-height: 44px;
    display: block;
    width: 44px;
    height: 44px;
    text-align: center;
    font-weight: $light;
    color: $color-grey;
    transition: color .3s ease;

    &.current {
      color: $color-blue-lochmara;
      text-decoration: underline;
    }

    &.link {
      &:hover {
        color: $color-blue-lochmara;
      }
    }
  }
</style>
