<template>
  <section class="FigureWidget mt-2 mb-2">
    <div class="image" :style="{backgroundImage: `url(${image})`}">
      <slot name="value"></slot>
    </div>
    <div class="container">
      <div class="content row align-items-center" :style="{minHeight}">
        <div class="col-md-7 col-lg-5">
          <slot name="title"></slot>
          <slot name="text"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'FigureWidget',
    props: {
      image: {
        type: String,
        required: true,
      },
      height: {
        type: String,
        default: 'calc(100vh - 64px)',
      }
    },
    computed: {
      minHeight () {
        if (this.$viewportWidth >= 768) {
          return this.height
        } else {
          return 'auto';
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .FigureWidget {
    background: $color-grey-light;
    position: relative;

    .image {
      height: 300px;
      background: no-repeat 50% 50%;
      background-size: cover;
      position: relative;

      .value {
        position: absolute;
        letter-spacing: -10px;
        font-size: 120px;
        line-height: 1;
        color: $color-blue;
        font-weight: $heavy;
        left: 24px;
        bottom: -60px;
      }

      @media (min-width: $bootstrap-md) {
        z-index: 1;
        right: 0;
        top: 0;
        bottom: 0;
        width: 30%;
        height: auto;
        position: absolute;

        .value {
          left: 0;
          top: 50%;
          transform: translate(-50%, 0);
          margin-top: -90px;
          letter-spacing: -14px;
          font-size: 180px;
          bottom: auto;
        }
      }

      @media (min-width: $bootstrap-md) and (max-width: $bootstrap-lg - 1) {
        .value {
          transform: translate(-50%, 0) rotate(-90deg);
        }
      }

      @media (min-width: $bootstrap-xl) {
        .value {
          font-size: 250px;
          margin-top: -125px;
          letter-spacing: -18px;
        }
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .content {
      padding-top: 90px;
      padding-bottom: 60px;

      @media (min-width: $bootstrap-md) {
        padding-top: 120px;
        padding-bottom: 120px;
      }
    }

    // Theme inverted
    &.inverted {
      background: $color-blue;

      .image {
        .value {
          color: $color-white;
        }

        @media (min-width: $bootstrap-md) {
          right: auto;
          left: 0;

          .value {
            left: auto;
            right: 0;
            transform: translate(50%, 0);
          }
        }

        @media (min-width: $bootstrap-md) and (max-width: $bootstrap-lg - 1) {
          .value {
            transform: translate(50%, 0) rotate(-90deg);
          }
        }
      }

      .content {
        @media (min-width: $bootstrap-md) {
          justify-content: flex-end;
          text-align: right;
        }

        .title, .text {
          color: $color-white;
        }
      }
    }
  }
</style>