<template>
  <component :is="tag" :href="href" :target="target" :type="type" :class="['Btn', {'with-icon': icon}]">
    <slot></slot>
    <icon v-if="icon" class="btn-icon" :name="icon"></icon>
  </component>
</template>

<script>
  import Icon from '@components/atoms/icon'

  export default {
    name: 'Btn',
    components: {Icon},
    props: {
      tag: {
        type: String,
        default: 'a',
      },
      href: {
        type: String,
      },
      target: {
        type: String | Boolean,
        default: false,
      },
      type: {
        type: String,
      },
      icon: {
        type: String,
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/_variables.scss";

  .Btn {
    display: inline-block;
    border: 2px solid $color-dark;
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $color-dark;
    font-weight: $bold;
    padding: 10px 26px 12px;
    vertical-align: top;
    text-align: center;
    transition: background-color .3s ease, color .3s ease;

    &:hover {
      background-color: $color-dark;
      color: $color-white;
    }

    &.blue-lochmara {
      border: 0;
      border-radius: 5px;
      background: $color-blue-lochmara;
      color: $color-white;
      text-transform: none;
      font-size: 20px;
      font-weight: $light;

      &:hover {
        background-color: darken($color-blue-lochmara, 10%);
      }

      &.active {
        background: rgba($color-blue-lochmara, .1) !important;
        color: $color-blue-lochmara;
      }
    }

    &.blue-lochmara-ghost {
      border: 0;
      border-radius: 5px;
      background: fade-out($color-white, .75);
      color: $color-blue-lochmara;
      text-transform: none;
      font-size: 20px;
      font-weight: $light;
      border: 1px solid fade-out($color-blue-lochmara, .33);

      &:hover {
        background-color: darken($color-white, 10%);
      }
    }

    &.small {
      font-size: 16px;
      line-height: 21px;
      padding: 6px 20px 8px;
      font-weight: $normal;
    }

    &.block {
      width: 100%;
      display: block;
      box-sizing: border-box;
    }

    &.with-icon {
      position: relative;
      padding-right: 56px;

      .btn-icon {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    // rounded-ghost theme
    &.rounded-ghost {
      font-weight: $normal;
      text-transform: none;
      border: 1px solid fade-out(#00142F, 0.75);
      border-radius: 5px;
    }
  }
</style>
