import { debounce } from 'debounce'

const ViewportMixins = {
  data(){
    return {
      handleDebouncedResize: null,
      handleDebounceScroll: null,
      currentViewport: null
    }
  },
  methods: {
  	initResizeHandler(){
  		this.handleDebouncedResize = debounce(this.handleResize, 100);
      window.addEventListener('resize', this.handleDebouncedResize);
      this.handleResize();
  	},

    initScrollHandler(){
      this.handleDebounceScroll = debounce(this.handleScroll, 100);
      window.addEventListener('scroll', this.handleDebounceScroll);
      this.handleScroll();
    },

    removeResizeHandler(){
      window.removeEventListener('resize', this.handleDebouncedResize )
    },

    removeScrollHandler(){
      window.removeEventListener('resize', this.handleDebounceScroll )
    },

    handleScroll(){
      /*console.log('handleScroll')*/
    },

    customResizeClbk(){
      /*console.log('customResizeClbk')*/
    },

    setCurrentViewport(w){
      let viewport;
        /*
        @bootstrap-sm: 576px;
        @bootstrap-md: 768px;
        @bootstrap-lg: 992px;
        @bootstrap-xl: 1200px;
        */
        if(w < 321){
          viewport = 'xs'
        } else if(w < 576){
          viewport = 'sm'
        } else if(w < 769){
          viewport = 'md'
        } else if(w < 993){
          viewport = 'lg'
        } else if(w < 1201){
          viewport = 'xl'
        }else {
          viewport = 'xxl'
        }
        this.currentViewport = viewport
    },

  	handleResize(){
  		this.setCurrentViewport(window.outerWidth)
      this.customResizeClbk()
  	},

    isElementXPercentInViewport (el, percentVisible) {
	    let
	      rect = el.getBoundingClientRect(),
	      windowHeight = (window.innerHeight || document.documentElement.clientHeight);

	    return !(
	      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height)) * 100)) < percentVisible ||
	      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
	    )
	  },

  }
}

export default ViewportMixins
