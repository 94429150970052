<template>
  <div class="SidedTitleText">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'SidedTitleText'
  };
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .SidedTitleText {
    padding: 0 0 0 60px;

    @media (min-width: $bootstrap-md) {
      padding: 0 0 0 70px;
    }

    @media (min-width: $bootstrap-lg) {
      padding: 0 0 0 105px;
    }

    @media (min-width: $bootstrap-xl) {
      padding: 0 0 0 130px;
    }

    /deep/ .TitleText {
      position: relative;

      .over-title {
        position: absolute;
        top: 0;
        left: -60px;
        bottom: 0;
        margin: 0;
        line-height: 70px !important;

        @media (min-width: $bootstrap-md) {
          left: -70px;
        }

        @media (min-width: $bootstrap-lg) {
          left: -105px;
          line-height: 120px !important;
        }

        @media (min-width: $bootstrap-xl) {
          left: -130px;
          line-height: 160px !important;
        }
      }
    }
  }
</style>