<template>
  <div class="BlankSpace" :style="{height: currentHeight}"></div>
</template>

<script>
  import { fallback } from '@helpers/responsive-utilities'

  export default {
    name: 'blank-space',
    props: {
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      },
      height: {type: Object, default: () => ({xs: '75px'})}
    },
    computed: {
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      currentHeight () {
        return fallback(this.height, this.currentStep)
      }
    }
  }
</script>
