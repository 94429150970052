import * as types from './mutation_types'

export default {
  $setAppReady ({commit}) {
    return new Promise(resolve => {
      commit(types.SET_APP_READY)
      resolve()
    })
  }
}
