<template>
  <form
    action="#"
    method="get"
    :class="['FreeSearch', {'result-box-open': resultsBoxOpen, loading}]"
    :id="`free-search-${_uid}`"
    ref="Component"
    @submit.prevent="goToSearchPage"
  >
    <label class="input-label">
      <input class="search-input"
             ref="inputField"
             :name="searchConfig.queryVariable"
             type="text"
             :placeholder="l10n.searchPlaceholder"
             v-stream:keyup="queryChanged$"
             v-model="query"
             @focus="onFocus"
      >
      <button v-if="query || mobile" type="submit" class="search-submit" :title="l10n.search">
        <icon name="search"></icon>
        <span class="hidden">{{l10n.search}}</span>
      </button>
    </label>
    <div v-if="loading || noResults || (searchResults && searchResults.length)" class="result-wrapper">
      <ul role="list" class="search-results" v-if="!loading && !!searchResults">
        <li class="result" v-for="(result, idx) in searchResults" :key="`result-${idx}`">
          <a class="result-link" :href="result.link" v-html="fancyResultText(result.text)"></a>
        </li>
        <li class="result" v-if="noResults">
          <span class="result-link">{{l10n.noResults}}</span>
        </li>
      </ul>
      <div class="loading-spinner" v-if="loading">
        <spinner type="dots" :color="mobile ? '#fff' : '#00347B'"></spinner>
      </div>
    </div>
  </form>
</template>

<script>
  import Icon from '@components/atoms/icon'
  import Spinner from '@components/atoms/spinner'
  import { debounceTime, delay, distinctUntilChanged, map, mergeMap, pluck, tap, filter } from 'rxjs/operators'
  import { from, iif, of, Subject, merge } from 'rxjs'
  import { getQueryStringParams, renderQueryStringToUrl } from '@helpers/query-string-helpers'

  export default {
    name: 'FreeSearch',
    components: {Icon, Spinner},
    props: {
      autocompleteDisabled: {type: Boolean, default: false},
      searchConfig: {type: Object, required: true},
      focused: {type: Boolean, default: true},
      useFixture: {type: Boolean, default: false},
      searchApi: {type: String, default: ''},
      l10n: {
        type: Object,
        default () {
          return {
            noResults: 'No results found',
            search: 'Search',
            searchClose: 'Close search',
            searchPlaceholder: 'Search in barillagroup.com',
          }
        },
      },
    },
    domStreams: ['queryChanged$'],
    subscriptions () {
      this.resultsResetter$ = new Subject()
      this.queryChangedHandler$ = this.queryChanged$.pipe(
        filter(() => !this.autocompleteDisabled),
        debounceTime(200),
        pluck('event', 'target', 'value'),
        map(query => query.trim()),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        mergeMap(val =>
          iif( // Empty query returns an empty array of results
            () => val === '',
            of([]),
            iif( // Checks if using fixture
              () => this.useFixture,
              from(import('@fixtures/free-search-fixture')).pipe(
                pluck('default'),
                map(results => (Math.floor(Math.random() * 2) === 0) ? results : []),
                delay(1000)
              ),
              of([]) // TODO request here
            )
          )
        ),
        tap(() => this.loading = false)
      )

      return {
        searchResults: merge(
          this.resultsResetter$.pipe(
            filter(() => !this.autocompleteDisabled),
            filter(reset => !!reset),
            map(() => ([]))
          ),
          this.queryChangedHandler$
        )
      }
    },
    data () {
      return {
        loading: false,
        query: '',
        mobile: false,
      }
    },
    computed: {
      noResults () {
        return this.query !== '' && (this.searchResults && this.searchResults.length === 0)
      },
      resultsBoxOpen () {
        return this.loading || (this.searchResults && this.searchResults.length > 0) || this.noResults
      },
      searchUrl () {
        const locationParams = getQueryStringParams(location.href)
        const query = {...locationParams.query, [this.searchConfig.queryVariable]: this.query}
        return renderQueryStringToUrl(this.searchConfig.url, query)
      }
    },
    methods: {
      fancyResultText (string) {
        const reg = '(' + this.query + ')(?![^<]*>|[^<>]*</)'
        const regex = new RegExp(reg, 'i')
        if (!string.match(regex)) {
          return string
        }
        const matchStartPosition = string.match(regex).index
        const matchEndPosition = matchStartPosition + string.match(regex)[0].toString().length
        const originalTextFoundByRegex = string.substr(matchStartPosition, matchEndPosition)

        return string.replace(regex, `<strong>${originalTextFoundByRegex}</strong>`)
      },
      onFocus () {
        if (this.mobile) {
          this.$emit('inputfocus', true)
        }
      },
      cleanup () {
        this.query = ''
        this.resultsResetter$.next(true)
      },
      goToSearchPage () {
        if (this.autocompleteDisabled) {
          window.location.href = this.searchUrl
        }
      }
    },
    mounted () {
      if (this.focused) {
        this.$refs['inputField'].focus()
      }
      this.mobile = this.$refs['Component'].classList.contains('mobile')
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  @import '~$scss/utility';

  $icon-size: 44px;
  $transition-time: .5s;

  .FreeSearch {
    position: relative;
    padding-top: 100px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  .search-toggle {
    position: absolute;
    right: 0;
    top: 0;
    color: $color-blue;
    font-size: 18px;
    width: $icon-size;
    height: $icon-size;
    transition: opacity $transition-time ease, color $transition-time ease;
    text-align: center;

    &:hover {
      opacity: .75;
    }
  }

  .input-label {
    display: flex;
    position: absolute;
    left: -24px;
    top: 0;
    right: -24px;
    padding: 20px 24px;

    // TODO outline when focused for accessibility?
    .search-input {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      height: 60px;
      font-size: 24px;
      color: $color-dark;
      position: relative;

      &::placeholder {
        color: rgba($color-dark, .5);
      }
    }

    .search-submit {
      color: $color-blue;
      font-size: 18px;
      width: 60px;
      height: 60px;
      padding: 0;
      text-align: center;
      transition: color .3s ease;
      cursor: pointer;

      &:hover {
        color: $color-blue-lochmara;
      }
    }
  }

  .result-wrapper {
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    @include ScrollTouchFix();
    border-top: 1px solid rgba($color-grey, .25);

    .result {
      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        padding-bottom: 30px;
      }
    }

    .result-link {
      display: block;
      color: $color-dark;
      font-size: 20px;
      line-height: 44px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      strong {
        font-weight: $extra-bold;
      }
    }

    span.result-link {
      color: $color-grey;
    }

    a.result-link {
      transition: color .3s ease;

      &:hover {
        color: $color-blue-lochmara;
      }
    }
  }

  .loading-spinner {
    text-align: center;

    .Spinner {
      display: inline-block;
      vertical-align: top;
      padding: 30px 0;
    }
  }

  .FreeSearch.mobile {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    height: auto;

    .input-label {
      display: block;
      position: relative;
      left: auto;
      top: auto;
      right: auto;
      padding: 0;
      flex: 0 0 auto;

      // TODO outline when focused for accessibility?
      .search-input {
        height: 50px;
        font-size: 18px;
        font-weight: $light;
        color: $color-white;
        border-radius: 4px;
        background: rgba($color-white, .1);
        padding: 0 50px;
        position: relative;
        z-index: 1;

        &::placeholder {
          color: rgba($color-white, .75);
        }
      }

      .search-submit {
        color: $color-white;
        font-size: 18px;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
    }

    .result-wrapper {
      flex: 1 1 auto;
      height: auto;

      .result-link {
        color: rgba($color-white, .6);
        font-size: 16px;
        line-height: 36px;
      }

      span.result-link {
        color: $color-grey-light;
        text-align: center;
        padding: 20px 0;
      }

      a.result-link {
        &:hover {
          color: $color-white;
        }
      }
    }
  }
</style>
