<template>
  <a :href="href" class="PositionBox">
    <container class="py-0">
      <div class="row justify-content-center">
        <div :class="colClasses">
          <div class="main-row row align-items-center justify-content-between">
            <div class="col col-lg-10">
              <div class="header row align-items-center mb-4">
                <icon v-if="icon" class="header-icon col-auto" :name="icon"></icon>
                <div class="col">
                  <slot name="title"></slot>
                </div>
              </div>
              <slot name="text"></slot>
            </div>
            <div class="col-auto">
              <icon class="arrow" name="angle-right"></icon>
            </div>
          </div>
          <separator></separator>
        </div>
      </div>
    </container>
  </a>
</template>

<script>
  import Icon from '@components/atoms/icon'
  import IconLink from '@components/atoms/icon-link'
  import Container from '@components/layout/container'
  import Separator from '@components/layout/separator'

  export default {
    name: 'PositionBox',
    components: {Separator, Container, IconLink, Icon},
    props: {
      href: {
        type: String,
        required: true
      },
      icon: {
        type: String
      },
      colClasses: {
        type: String,
        default: 'col-12',
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .PositionBox {
    display: block;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 100vw;
      height: 100%;
      transform: translate(-50%, 0);
      pointer-events: none;
      transition: box-shadow .5s ease;
    }

    .header, .arrow {
      color: $color-blue !important;
      transition: color .3s ease;
    }

    &:hover {
      .header, .arrow {
        color: $color-blue-lochmara !important;
      }

      &:after {
        z-index: 2;
        box-shadow: 0 0 20px rgba($color-dark, .1);
      }
    }
  }

  .main-row {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .arrow {
    font-size: 32px;
    width: 32px;
    height: 32px;
    text-align: center;
    display: block;
  }

  .header {
    margin-top: -20px;
  }

  .header-icon {
    font-size: 48px;
  }
</style>
