<template>
<article :aria-labelledby="`title-link-${_uid}`" class="ExcerptCard" :class="{'no-image': !image}">
  <div class="image-link" v-if="image" :href="link" :target="target">
    <img :src="image.src" :alt="image.alt" class="image resp-image">
  </div>

  <slot name="date"></slot>

  <div v-if="hasCategory" class="category">
    <a v-if="categoryLink" class="categoryLink" :href="categoryLink" :target="categoryTarget">
      <slot name="category" />
    </a>
  </div>
  <div class="title-link" :href="link" :target="target" :id="`title-link-${_uid}`">
    <slot name="title"></slot>
  </div>

  <slot name="excerpt"></slot>

  <icon-link v-if="link" class="cta" :href="link" :target="target" :icon="linkIcon" :aria-label="linkAriaLabel">{{linkText}}</icon-link>
</article>
</template>

<script>
import IconLink from '@components/atoms/icon-link'

export default {
  name: 'ExcerptCard',
  components: { IconLink },
  props: {
    linkText: {
      type: String,
      default: 'Read'
    },
    linkAriaLabel: {
      type: String,
      default: null
    },
    link: {
      type: String,
      required: true,
    },
    target: {
      type: String,
    },
    image: {
      type: Object,
    },
    linkIcon: {
      type: String,
      default: 'arrow-right'
    },
    categoryLink: {
      type: String
    },
    categoryTarget: {
      type: String
    },
  },
  computed: {
    hasCategory() {
      return !!(this.$slots['category'] || [])[0];
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~$scss/variables";

.ExcerptCard {
  @media (min-width: $bootstrap-md) {
    position: relative;
    height: 100%;
    padding-bottom: 30px;

    .excerpt {
      min-height: 105px;
    }

    .IconLink {
      position: absolute;
      bottom: 0;
      margin-top: 0;
    }
  }
}

.image-link {
  display: block;
  margin-bottom: 18px;

  .image {
    width: 100%;
    transition: opacity .3s ease;

    &:hover {
      opacity: .75;
    }
  }
}

.date {
  color: fade-out($color-dark, 0.25);
}

.title {
  margin: 24px 0 12px;

  @media (min-width: $bootstrap-lg) {
    margin: 35px 0 20px;
  }
}

.excerpt p {
  margin: 0;
}

.title-link {
  display: block;
  margin: 35px 0 20px;

  .title {
    margin: 0;
    transition: color .3s ease;
  }

  &:hover {
    .title {
      color: $color-dark;
    }
  }
}

.cta {
  margin-top: 50px;
}

.ExcerptCard {
  &.no-image {
    border-top: 1px solid $color-grey-medium-2;
    padding-top: 20px;
  }

  &.small-title-margin {
    .title-link {
      margin: 20px 0 8px;

      @media (min-width: $bootstrap-lg) {
        margin: 30px 0 10px;
      }
    }
  }
}

//Press Room theme
.ExcerptCard {
  &.press-room {
    height: 100% !important;

    @media(min-width:$bootstrap-md){
      border-right: 1px solid rgba($color-dark, 0.2);
      padding-right: 27px;

      &.no-border {
        border:0;
      }
    }

    .category {
      padding-top: 22px;

      .categoryLink {
        color: rgba($color-dark, 0.5);
        font-weight: $extra-bold;
        font-size: 14px;
        letter-spacing: 0.1em;

        @media (min-width: $bootstrap-lg) {
          margin: 0 0 14px;
        }
      }
    }

    .title-link {
      margin-top: 21px;
      margin-bottom: 12px;

      h4 {
        font-size: 20px !important;
        line-height: 32px !important;
      }
    }

    .cta {
      margin-top: 40px;

       @media(min-width:$bootstrap-md){
          margin-top: 66px;
      }
    }
  }
}

.ExcerptCard {
  &.press-room-releases {
    .category {
      padding-top: 20px;

      .categoryLink {
        color: rgba($color-dark, 0.5);
        font-weight: $extra-bold;
        font-size: 14px;
        letter-spacing: 0.1em;

        @media (min-width: $bootstrap-lg) {
          margin: 0 0 14px;
        }
      }
    }

    .cta {
      margin-top: 30px;

      @media(min-width:$bootstrap-lg){
        margin-top: 34px;
      }
    }
  }
}
</style>
