<template>
  <div class="FeaturingGrid">
    <div class="row">
      <div
        :class="`featured-col col-lg-${featuredColSize} d-flex flex-column`"
      >
        <slot name="featured"></slot>
      </div>
      <div :class="`col-lg-${rightColSize}`">
        <slot-wrapper
          class="side-row row"
          :wrapper-class="[
            'side-col d-flex flex-column',
            `col-lg-${12/rightColDivisions}`,
            rightColBorders
          ]"
        >
          <slot name="side"></slot>
        </slot-wrapper>
      </div>
    </div>
    <slot-wrapper
      class="row d-none d-lg-flex"
      wrapper-class="col-sm-6 col-lg-3 pt-3 d-flex flex-column"
    >
      <slot name="others"></slot>
    </slot-wrapper>
  </div>
</template>

<script>
  import Container from '@components/layout/container'
  import SlotWrapper from '@components/layout/slot-wrapper'

  export default {
    name: 'FeaturingGrid',
    components: {Container, SlotWrapper},
    props: {
      featuredColSize: {type: Number, default: 6},
      rightColDivisions: {type: Number, default: 2},
      rightColBorders: {type: String, default: ''}
    },
    computed: {
      rightColSize () {
        return 12 - this.featuredColSize
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .FeaturingGrid {
    .featured-col {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .side-row /deep/ .side-col {
      padding-top: 12px;
      padding-bottom: 12px;

      &.borders {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 12px;
          right: 12px;
          bottom: 0;
          height: 1px;
        }

        &:last-child:after {
          display: none;
        }
        &.white:after{
            background: fade-out($color-white, 0.5);
        }
        &.grey:after{
            background: fade-out($color-grey, 0.75);
        }
      }
    }

    // Theme: vertically-wide (wider vertical gutter)
    &.vertically-wide {
      .featured-col {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .side-row /deep/ .side-col {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
</style>
