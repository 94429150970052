<script>
  import AspectRatioBox from '@components/layout/aspect-ratio-box'
  import Icon from '@components/atoms/icon'
  import { fallback } from '@helpers/responsive-utilities'
  import AccessibleDialog from '@components/layout/accessible-dialog'

  export default {
    name: 'MultimediaBox',
    components: {AspectRatioBox, Icon, AccessibleDialog},
    props: {
      // FIXME duplicate from flex-grid, consider using a mixin or integrating in vue-viewport-manager
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      },
      responsiveRatio: {type: Object, default: () => null},
      small: {type: Boolean, default: false},
      xSmall: {type: Boolean, default: false},
      overTitleTag: {type: String, default: 'p'},
      titleTag: {type: String, default: 'h4'},
      modalTitleTag: {type: String, default: 'h4'}
    },
    data () {
      return {
        dialog: null,
        smallCard: false,
        searchResult: false
      }
    },
    computed: {
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      playerSize () {
        const ratio = 1.8

        let width
        if (this.$viewportWidth < 768) {
          width = this.$viewportWidth / 100 * 90
        } else {
          width = Math.min(this.$viewportWidth / 100 * 80, 1056)
        }

        const height = width / ratio
        return {width, height}
      },
      posterRatio () {
        return this.responsiveRatio ? fallback(this.responsiveRatio, this.currentStep) :
          this.small ? 1.58 :
            this.xSmall ? 1.84 :
              1.74
      },
      portraitPreview () {
        return this.posterRatio < 1
      }
    },
    methods: {
      closeDialogHandler () {
        // Override this if needed
      },
      openDialogHandler () {
        // Override this if needed
      },
      posterClickHandler () {
        this.$refs['media-dialog'].open()
      }
    },
    mounted () {
      this.smallCard = this.$el.classList.contains('small')
        || this.$el.classList.contains('x-small')

      this.searchResult = this.$el.classList.contains('search-result')
    }
  }
</script>
