<template>
  <section class="PageHeader">
    <div
      v-if="$slots['top-overlay']"
      :class="['top-overlay', topOverlayContainerClasses]"
    >
      <div class="row justify-content-center">
        <div
          :class="['content-overlay-col', topOverlayColClasses]"
          :style="{marginBottom: currentTopOverlayOverlap}"
        >
          <slot name="top-overlay"></slot>
        </div>
      </div>
    </div>

    <div
      class="header"
      :style="background"
      ref="header-container"
    >
      <responsive-img
        v-if="headerConfig"
        class="responsive-bg"
        :sources="headerConfig"
      ></responsive-img>

      <div
        v-if="$slots['text-overlay']"
        :class="['container', {'with-content': $slots['content-overlay']}]"
      >
        <div :class="textOverlayRowClasses" :style="{minHeight: this.currentHeight}">
          <div :class="textOverlayColClasses">
            <slot name="text-overlay"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$slots['content-overlay']" :class="['content-overlay', contentOverlayContainerClasses]">
      <div class="row justify-content-center">
        <div
          :class="['content-overlay-col', contentOverlayColClasses]"
          :style="{marginTop: currentOverlayOverlap}"
        >
          <slot name="content-overlay"></slot>
        </div>
      </div>
    </div>

    <div
      v-if="$slots['raw-overlay']"
      class="content-overlay raw"
      :style="{marginTop: currentOverlayOverlap}"
    >
      <slot name="raw-overlay"></slot>
    </div>
  </section>
</template>

<script>
  /*
   * configs = {
   *   xs: {
   *     '1x': {src: 'xs/1x/image/url.png', alt: 'alt text', height: 1000},
   *     '2x': {src: 'xs/2x/image/url.png', alt: 'alt text', height: 1000},
   *     ...
   *   },
   *   sm: {
   *     ...
   *   }
   * }
   */
  import { fallback } from '@helpers/responsive-utilities'
  import ResponsiveImg from '@components/atoms/responsive-img'

  export default {
    name: 'PageHeader',
    components: {ResponsiveImg},
    props: {
      image: {
        type: String,
        required: true,
      },
      headerConfig: {
        type: Object
      },
      textOverlayRowClasses: {
        type: String,
        default: 'row justify-content-center align-items-center'
      },
      textOverlayColClasses: {
        type: String,
        default: 'col-md-10 col-lg-8 col-xl-6',
      },
      topOverlayContainerClasses: {
        type: String,
        default: 'container'
      },
      topOverlayColClasses: {
        type: String,
        default: 'col-lg-10 col-xl-8'
      },
      contentOverlayContainerClasses: {
        type: String,
        default: 'container'
      },
      contentOverlayColClasses: {
        type: String,
        default: 'col-lg-10 col-xl-8',
      },
      height: {
        type: String,
        default: 'calc(100vh - 70px)',
      },
      minHeight: {
        type: String,
        default: '360px',
      },
      maxHeight: {
        type: String,
        default: 'none',
      },
      overlayOverlap: {
        type: Object,
        default: () => ({xs: -100})
      },
      topOverlayOverlap: {
        type: Object,
        default: () => ({xs: -100})
      },
      rawOverlay: {
        type: Boolean,
        default: false
      },
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      }
    },
    data () {
      return {
        responsiveBgSize: null
      }
    },
    watch: {
      $viewportWidth () {
        const $header = this.$viewportWidth ? this.$refs['header-container'] : null
        if ($header) {
          const portrait = $header.getBoundingClientRect().width < $header.getBoundingClientRect().height
          this.responsiveBgSize = {
            height: portrait ? '100%' : 'auto',
            width: portrait ? 'auto' : '100%'
          }
        } else {
          this.responsiveBgSize = null
        }
      }
    },
    computed: {
      background () {
        return this.headerConfig ? {
          height: this.currentHeight,
          minHeight: this.minHeight,
          maxHeight: this.maxHeight
        } : {
          height: this.currentHeight,
          minHeight: this.minHeight,
          maxHeight: this.maxHeight,
          backgroundImage: `url('${this.image}')`
        }
      },
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      currentOverlayOverlap () {
        return `${fallback(this.overlayOverlap, this.currentStep)}px`
      },
      currentTopOverlayOverlap() {
        return `${fallback(this.topOverlayOverlap, this.currentStep)}px`
      },
      currentHeight () {
        if (this.headerConfig) {
          const currentSrc = fallback(this.headerConfig, this.currentStep)
          const densities = Object.getOwnPropertyNames(currentSrc)
          return currentSrc[densities[0]]['height']
        } else {
          return this.height
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  .PageHeader {
    &.padded .header {
      @include responsive-property(padding-top, $section-padding);
    }

    .header {
      background: no-repeat 50% 50%;
      background-size: cover;
      position: relative;
      z-index: 1;

      .responsive-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
      }

      .container {
        .row {
          padding-top: 24px;
          padding-bottom: 24px;

          main.no-padding & {
            padding-top: 88px;
          }
        }
      }

      .with-content {
        .row {
          padding-bottom: 264px;
        }
      }
    }

    .content-overlay, .top-overlay {
      position: relative;
      z-index: 2;

      .content-overlay-col {
        padding-bottom: 80px;

        // Theme shadow box
        &.shadow-box {
          box-shadow: 0 0 15px rgba($color-black, .1);
        }
      }
    }
  }

  .PageHeader {
    // White background theme
    &.white-bg {
      background: $color-white;
    }

    // Backdrop theme
    &.backdrop {
      .header {
        position: relative;

        &:after {
          content: ' ';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: rgba($color-black, .3);
        }

        .container {
          position: relative;
          z-index: 2;
        }
      }
    }
  }
</style>
