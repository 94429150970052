<template lang="pug">
  extends ./templates/multimedia-box/multimedia-box.pug
  block variables
    - const rootClass = 'PicturePreviewBox'

  block sr-label
    slot(name="sr-label")

  block title
    slot(name="title")

  block statistics
    icon-link.download-link.text-18(
      icon="arrow-down",
      target="_blank",
      :href="posterImage.url"
      :aria-label="arialabel ? arialabel : $pgettext('multimedia preview card', 'Download jpg') + ' ' + imageAlt"
    ) {{linktext ? linktext : $pgettext('multimedia preview card', 'Download jpg')}}

  block player
    img( class="player-img resp-image", :src="image['webUrl']", :alt="imageAlt")

  block modal-title
    slot(name="modal-title")

  block modal-meta
    icon-link.download-link(
      icon="arrow-down",
      target="_blank",
      :href="image['printUrl']"
      :aria-label="arialabel ? arialabel : $pgettext('multimedia preview card', 'Download jpg') + ' ' + imageAlt"
    ) {{linktext ? linktext : $pgettext('multimedia preview card', 'Download jpg')}}
</template>

<script>
  import MultimediaBox from './templates/multimedia-box/multimedia-box'
  import IconLink from '@components/atoms/icon-link'

  export default {
    name: 'PicturePreviewBox',
    extends: MultimediaBox,
    components: {IconLink},
    props: {
      small: {type: Boolean, default: false},
      image: {type: Object, required: true},
      linktext: {type: String, required: false},
      arialabel: {type: String, required: false},
    },
    computed: {
      posterImage () {
        return {url: this.image['photoUrl']}
      },
      imageAlt() {
        return this.image.alt ? this.image.alt : 'Download'
      }
    }
  }
</script>

<style lang="scss" src="./templates/multimedia-box/multimedia-box.scss" scoped></style>
<style lang="scss" scoped>
  @import '~$scss/variables';

  .PicturePreviewBox {
    .download-link {
      font-weight: $light;
    }

    .player-img {
      width: 100%;
    }

    .download-link {
      color: $color-blue-lochmara;
    }
  }

  // Theme: inverted
  .PicturePreviewBox.inverted {
    .download-link {
      color: $color-white;
    }
  }

  // Theme: search-result
  .PicturePreviewBox.search-result {
    .statistics {
      margin-top: 10px;
    }
  }
</style>
