<template>
  <component
    :is="componentTag"
    :class="['ImageBox', {link: link}]"
    :href="hasNestedLink ? null : link"
    :target="hasNestedLink ? null : target"
  >
    <a
      v-if="hasNestedLink"
      :href="link"
      :target="target"
    >
      <template v-if="!responsiveImg">
        <aspect-ratio-box v-if="aspectRatio" :ratio="aspectRatio">
          <img :src="computedImage.src" :alt="computedImage.alt">
        </aspect-ratio-box>
        <img v-else :src="computedImage.src" :alt="computedImage.alt" class="image resp-image">
      </template>
      <template v-else>
        <aspect-ratio-box v-if="aspectRatio" :ratio="aspectRatio">
          <responsive-img :sources="computedImage"></responsive-img>
        </aspect-ratio-box>
        <responsive-img
          v-else
          :sources="computedImage"
          class="image resp-image"
        ></responsive-img>
      </template>
    </a>
    <template v-else>
      <template v-if="!responsiveImg">
        <aspect-ratio-box v-if="aspectRatio" :ratio="aspectRatio">
          <img :src="computedImage.src" :alt="computedImage.alt">
        </aspect-ratio-box>
        <img v-else :src="computedImage.src" :alt="computedImage.alt" class="image resp-image">
      </template>
      <template v-else>
        <aspect-ratio-box v-if="aspectRatio" :ratio="aspectRatio">
          <responsive-img :sources="computedImage"></responsive-img>
        </aspect-ratio-box>
        <responsive-img
          v-else
          :sources="computedImage"
          class="image resp-image"
        ></responsive-img>
      </template>
    </template>

    <div class="separator-wrapper" v-if="hasSeparator">
      <separator></separator>
    </div>

    <component
      v-if="hasCategory || hasTitle || hasText || showMoreLink || hasIconLink"
      class="text-container"
      :class="textContainerClass"
      :is="contentTag"
    >
      <template v-if="hasCategory">
        <a
          v-if="categoryLink"
          class="category"
          :href="categoryLink"
          :target="categoryTarget"
        >
          <slot name="category" />
        </a>
        <span
          v-else
          class="category"
        >
          <slot name="category" />
        </span>
      </template>
      <a
        v-if="hasTitle && hasNestedLink"
        :href="link"
        :target="target"
      >
        <slot name="title" />
      </a>
      <slot
        v-else
        name="title"
      />
      <a
        v-if="hasText && hasNestedLink"
        :href="link"
        :target="target"
      >
        <slot name="text" />
      </a>
      <slot
        v-else
        name="text"
      />
      <template v-if="showMoreLink">
        <icon-link
          v-if="showMoreLink && $viewportWidth >= 992"
          :class="iconLinkClass"
          :href="showMoreLink"
          :target="showMoreTarget"
          icon="arrow-right"
        >
          <template v-if="dataLanguage==='it'">Leggi</template>
          <template v-else>Show more</template>
        </icon-link>
      </template>
      <div v-else-if="hasIconLink">
        <slot name="icon-link" />
      </div>
    </component>
  </component>
</template>

<script>
import AspectRatioBox from '@components/layout/aspect-ratio-box';
import Separator from '@components/layout/separator';
import ResponsiveImg from '@components/atoms/responsive-img';
import IconLink from '@components/atoms/icon-link';

export default {
  name: 'ImageBox',
  components: {
    IconLink,
    ResponsiveImg,
    AspectRatioBox,
    Separator
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    responsiveImg: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number
    },
    hasSeparator: {
      type: Boolean,
      default: false
    },
    link: {
      type: String
    },
    target: {
      type: String
    },
    categoryLink: {
      type: String
    },
    categoryTarget: {
      type: String
    },
    showMoreLink: {
      type: String
    },
    showMoreTarget: {
      type: String
    },
    textContainerClass: {
      type: String
    },
    iconLinkClass: {
      type: String,
      default: 'big mt-0 pt-0 mt-lg-1 pt-lg-4'
    },
    dataLanguage:{
      type: String,
      default:'en'
    }
  },
  computed: {
    componentTag() {
      return this.link ? this.hasNestedLink ? 'div' : 'a' : 'figure';
    },
    contentTag() {
      return this.link ? 'div' : 'figcaption';
    },
    hasCategory() {
      return !!(this.$slots['category'] || [])[0];
    },
    hasTitle() {
      return !!(this.$slots['title'] || [])[0];
    },
    hasText() {
      return !!(this.$slots['text'] || [])[0];
    },
    hasIconLink() {
      return !!(this.$slots['icon-link'] || [])[0];
    },
    hasNestedLink() {
      return !!(this.link && (this.categoryLink || this.showMoreLink || this.hasIconLink));
    },
    computedImage() {
      if (this.$viewportWidth < 768) {
        // mobile
        if (this.image.src_mobile) {
          return {
            src: this.image.src_mobile,
            alt: this.image.alt
          }
        }
      } else {
        // desktop
        if (this.image.src_desktop) {
          return {
            src: this.image.src_desktop,
            alt: this.image.alt
          }
        }
      }

      return this.image
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";
@import "~$scss/utility";

.ImageBox {
  display: block;

  &.link {
    .image {
      transition: opacity .3s ease;
    }

    &:hover {
      .image {
        opacity: .8;
      }
    }
  }

  &.white-bg {
    .text-container {
      background: #fff;
    }
  }

  &.brussia {
    .text-container {
      .title-32 {
        line-height: normal !important;
      }

      .text-20 {
        line-height: normal !important;
      }
    }
  }
}

.separator-wrapper {
  padding: 0 30px;
}

.text-container {
  text-align: center;
  margin-top: 30px;
}

.category {
  margin: 0 0 4px;
  color: $color-white;
  font-weight: $extra-bold;
  font-size: 14px;
  letter-spacing: 0.1em;

  @media (min-width: $bootstrap-lg) {
    margin: 0 0 14px;
  }
}

.title, .text {
  margin: 16px 0;
  word-break: break-word !important;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ImageBox {
  // Theme Text over image
  &.text-over {
    position: relative;
    overflow: hidden;

    .image {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(to bottom, fade-out($color-dark, 1) 0%, fade-out($color-dark, 1) 43%, fade-out($color-dark, 0.25) 100%);
    }

    .text-container {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: 24px;
      left: 24px;
      padding: 0;
      margin: 0;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.align-bottom {
      .text-container {
        position: absolute;
        box-sizing: border-box;
        display: block;
        left: 0;
        width: 100%;
        z-index: 3;
        margin-top: 0;
        text-align: left;

        $wrapper-padding: (
          $bootstrap-sm: 0 20px 0,
          $bootstrap-md: 0 30px 0,
          $bootstrap-lg: 0 40px 0,
          $bootstrap-xl: 0 50px 0
        );

        top: auto;
        $bottom: (
          $bootstrap-sm: 20px,
          $bootstrap-md: 30px,
          $bootstrap-lg: 40px,
          $bootstrap-xl: 50px
        );

        @include responsive-property(padding, $wrapper-padding);
        @include responsive-property(bottom, $bottom);
      }
    }

    .title {
      color: $color-white;
      max-width: 390px;
    }

    .text {
      color: $color-white;
      margin-top: 0;
      max-width: 390px;
    }

    &.link {
      .image {
        opacity: 1 !important;
        transition: transform .5s ease;
      }

      &:before {
        transition: top .5s ease;
      }

      &:hover {
        .image {
          transform: scale(1.05);
        }

        &:before {
          top: -100%;
        }
      }
    }
  }

  // Theme Story card
  &.story-card {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border: 1px solid fade-out($color-grey, 0.75);

    &.transparent {
      background: transparent;
    }

    &.no-border {
      border: none;
    }

    @media (min-width: $bootstrap-md) {
      /* pixel perfect forced style */
      img {
        background-color: $color-blue;
        min-height: 220px;
      }
    }

    .text-container {
      border-top: none;
      margin-top: 0;
      flex: 1;
      padding: 26px 25px 23px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.no-border {
        border: none;
      }

      .category {
        margin: 0 0 34px;
        color: $color-grey;

        @media (min-width: $bootstrap-md) {
          margin: 0 0 18px;
        }
      }

      .title-20 {
        @media (max-width: $bootstrap-md - 1) {
          font-size: 18px !important;
        }

        @media (min-width: $bootstrap-md) {
          line-height: 32px !important;
        }
      }
    }

    &:not(.highlighted) {
      @media (max-width: $bootstrap-md - 1) {
        padding-left: 130px;
        position: relative;

        .image {
          width: 130px;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          object-fit: cover;
        }

        .text-container {
          padding: 15px;
          box-sizing: border-box;
          min-height: 160px;
        }
      }
    }

    &.highlighted {
      @media (min-width: $bootstrap-md) {
        position: relative;
        padding-top: 45.2%;

        .image {
          position: absolute;
          right: 0;
          top: 0;
          width: 57.1%;
          height: 100%;
          object-fit: cover;
        }

        .text-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 42.9%;
          box-sizing: border-box;

          .text-24 {
            font-size: 20px !important;
            line-height: 30px !important;
          }
        }
      }

      @media (min-width: $bootstrap-xl) {
        .text-container {
          padding: 60px 75px 0 60px;

          .title-20 {
            font-size: 32px !important;
            line-height: 44px !important;
          }

          .text-24 {
            font-size: 24px !important;
            line-height: 34px !important;
          }
        }
      }
    }

    // SubTheme Story card  Border-box
    &.border-box{
      &:not(.highlighted) {
        @media (max-width: $bootstrap-lg - 1) {
          padding-left: 0;

          .image {
            width: 100%;
            position: static;
          }
        }

        .text-container {
          padding: 30px 25px 25px 30px;

          @media(min-width: $bootstrap-lg){
            padding: 30px 25px;
          }

          :last-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  // Theme shadow box
  &.shadow-box {
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, .1);

    .text-container {
      padding: 16px 20px 24px;
    }

    .text-container {
      margin-top: 0;
    }
  }

  // Theme align left
  &.align-left {
    .text-container {
      text-align: left;
    }
  }

  // Theme large padding
  &.large-padding {
    .text-container {
      padding: 9.6%;
    }
  }

  &.large-padding.text-over {
    .text-container {
      top: 9.6%;
      right: 9.6%;
      bottom: 9.6%;
      left: 9.6%;
      padding: 0 0 10px 0;
    }
  }

  // Theme border box
  &.border-box {
    border: 1px solid $color-grey-medium-2;

    .text-container {
      margin: 0;
      padding: 50px 25px 25px;

      :last-child {
        margin-top: 40px;
      }
    }

    // Sub-theme linkedin
    &.linkedin {
      border-bottom: 3px solid $color-linkedin;

      .date {
        padding-right: 30px;
        position: relative;

        .icon-linkedin {
          position: absolute;
          right: 0;
          top: 50%;
          font-size: 20px;
          margin-top: -10px;
          color: $color-linkedin;
        }
      }
    }

    // Sub-theme big
    &.big {
      @media (min-width: $bootstrap-md) {
        .text-container {
          .title {
            margin-bottom: 30px;
          }

          :last-child {
            margin-top: 60px;
          }
        }
      }

      @media (max-width: $bootstrap-md - 1) {
        margin-left: -24px;
        margin-right: -24px;
        border-left: 0;
        border-right: 0;
        border-top: 0;
      }
    }
  }

  // Theme yellow box
  &.yellow-box {
    background: rgba($color-champagne, .25);
    padding-top: 20px;
    min-height: 100%;
    box-sizing: border-box;

    .text-container {
      padding: 0 25px 25px;
      margin: 0;

      @media (min-width: $bootstrap-lg) {
        padding: 0 50px 50px;
      }
    }
  }
}
</style>