<template>
  <div class="PostitOnContent">
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div class="postit">
      <slot name="postit"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PostitOnImage'
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  $layout-switch: $bootstrap-md;

  .PostitOnContent {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: $layout-switch) {
      justify-content: flex-start;
    }

    .content {
      flex: 0 0 100%;
      max-width: 100%;

      @media (min-width: $layout-switch) {
        flex: 0 0 70%;
        max-width: 70%;
      }
    }

    .postit {
      margin-top: -40px;
      flex: 0 0 89%;
      max-width: 89%;
      background-color: $color-white;
      padding: 34px 30px;
      box-shadow: 0 5px 10px rgba($color-dark, 0.1);

      @media (min-width: $layout-switch) {
        margin-top: 0;
        position: absolute;
        width: 40%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 56px 50px;
      }
    }

    // Reverse theme (content on the right)
    &.reverse {
      @media (min-width: $layout-switch) {
        justify-content: flex-end;
      }

      .postit {
        @media (min-width: $layout-switch) {
          right: auto;
          left: 0;
        }
      }
    }

    &.chained {
      padding: 40px 0;

      &:nth-child(even) {
        @media (min-width: $layout-switch) {
          justify-content: flex-end;
        }

        .postit {
          @media (min-width: $layout-switch) {
            right: auto;
            left: 0;
          }
        }
      }
    }
  }
</style>
