<template>
  <div class="Perspective2030" v-if="configOrFixture">
    <h4 class="label">{{configOrFixture.title}}</h4>
    <div class="button-group-container">
      <div class="button-group" v-if="configOrFixture && !configOrFixture.hide_buttons">
        <button
          :class="['button', {active: active === 'now'}]"
          @click="active = 'now'"
        >{{$pgettext('now to 2030', 'Now')}}
        </button>
        <button
          :class="['button', {active: active === 'future'}]"
          @click="active = 'future'"
        >{{$pgettext('now to 2030', 'Future')}}
        </button>
      </div>
    </div>
    <div class="items">
      <perspective2030-item
        class="perspective-item"
        v-for="(item, idx) in configOrFixture.items"
        :key="`now-2030-${idx}-${_uid}`"
        :config="item"
        :state="active"
        :colors="colors[idx % colors.length]"
      ></perspective2030-item>
    </div>
  </div>
</template>

<script>
  import Perspective2030Item from '@components/perspective-2030-item'

  export default {
    name: 'Perspective2030',
    components: { Perspective2030Item },
    props: {
      slideConfig: { type: Object }
    },
    data () {
      return {
        active: 'future',
        colors: [
          { now: 'yellow-green', future: 'limeade' },
          { now: 'viking', future: 'blue-lochmara' },
          { now: 'porsche', future: 'orange' }
        ]
      }
    },
    asyncComputed: {
      async configOrFixture () {
        return this.slideConfig ?
          await new Promise(resolve => resolve(this.slideConfig))
          : (await import('@fixtures/now-to-2030-fixture')).slides[0]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .label {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($color-grey, 0.25);
    margin-bottom: 50px;
    color: $color-grey;
    font-weight: $extra-bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: $bootstrap-md) {
      text-align: left;
    }
  }

  .button-group {
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba($color-grey, 0.25);
    border-radius: 4px;

    @media (min-width: $bootstrap-md) {
      display: inline-block;
    }

    .button {
      box-sizing: border-box;
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      border: none;
      padding: 0 40px;
      text-align: center;
      font-size: 20px;
      line-height: 50px;
      color: rgba($color-grey, 0.5);
      transition: color .3s ease-out;

      &:after {
        content: '';
        position: absolute;
        height: 20px;
        width: 1px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba($color-grey, 0.25);
      }

      &:last-child:after {
        display: none;
      }

      &.active {
        color: $color-blue;
      }
    }
    &-container{
      min-height: 50px;
    }
  }

  .perspective-item {
    margin-top: 50px;
  }
</style>
