<template lang="pug">
  .VideoInline(
    :style="playerSize"
  )
    transition(name="fade")
      .stuff(v-if="!isPlaying")
        button.play-button(@click="playVideo")
          svg.play-icon(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 64 64")
            circle(style="fill: #ffffff; opacity: 0.72;", cx="32", cy="32", r="32")
            path(style="fill: #00142f; opacity: 0.75;", d="M45,32,23,44V20Z")
        img.poster-image(:src="posterImage ? posterImage.url : null", :alt="ytMeta ? ytMeta.snippet.title : null")

    div.wrapper
      youtube.player(
        :video-id="youtubeId",
        ref="player",
        width="100%",
        height="100%"
      )
</template>

<script>
  import VideoBox from '@components/video-box'

  export default {
    name: 'VideoInline',
    extends: VideoBox,
    data () {
      return {
        isPlaying: false
      }
    },
    props: {
      ratio: {type: Number, default: 1.8}
    },
    computed: {
      playerSize () {
        return {
          paddingBottom: `${100 / this.ratio}%`
        }
      }
    },
    methods: {
      playVideo () {
        this.isPlaying = true
        this.$refs['player'].player.playVideo()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .VideoInline {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;

    .poster-image {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    .wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .play-button {
      position: absolute;
      z-index: 3;
      width: 64px;
      height: 64px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .play-icon {
      width: 100%;
    }
  }
</style>
