<template>
  <div
    class="LayeredBackground"
    :style="bgStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'LayeredBackground',
    props: {
      layers: {type: Array | Object, default: () => null}
    },
    computed: {
      bgStyle () {
        const layers = !this.layers ? [] :
          Array.isArray(this.layers) ?
            this.layers : [this.layers]

        return layers.reduce((acc, layer, idx) => {
          // TODO handle empty strings and undefined properties
          return idx === 0 ? {
            backgroundImage: `${layer.image}`,
            backgroundPosition: `${layer.position}`,
            backgroundSize: `${layer.size}`,
            backgroundRepeat: `${layer.repeat}`
          } : {
            backgroundImage: `${acc.backgroundImage}, ${layer.image}`,
            backgroundPosition: `${acc.backgroundPosition}, ${layer.position}`,
            backgroundSize: `${acc.backgroundSize}, ${layer.size}`,
            backgroundRepeat: `${acc.backgroundRepeat}, ${layer.repeat}`
          }
        }, {backgroundImage: '', backgroundPosition: '', backgroundSize: '', backgroundRepeat: ''})
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "~$scss/variables";
@import "~$scss/utility";

.LayeredBackground {
  &.bg-grancereale {
    @media (min-width: 1570px) {
      background-size: contain !important;
    }

    #grancereale {
      max-height: 687px;
    }
  }
}
</style>
