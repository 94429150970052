<template>
  <external-js></external-js>
</template>

<script>
  import Vue from 'vue'
  Vue.config.ignoredElements.push('external-js')

  export default {
    name: 'JsLoader',
    props: {
      src: {type: String, required: true},
      async: {type: Boolean, default: true},
      defer: {type: Boolean, default: false},
      type: {type: String, default: 'text/javascript'},
      extraAttrs: {type: Object, default: () => ({})}
    },
    created () {
      let scriptTag = document.createElement('script')
      scriptTag.setAttribute('src', this.src)
      scriptTag.setAttribute('async', this.async)
      scriptTag.setAttribute('defer', this.defer)
      scriptTag.setAttribute('type', this.type)
      Object.getOwnPropertyNames(this.extraAttrs).forEach(attr => {
        scriptTag.setAttribute(attr, this.extraAttrs[attr])
      })

      this.$nextTick(() => {
        this.$el.appendChild(scriptTag)
      })
    }
  }
</script>
