<template>
  <div role="region" aria-label="Skip to content">
    <a class="skip-to-content" href="#main-content">{{ this.$pgettext('Skip to content', 'Passa a contenuti principali') }}</a>
  </div>
</template>

<script>
  export default {
    name: 'SkipToContent'
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  .skip-to-content{
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus{
      background: #e6e6e6;
      color: #0067b8;
      position: fixed;
      top: 0;
      left: 0;
      padding: 24px;
      width: auto;
      height: auto;
      overflow: auto;
      right: 0;
      text-decoration: underline;
      text-align: center;
      z-index: 300001;
      outline: none;
    }
  }
</style>
