<template>
  <div class="RowColResponsiveCmp">
    <div
      v-for="rowN in rowsNumber"
      :key="`row-${rowN}-${_uid}`"
      class="row"
    >
      <div
        v-for="colN in rowN * +dataLayoutColSize <= +dataColsNumber
          ? +dataLayoutColSize
          : +dataColsNumber - (rowN - 1) * +dataLayoutColSize"
        :key="`col-${
          colN + (rowN * +dataLayoutColSize - +dataLayoutColSize)
        }-${_uid}`"
        :class="[`col-${12 / +dataLayoutColSize}`]"
      >
        <slot
          :name="`col-${colN + (rowN * +dataLayoutColSize - +dataLayoutColSize)}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowColResponsiveCmp',
  props: {
    dataColsNumber: {
      Type: String,
      default: '3',
    },
    dataLayoutColSize: {
      Type: String,
      default: '1',
    },
  },
  computed: {
    rowsNumber() {
      return Math.floor((+this.dataColsNumber - 1) / +this.dataLayoutColSize) + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables.scss";

.RowColResponsiveCmp {
  &.with-separator-between-rows {
    & > .row:not(:first-child) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        //@include responsive-properties(
        //  ('right', 'left'),
        //  $grid-gutter-width-resp,
        //  $halve: true,
        //  $fallback: $grid-gutter-width
        //);
        border-top: 1px solid $color-grey-medium-2;
        //z-index: map-get($z, base);
        z-index: 1;
      }
    }
  }
}
</style>
