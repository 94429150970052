import Vue from 'vue'
import store from '@store'

import VueDjangoCMSPatch from '@mixins/vue-django-cms-patch'
import VueExpand from '@plugins/vue-expand'
import VueClosable from '@plugins/vue-closable'
import VueNumberTransition from '@plugins/vue-number-transition'
import VueDjangoGettext from '@plugins/vue-django-gettext'
import VueScrollTo from 'vue-scrollto'
import VueBootstrapper from 'vue-bootstrapper'
import VueViewportMgr from 'vue-viewport-manager'
import VueRx from 'vue-rx'
import AsyncComputed from 'vue-async-computed'
import VueYoutube from 'vue-youtube'
import PortalVue from 'portal-vue'
import VueMoment from 'vue-moment'
import moment from 'moment'
import momentDurationFormat from 'moment-duration-format'
import VueMatchHeights from 'vue-match-heights'
import VueLineClamp from 'vue-line-clamp'
import { mapActions } from 'vuex'

momentDurationFormat(moment)

import { components } from '@helpers/load-components'

Vue.use(VueExpand)
Vue.use(VueClosable)
Vue.use(VueNumberTransition)
// Vue.use(VueTruncate)
Vue.use(VueScrollTo, { offset: -64 })
Vue.use(VueRx)
Vue.use(AsyncComputed)
Vue.use(VueYoutube)
Vue.use(PortalVue)
Vue.use(VueBootstrapper, { store })
Vue.use(VueViewportMgr, {
  store,
  breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1332 }
})
Vue.use(VueMoment, { moment })
Vue.use(VueDjangoGettext, { store })
Vue.use(VueMatchHeights)
Vue.use(VueLineClamp, {
  importCss: true
})

export default new Vue({
  el: '#app-root',
  mixins: [VueDjangoCMSPatch],
  store,
  components,
  methods: {
    ...mapActions(['$setAppReady'])
  },
  async mounted () {
    await this.$setAppReady()
    await this.$getCatalog(this.$context['api']['json-catalog'])
  }
})