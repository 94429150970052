<template>
  <div :class="['SideBySide', {'off-grid-right': offGridRight}]">
    <div class="off-grid-row">
      <portal
        class="off-grid"
        :disabled="portalDisabled"
        :to="`target-${_uid}`"
        :style="offgridStyle"
      >
        <slot name="off-grid"></slot>
      </portal>

      <div class="content pt-4 pt-lg-0" :style="contentStyle">
        <slot name="content"></slot>
      </div>

      <portal-target
        class="off-grid"
        :name="`target-${_uid}`"
        v-if="offGridRight"
        :style="offgridStyle"
      ></portal-target>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SideBySide',
    props: {
      offGridRight: {type: Boolean, default: false},
      flexGrow: { type: Number, default: 0 },
      flexShrink: { type: Number, default: 0 },
      flexBasis: { type: Number, default: 57 }
    },
    data(){
      return {
        portalDisabled: null
      }
    },
    mounted(){
      this.$nextTick(() => {
        this.portalDisabled = this.isPortalDisabled()
      })
    },
    watch: {
      $viewportWidth (width) {
        this.portalDisabled = this.isPortalDisabled()
      }
    },
    methods: {
      isPortalDisabled(){
        return this.$viewportWidth < 992 || !this.offGridRight
      }
    },
    computed: {
      offgridStyle(){
        return this.$viewportWidth >= 992 ? {
          flex: `${this.flexGrow} ${this.flexShrink} ${this.flexBasis}%`,
          maxWidth: `${this.flexBasis}%`
        } : {}
      },
      contentStyle(){
        return this.$viewportWidth >= 992 ? {
          flex: `${this.flexGrow} ${this.flexShrink} ${100 - this.flexBasis}%`,
          maxWidth: `${100 - this.flexBasis}%`
        } : {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  @import '~$scss/utility';

  $big-padding: 130px;

  .SideBySide.chained + .SideBySide.chained {
    padding-top: 0 !important;
  }

  .SideBySide {
    @include responsive-property(padding-top, $section-padding);
    @include responsive-property(padding-bottom, $section-padding);

    // Theme: double-padding-top to be used when previous block has colored bg
    &.double-padding-top {
      @include responsive-property(padding-top, $section-double-padding);
    }

    // Theme: double-padding-bottom to be used when next block has colored bg
    &.double-padding-bottom {
      @include responsive-property(padding-bottom, $section-double-padding);
    }

    // Theme: half-padding-top
    &.half-padding-top {
      @include responsive-property(padding-top, $section-half-padding);
    }

    // Theme: half-padding-bottom
    &.half-padding-bottom {
      @include responsive-property(padding-bottom, $section-half-padding);
    }

    // Theme: last-section-padding-bottom
    &.last-section-padding-bottom {
      @include responsive-property(padding-bottom, $last-section-padding);
    }

    // Theme: first-section-padding-top
    &.first-section-padding-top {
      @include responsive-property(padding-top, $last-section-padding);
    }

    // Theme: regular-padding-top
    &.regular-padding-top {
      @include responsive-property(padding-top, $section-regular-padding);
    }

    // Theme: regular-padding-bottom
    &.regular-padding-bottom {
      @include responsive-property(padding-bottom, $section-regular-padding);
    }

    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;

    @media (min-width: $bootstrap-lg) {
      padding-left: 0;
      padding-right: $grid-gutter-width;

      &.off-grid-right {
        padding-left: $grid-gutter-width;
        padding-right: 0;
      }
    }

    @media (min-width: $bootstrap-xl) {
      max-width: calc(50% + #{$bootstrap-xl/2});
      margin-left: 0;
      margin-right: auto;

      &.off-grid-right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .off-grid-row {
      @media (min-width: $bootstrap-lg) {
        display: flex;
        margin-left: 0;
        margin-right: -$grid-gutter-width / 2;

        .off-grid, .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .content {
          padding-left: $big-padding / 2;
          padding-right: $grid-gutter-width / 2;
        }
      }

      @media (min-width: $bootstrap-xl) {
        .content {
          padding-left: $big-padding;
        }
      }
    }

    &.off-grid-right .off-grid-row {
      @media (min-width: $bootstrap-lg) {
        margin-left: -$grid-gutter-width / 2;
        margin-right: 0;

        .content {
          padding-left: $grid-gutter-width / 2;
          padding-right: $big-padding / 2;
        }
      }

      @media (min-width: $bootstrap-xl) {
        .content {
          padding-right: $big-padding;
        }
      }
    }
  }
</style>
