export default {
  install(Vue) {
    let handleOutsideClick
    Vue.directive('closable', {
      bind (el, binding) {
        handleOutsideClick = (e) => {
          e.stopPropagation()
          if (!el.contains(e.target)) {
            binding.value();
          }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
      unbind () {
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
      }
    })
  }
}
