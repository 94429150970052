<script>
  import { fallback } from '@helpers/responsive-utilities'

  export default {
    name: 'SlotWrapper',
    props: {
      tag: {type: String, default: 'div'},
      wrapperTag: {type: String, default: 'div'},
      wrapperClass: {type: String | Array, default: ''},
      breakpoints: {
        type: Object, default: () => ({
          xs: 0, sm: 576, md: 768, lg: 992, xl: 1200
        })
      },
      verticalGutter: {type: Object, default: () => ({xs: null})},
    },
    computed: {
      currentStep () {
        return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
          this.$viewportWidth < this.breakpoints.md ? 'sm' :
            this.$viewportWidth < this.breakpoints.lg ? 'md' :
              this.$viewportWidth < this.breakpoints.xl ? 'lg' :
                'xl'
      },
      currentVerticalGutter () {
        return fallback(this.verticalGutter, this.currentStep)
      },
    },
    render (h) {
      if (this.$slots.default) {
        const children = this.$slots.default
          .filter($el => $el.tag)
          .map($el =>
            h(
              this.wrapperTag,
              {class: this.wrapperClass, style: {paddingTop: `${this.currentVerticalGutter}px`, paddingBottom: `${this.currentVerticalGutter}px`}},
              [$el]
            )
          )
        return h(this.tag, {style: {marginTop: `-${this.currentVerticalGutter}px`, marginBottom: `-${this.currentVerticalGutter}px`}}, children)
      } else {
        return h(this.tag, {})
      }
    }
  }
</script>
