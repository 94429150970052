<template>
<div class="Carousel">
  <div class="swiper-navigation" v-if="options.navigation && !disableIfMinSlides">
    <a href="#" :aria-label="$pgettext('generic carousel', 'previous')" :class="['swiper-button-prev', {'d-none': disabled}]" ref="prev"></a>
    <a href="#" :aria-label="$pgettext('generic carousel', 'next')" :class="['swiper-button-next', {'d-none': disabled}]" ref="next"></a>
  </div>
  <div :class="{'swiper-container': !disabled, 'disabled': disabled}" ref="swiper-root">
    <div :class="[classes, {'swiper-wrapper': !disabled, 'row': disabled}]" :style="currentVerticalGutter">
      <slot></slot>
    </div>
  </div>
  <div v-if="options.pagination && !disableIfMinSlides" :class="['swiper-pagination', {'d-none': disabled}, paginationClasses]" ref="pagination"></div>
</div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper';
import { fallback } from '@helpers/responsive-utilities';

export default {
  name: 'Carousel',
  data() {
    return {
      default: {
        spaceBetween: 24,
        slidesPerColumn: 1,
        breakpointsInverse: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
      },
      swiper: null,
      disabled: false,
      multirow: false,
      disableIfMinSlides: false
    };
  },
  props: {
    options: { type: Object, required: true },
    disableFrom: { type: Number },
    disabledRowClasses: { type: String },
    disabledColClasses: { type: String },
    disabledColVerticalGutter: { type: Object, default: () => ({ xs: null }) },
    multiRow: { type: Object },
    paginationClasses: { type: String },
    breakpoints: {
      type: Object,
      default: () => ({
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
      })
    },
  },
  methods: {
    initSlider() {
      if (this.swiper) {
        this.destroySlider();
      }

      const $swiperEl = this.$refs['swiper-root'];
      const $prev = this.$refs['prev'];
      const $next = this.$refs['next'];
      const $pagination = this.$refs['pagination'];

      const options = Object.assign(
        this.default,
        this.options, {
          navigation: this.options.navigation ? { nextEl: $next, prevEl: $prev } : {}
        }, {
          pagination: this.options.pagination ? {
            el: $pagination,
            clickable: true,
            renderBullet: function (index, className) {
              return `<span class="${className}"></span>`;
            }
          } : {}
        }
      );

      if (this.multiRow) {
        if (this.multiRow.disableFrom) {
          if (this.$viewportWidth < this.multiRow.disableFrom) {
            options.slidesPerColumn = this.multiRow.count;
          } else {
            options.slidesPerColumn = 1;
          }
        } else {
          options.slidesPerColumn = this.multiRow.count;
        }
      }

      if (this.disableFrom) {
        if (this.$viewportWidth < this.disableFrom) {
          options.allowTouchMove = true;
          this.disabled = false;
        } else {
          options.allowTouchMove = false;
          this.disabled = true;
          this.swiper = false;
          return;
        }
      }

      this.$nextTick(() => {
        this.swiper = new Swiper($swiperEl, options);
      });
    },
    destroySlider() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = false;
      }
    }
  },
  computed: {
    classes () {
      return this.disabled ? this.disabledRowClasses : null;
    },
    currentStep() {
      return this.$viewportWidth < this.breakpoints.sm ? 'xs' :
        this.$viewportWidth < this.breakpoints.md ? 'sm' :
        this.$viewportWidth < this.breakpoints.lg ? 'md' :
        this.$viewportWidth < this.breakpoints.xl ? 'lg' :
        'xl';
    },
    currentVerticalGutter() {
      if (this.disabled) {
        const gutter = fallback(this.disabledColVerticalGutter, this.currentStep);
        return { marginTop: `-${gutter}px`, marginBottom: `-${gutter}px` };
      } else {
        return null;
      }
    },
    minSlides() {
      return this.options.loop ? 3 : 1;
    }
  },
  watch: {
    $viewportWidth(width, prev) {
      if (this.swiper === null) {
        return;
      }
      let reinit = false;

      if (!reinit && this.multiRow && this.multiRow.disableFrom) {
        reinit = (width < this.multiRow.disableFrom && prev >= this.multiRow.disableFrom) ||
          (width >= this.multiRow.disableFrom && prev < this.multiRow.disableFrom);
      }

      if (!reinit && this.disableFrom) {
        reinit = (width < this.disableFrom && prev >= this.disableFrom) ||
          (width >= this.disableFrom && prev < this.disableFrom);
      }

      if (reinit) {
        this.initSlider();
      }
    },
    swiper(swiper) {
      if (swiper) {
        if (swiper.slides.length === this.minSlides) {
          this.disableIfMinSlides = true;
        } else {
          this.disableIfMinSlides = false;
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.initSlider());
  },
  beforeDestroy() {
    this.destroySlider();
  }
};
</script>

<style lang="scss" scoped>
@import "~$scss/variables";
@import "~$scss/utility";

$nav-distances: ($bootstrap-md: 14px,
  1440px: -66px);

$nav-distances-coverflow: ($bootstrap-md: 20px);

$nav-distances-external: ($bootstrap-md: -110px);

// Theme: alternate pagination
[class*='alternate-pagination-'] {
  .swiper-navigation {
    display: none;
  }
}

@each $breakpoint,
$value in $grid-breakpoints {
  .alternate-pagination-#{$breakpoint} {
    @media (min-width: $value) {
      .swiper-navigation {
        display: block;
      }

      .swiper-pagination {
        display: none;
      }
    }
  }
}

.alternate-pagination {
  [class*='swiper-button-'] {
    display: none;
  }
}

.swiper-container {
  position: relative;
}

// Theme: fullwidth
.fullwidth {
  .swiper-container {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;

    &.disabled {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Theme: coverflow
.Carousel.coverflow {

  // FIXME vertical alignment should respect slides, excluding pagination
  // FIXME horizontal positioning should be with respect to the focused slide
  .swiper-button-next {
    @include responsive-property(right, $nav-distances-coverflow);
  }

  .swiper-button-prev {
    @include responsive-property(left, $nav-distances-coverflow);
  }
}

// Theme: external navigation
.Carousel.side-navigation {
  .swiper-button-next {
    @include responsive-property(right, $nav-distances-external);
  }

  .swiper-button-prev {
    @include responsive-property(left, $nav-distances-external);
  }
}

.overflow-visible .swiper-container {
  overflow: visible;
}

@media (min-width: $bootstrap-lg) {
  .overflow-hidden-lg .swiper-container {
    overflow: hidden;
  }
}

.with-border .swiper-container {
  &:after {
    content: ' ';
    display: block;
    height: 1px;
    background: $color-grey-medium-2;
  }
}

.Carousel {
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f5f5f5;

    @media (min-width: $bootstrap-md) {
      display: flex;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      transform: translateX(-25%) rotate(45deg);
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: $color-black;
    }
  }

  .swiper-button-next {
    @include responsive-property(right, $nav-distances);
  }

  .swiper-button-prev {
    @include responsive-property(left, $nav-distances);

    &:before {
      transform: translateX(25%) rotate(-135deg);
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  &.navigationEl-opaque {

    .swiper-button-next,
    .swiper-button-prev {
      background-color: rgba(#f5f5f5, 0.5);
    }
  }
}

.swiper-pagination {
  padding-top: 24px;
}

// Theme: distant-pagination
.distant-pagination {
  padding-top: 40px;
}

.Carousel {
  &.override-base-carousel {
    .swiper-pagination {
      padding-top: 10px;

      @media(min-width:$bootstrap-md){
         padding-top: 24px;
      }
    }

    .swiper-navigation{
      .swiper-button-prev, .swiper-button-next{
        top: 35%;
        transform: translateY(-35%);
      }
    }
  }

  &.stories-carousel{
    .swiper-pagination {
      padding-top: 30px;
    }

    .swiper-navigation{
      .swiper-button-prev, .swiper-button-next{
        top: 35%;
        transform: translateY(-35%);
      }
    }
  }
}

// Theme: press-room-carousel
.Carousel{
  &.press-room-carousel{

    .swiper-slide {
      height: auto !important;
    }

    .swiper-pagination {
      padding: 40px 0;
    }
  }

  &.reports{
    .swiper-pagination {
      padding-top:59px;
      padding-bottom:40px;
    }
  }
}
</style>

<style lang="scss">
@import "~$scss/variables";

.swiper-pagination {
  text-align: center;

  .swiper-pagination-bullet {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;
    margin-right: 15px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 1px;
      background-color: fade-out($color-dark, 0.75);
      transition: background-color 0.3s ease-out;
    }

    &.swiper-pagination-bullet-active:after {
      background-color: $color-blue;
    }
  }
}

// White pagination
.white-pagination {
  .swiper-pagination {
    .swiper-pagination-bullet {
      &:after {
        background-color: fade-out($color-white, 0.5);
      }

      &.swiper-pagination-bullet-active:after {
        background-color: $color-white;
      }
    }
  }
}
</style>
