<template>
    <div class="PeopleInfo">
      <slot name="name"></slot>
      <slot name="role"></slot>
      <slot name="text"></slot>
      <div v-if="href || download" class="row mt-4">
        <div v-if="href" class="col-auto">
          <a :href="href" class="link"><slot name="cta"></slot></a>
        </div>
        <div v-if="download" class="col-auto">
          <icon-link :href="download" target="_blank" icon="arrow-to-bottom" class="link left grey"><slot name="download-cta"></slot></icon-link>
        </div>
      </div>
    </div>
</template>

<script>
  import Icon from '@components/atoms/icon'
  import IconLink from '@components/atoms/icon-link'

  export default {
    name: 'PeopleInfo',
    components: {Icon, IconLink},
    props: {
      href: {
        type: String,
      },
      download: {
        type: String,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .link {
    color: $color-grey;
    transition: color .3s ease;
    display: inline-block;

    &:hover {
      color: $color-blue;
    }

    &.with-icon {
      padding-left: 18px;
      position: relative;

      .icon {
        position: absolute;
        left: 0;
        top: 50%;
        font-size: 18px;
        margin-top: -9px;
      }
    }
  }

  .PeopleInfo {
    // White theme
    &.white {
      .name, .role, .text, .link {
        color: $color-white;
      }

      .link:hover {
        color: $color-grey-medium;
      }
    }
  }
</style>