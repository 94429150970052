<template>
  <div
    class="FlexCol"
    :style="{
      width: '100%',
      maxWidth: `${percentageSize}%`,
      flex: `0 0 ${percentageSize}%`,
      marginLeft: percentageOffset ? `${percentageOffset}%` : null,
      paddingRight: `${gutter/2}px`,
      paddingLeft: `${gutter/2}px`,
      paddingTop: `${verticalGutter/2}px`,
      paddingBottom: `${verticalGutter/2}px`
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
  import { fallback } from '@helpers/responsive-utilities'

  export default {
    name: 'FlexCol',
    props: {
      size: {type: Object | null, default: () => ({xs: 1})},
      offset: {type: Object | null, default: () => ({xs: 0})}
    },
    computed: {
      currentStep () {
        return this.$parent.currentStep
      },
      currentSize () {
        return fallback(this.size, this.currentStep)
      },
      currentOffset () {
        return fallback(this.offset, this.currentStep)
      },
      gridSize () {
        return this.$parent.currentColumns
      },
      gutter () {
        return this.$parent.currentGutter
      },
      verticalGutter () {
        return this.$parent.currentVerticalGutter
      },
      percentageSize () {
        return this.currentSize ? this.currentSize / this.gridSize * 100 : 100
      },
      percentageOffset () {
        return this.currentOffset ? this.currentOffset / this.gridSize * 100 : null
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
