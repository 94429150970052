var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FlexGrid",style:({
    marginLeft: ((-_vm.currentGutter/2) + "px"),
    marginRight: ((-_vm.currentGutter/2) + "px"),
    marginTop: ((-_vm.currentVerticalGutter/2) + "px"),
    marginBottom: ((-_vm.currentVerticalGutter/2) + "px"),
    justifyContent: ("" + _vm.currentJustifyContent),
    alignItems: ("" + _vm.currentAlignItems)
  })},[(!_vm.autoWrap)?[_vm._t("default")]:_c('slot-wrapper',{attrs:{"wrapper-tag":"flex-col"}},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }