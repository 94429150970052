<template>
  <component :is="clickable ? 'a' : 'span'" :href="href" :target="target" class="IconLink">
    <span :class="[{animated: isAnimated}]">
      <icon :name="icon" v-if="isAnimated" class="animated-icon-left"></icon>
      <span class='wrapper'>
        <icon :name="icon" :class="[{'animated-icon-right': isAnimated}]"></icon>
        <span class='text'>
          <slot></slot>
        </span>
      </span>
    </span>
  </component>
</template>

<script>
  import Icon from '@components/atoms/icon';

  export default {
    name: 'IconLink',
    components: {Icon},
    props: {
      clickable: {type: Boolean, default: true},
      href: {
        type: String,
        default: '#',
      },
      target: {
        type: String,
      },
      icon: {
        type: String,
        default: 'angle-right',
      }
    },
    computed: {
      isAnimated() {
        return this.icon === 'arrow-right';
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~$scss/_variables.scss";
  @import "~$scss/_utility.scss";

  .IconLink {
    position: relative;
    display: inline-flex;
    align-self: flex-start;
    cursor: pointer;
    color: $color-blue-lochmara;
    transition: color .3s ease;
    font-size: 18px;
    text-underline-position: under;

    .wrapper {
      display: flex;
      align-items: center;
      position: relative;
    }

    .icon {
      font-size: 0.8em;
      margin-right: 10px;
    }

    small {
      color: $color-grey;
    }

    @media (min-width: $bootstrap-md) {
      font-size: 20px;
    }

    &:hover {
      color: lighten($color-blue-lochmara, 15%);
    }

    &.grey-icon {
      .icon {
        color: $color-grey
      }
    }

    &.underlined {
      text-decoration: underline;
    }

    &.black-hover {
      &:hover,
      &.active {
        color: #000;
      }
    }

    &.icon-black-hover {
      &:hover,
      &.active {
        .icon {
          color: #000;
        }
      }
    }

    // Theme isAnimated
    .animated {
      position: relative;
      transition: all 0.2s;

      .icon {
        width: 1em;
        overflow: hidden;
        transition: all 0.2s;
        transition-delay: 0s;
        will-change: transform;

        &.animated-icon-left {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 0;
          margin: 1px 0 0;
        }

        &.animated-icon-right {
          right: 0;
          width: 1em;
          transform: translateX(0);
          transition-delay: 0.2s;
        }
      }

      &:hover {
        > .animated-icon-left {
          width: 1em;
          transition-delay: 0.1s;
        }

        > .wrapper {
          transform: translateX(24px);

          > .animated-icon-right {
            width: 0;
            transform: translateX(-24px);
            transition-delay: 0s;
          }
        }
      }

      > .wrapper {
        transition: all 0.2s;
      }
    }

    // Theme icon left
    &.left {
      padding-left: 22px;

      .wrapper {
        position: static;
      }
    }

    // Theme icon top
    &.top {
      padding-left: 37px;

      .icon {
        font-size: 20px;
        top: 6px;
      }
    }

    // Theme color lochmara
    &.lochmara {
      color: $color-blue-lochmara;

      &:hover {
        color: $color-blue;
      }
    }

    // Theme color Blue
    &.blue {
      color: $color-blue-2;

      &:hover {
        color: $color-blue-lochmara;
      }
    }

    // Theme color grey
    &.grey {
      color: $color-grey;

      &:hover {
        color: $color-blue;
      }
    }

    // Theme color black
    &.black {
      color: $color-black;

      &:hover {
        color: $color-grey;
      }
    }

    // Theme color dark
    &.dark {
      color: $color-dark;

      &:hover {
        color: $color-grey;
      }
    }

    // Theme color white
    &.white {
      color: $color-white;

      &:hover {
        color: $color-grey-medium-2;
      }
    }

    // Theme orange
    &.orange {
      color: $color-orange;

      &:hover {
        color: lighten($color-orange, 10%);
      }
    }

    // Theme big
    &.big {
      font-size: 20px;

      &.left {
        padding-left: 24px;
      }
    }

    // Theme large
    &.large {
      font-size: 20px;

      .icon {
        font-size: 1em;
      }

      &.left {
        padding-left: 32px;
      }

      @media (max-width: $bootstrap-sm) {
        &.left {
          padding-left: 22px;
        }
      }
    }

    // Theme serif
    &.serif {
      @include fontCaecilia();
      font-weight: $normal;
    }
  }
</style>
