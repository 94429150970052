<template>
  <div class="Faq">
    <tabs
      ref="tabs"
      :tabs="categoriesOrFixture"
      :initial-tab="getInitialTab"
      :data-language="dataLanguage"
    >
      <template
        v-for="item in categoriesOrFixture"
        :slot="slotName(item.slug)"
      >
        <accordion-link
          :class="['large', 'left' , 'black-hover', 'icon-black-hover']"
          href="#"
          :icon="item.icon"
          :data-slug="item.slug"
          :ref="`category-${item.id}`"
          :id="`category-${item.id}`"
          @click.native="onCategoryClick"
        >
          {{ item.name }}
        </accordion-link>
      </template>

      <template
        v-for="category in categoriesOrFixture"
        :slot="slotActivePanelName(category.slug)"
      >
        <div :key="'category-'+category.id">
          <template
            v-for="(subcategory, subcategoryIndex) in subcategoriesOrFixture.filter(q=>category.slug === q.category)"
          >
            <div :key="'subcategory-'+subcategory.id" :class="{'mb-4': true, 'mt-7': subcategoryIndex > 0}">
              <h4 class="title title-40 pp-pb-60 pp-pb-md-80" v-html="subcategory.name"></h4>
              <accordion
                :id="`accordion-${subcategory.id}`"
                :ref="`accordion-${subcategory.id}`"
                class="questions"
              >
                <accordion-item
                  :index="question.id"
                  :key="'question-'+question.id"
                  :slug="question.slug"
                  v-on:accordion-toggled="onAccordionToggled"
                  v-for="question in faqOrFixture.filter(q=>subcategory.slug === q.subcategory)"
                >
                  <template slot="header">
                    <h4 class="title-24 text-color-blue serif" v-html="question.title"></h4>
                  </template>
                  <template slot="content">
                    <div class="questionBody" v-html="question.content"></div>
                  </template>
                </accordion-item>
              </accordion>
            </div>
          </template>
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
import Tabs from '@components/tabs/tabs';
import AccordionLink from '@components/atoms/accordion-link';
import Accordion from '@components/accordion/accordion';
import AccordionItem from '@components/accordion/accordion-item';
import { TabsWrapperMixins } from '@mixins/tabs-wrapper-mixins';

export default {
  name: 'Faq',
  components: {
    Tabs,
    AccordionLink,
    Accordion,
    AccordionItem
  },
  mixins: [
    TabsWrapperMixins
  ],
  props: {
    categories: {type: Array},
    subcategories: {type: Array},
    faq: {type: Array},
    initial: {type: String},
    dataLanguage: {
      type: String,
      required: true
    }
  },
  asyncComputed: {
    async categoriesOrFixture() {
      return this.categories ? this.categories :
        (await import('@fixtures/faq-fixture')).categoriesFixture;
    },
    async subcategoriesOrFixture() {
      return this.subcategories ? this.subcategories :
        (await import('@fixtures/faq-fixture')).subcategoriesFixture;
    },
    async faqOrFixture() {
      return this.faq ? this.faq :
        (await import('@fixtures/faq-fixture')).questionsFixture;
    }
  },
  methods: {
    replaceUrl(e) {
      let url = new URL(window.location.href),
        obj = url.searchParams;
      if (e.event === 'open') {
        obj.set('slug', e.slug);
      } else if (e.event === 'close') {
        obj.delete('slug');
      }
      window.history.replaceState({}, document.title, url.toString());
    },
    onAccordionToggled(e) {
      this.replaceUrl(e);
    },
    onCategoryClick(e) {
      e.preventDefault();
      let url = new URL(window.location.href),
        obj = url.searchParams;
      obj.delete('slug');
      window.history.replaceState({}, document.title, url.toString());
    }
  },
  computed: {
    getInitialTab() {
      return this.initial ? this.initial : 'people';
    }
  },
  mounted() {
    let url = new URL(window.location.href),
      obj = url.searchParams,
      objectList,
      categories,
      clbk = () => {
        let slot = this.slotActivePanelName(this.getInitialTab);
        let slug = obj.get('slug');
        if (!!slug) {
          setTimeout(() => {
            let activeFaqItem = objectList.find(a => a.slug === slug),
              activeSubCategory = this.subcategoriesOrFixture.find(a => a.slug === activeFaqItem.subcategory);
            let activeTab = activeFaqItem.subcategory;
            let activeCategory = this.categoriesOrFixture.find(a => a.slug === activeSubCategory.category);
            this.$refs.tabs.activeTab = activeTab;

            this.$el.querySelector(`#category-${activeCategory.id}`).click();

            setTimeout(() => {
              this.$refs[`accordion-${activeSubCategory.id}`][0].current = activeFaqItem.id;
              this.$scrollTo(this.$refs[`accordion-${activeSubCategory.id}`][0].$el.parentNode, 300);
              this.replaceUrl({
                event: 'open',
                slug: slug
              });
            });
          });
          /*
          setTimeout(() => {
            let id = parseInt(obj.get('id'));
            // Workaround to get a ref for a slotted (with dynamic slots) element
            this.$refs.tabs.activeTab = objectList.find(a => a.id === id).subcategory;
            this.$refs.accordion[0].current = id;
          });
          */
        }
      };

    if (this.faq) {
      objectList = this.faq;
    } else {
      import(modulePath).then((obj) => {
        objectList = obj.questionsFixture;
        clbk();
      }).catch((err) => {
        console.log(err);
      });
    }

    clbk();
  }
};
</script>

<style lang="scss" scoped>
.Faq {
  /deep/ .Accordion {
    .AccordionItem:first-child {
      .header {
        padding-top: 0;
      }
    }
  }
}
</style>