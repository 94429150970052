<template>
  <div :class="['SearchBar', focused ? 'focusIn' : 'focusOut']">
    <label :id="`search-label-${_uid}`" class="sr-only">Search</label>
    <input
      ref="input"
      class="input"
      type="text"
      :aria-labelledby="`search-label-${_uid}`"
      :placeholder="placeholder ? placeholder : $gettext('Search')"
      @input="$emit('input', $event.target.value)"
      @focus="onSearchBarFocusIn"
      @blur="onSearchBarFocusOut"
    >
    <icon class="search-icon" name="search" @click.native="onIconClick"></icon>
  </div>
</template>

<script>
  import Icon from '@components/atoms/icon';

  export default {
    name: 'SearchBar',
    components: {Icon},
    props: {
      placeholder: {type: String, default: null},
      value: {type: String}
    },
    data() {
      return {
        focused: false
      };
    },
    methods: {
      onIconClick () {
        this.$refs['input'].focus();
      },
      clear () {
        this.$refs['input'].value = '';
        this.$emit('input', '');
      },
      onSearchBarFocusIn() {
        this.focused = true;
      },
      onSearchBarFocusOut() {
        this.focused = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .SearchBar {
    display: flex;
    align-items: center;
    position: relative;
    padding: 14px 54px 14px 30px;
    font-size: 24px;
    transition: all 0.2s;
    border-width: 1px;
    border-style: solid;

    &.focusOut {
      box-shadow: 0 0 10px fade-out($color-dark, 0.9);
      border-color: transparent;
    }

    &.focusIn {
      box-shadow: none;
      border-color: rgba($color-dark, 0.25);
    }

    .input {
      width: 100%;
      font-size: 24px;
      line-height: 1.5em;
      border: 0;
      box-shadow: none;

      &::placeholder {
        color: $color-dark;
        opacity: 0.5;
      }
    }

    .search-icon {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.focusOut {
      .search-icon {
        color: fade-out($color-dark, 0.5);
      }
    }

    &.focusIn {
      .search-icon {
        color: fade-out($color-dark, 0);
      }
    }
  }
</style>
