<template>
  <footer class="BaseFooter">
    <nav class="top" :aria-label="$pgettext('base footer nav', 'Site map')">
      <div class="container">
        <ul class="row">
          <li
            class="main-menu-li col-md-4 col-lg-2 pt-3 pb-3 pt-md-4 pb-md-4"
            v-for="(item, index) in menuOrFixture"
            :class="{opened: isMobile && opened === index}"
          >
            <h4 v-if="!isMobile" class="menu-title">
              <a v-if="item.link" :href="item.link" :target="item.external ? '_blank' : false" class="menu-title-link">{{item.label}}</a>
              <template v-else>{{item.label}}</template>
            </h4>
            <h4 v-else class="menu-title-mobile">
              <a
                href="#"
                @click.prevent="opened = (opened === index) ? false : index"
                class="menu-title-link"
                role="button"
                :aria-label="item.label"
                :aria-controls="`footer-menu-${index}`"
                :aria-expanded="(isMobile && opened === index) || !isMobile ? 'true' : 'false'"
              >
                {{item.label}}
                <icon name="angle-down"></icon>
              </a>
            </h4>
            <ul
              :id="`footer-menu-${index}`"
              v-if="item.children"
              v-expand="(isMobile && opened === index) || !isMobile"
              class="menu"
            >
              <li v-if="item.link && isMobile" class="menu-item">
                <a :href="item.link" :target="item.external ? '_blank' : false" class="menu-item-link">
                  <span v-if="item.external" class="with-icon">{{item.label}}<icon name="arrow-top-right"></icon></span>
                  <template v-else>{{item.label}}</template>
                </a>
              </li>
              <li v-for="subitem in item.children" class="menu-item">
                <a
                  v-if="subitem.link"
                  :href="subitem.link"
                  :target="subitem.external ? '_blank' : false"
                  class="menu-item-link"
                >
                  <span v-if="subitem.external" class="with-icon">
                    {{subitem.label}}<icon name="arrow-top-right"></icon>
                  </span>
                  <template v-else>{{subitem.label}}</template>
                </a>
                <template v-else>{{subitem.label}}</template>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <div class="bottom" aria-label="Company info">
      <div class="container">
        <p class="bottom-text">
          <slot name="text"></slot>
        </p>
        <div class="row align-items-center">
          <div class="col-auto pt-2 pb-2">
            <h4 class="logo"><span class="hidden">Barilla</span></h4>
          </div>
          <portal class="d-none d-md-flex col-md pt-2 pb-2 align-items-md-center justify-content-md-start" :disabled="!isMobile" to="copyright">
            <p class="copyright-text">
              <slot name="copyright"></slot>
            </p>
          </portal>
          <div class="col col-md-3 col-lg-2 pt-2 pb-2">
            <socials :list="socials" ul-classes="d-flex align-items-center justify-content-end" position="footer"></socials>
          </div>
          <portal-target class="col-12 pt-3 d-md-none" name="copyright"></portal-target>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import Languages from '@components/languages'
  import Icon from '@components/atoms/icon'
  import Socials from "@components/socials"

  export default {
    name: 'BaseFooter',
    components: {Socials, Icon, Languages},
    data () {
      return {
        opened: false,
      }
    },
    props: {
      menu: {type: Array},
      socials: {type: Array},
      languages: {type: Array},
    },
    asyncComputed: {
      async menuOrFixture () {
        return this.menu ? this.menu :
          (await import('@fixtures/footer-fixture')).footerMenuFixture
      },
    },
    computed: {
      isMobile() {
        return this.$viewportWidth < 768
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .BaseFooter {
    color: $color-white;
  }

  .top {
    background: fade-out($color-dark, 0.05);
    padding: 20px 0 40px;

    @media (min-width: $bootstrap-md) {
      padding: 80px 0;
    }
  }

  .bottom {
    background: $color-dark;
    padding: 40px 0 60px;

    @media (min-width: $bootstrap-md) {
      padding: 40px 0;
    }
  }

  .menu-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $extra-bold;
    padding-bottom: 10px;

    .menu-title-link {
      display: block;
      color: $color-white;
      transition: color .3s ease;

      &:hover {
        color: darken($color-grey-light, 10%);
      }
    }

    @media (min-width: $bootstrap-lg) {
      padding-bottom: 20px;
    }
  }

  .menu-title-mobile {
    .menu-title-link {
      display: block;
      font-size: 14px;
      font-weight: $extra-bold;
      text-transform: uppercase;
      padding-right: 30px;
      position: relative;
      transition: color .3s ease;
      color: $color-white;

      &:hover {
        color: darken($color-grey-light, 10%);
      }

      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
        font-size: 24px;
        color: $color-white;
        transition: transform .3s ease;
      }
    }
  }

  .menu {
    line-height: 1.4;

    .menu-item {
      padding: 7px 0;

      @media (max-width: $bootstrap-md - 1) {
        &:first-child {
          padding-top: 20px;
        }
      }

      .menu-item-link {
        display: block;
        font-size: 16px;
        font-weight: $light;
        transition: color .3s ease;
        color: $color-white;

        &:hover {
          color: darken($color-grey-light, 10%);
        }

        .with-icon {
          display: inline-block;
          max-width: 100%;
          padding-right: 31px;
          position: relative;

          .icon {
            font-size: 16px;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -8px;
          }
        }
      }
    }

    @media (min-width: $bootstrap-md) {
      .menu-item {
        padding: 9px 0;
      }
    }

    @media (min-width: $bootstrap-lg) {
      .menu-item {
        padding: 12px 0;
      }
    }
  }

  @media (max-width: $bootstrap-md - 1) {
    .main-menu-li {
      position: relative;

      &:after {
        content: ' ';
        display: block;
        height: 1px;
        background: rgba($color-white, .5);
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 0;
      }
    }
  }

  .opened {
    .menu-title-mobile {
      .menu-title-link {
        color: $color-white;

        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .bottom-text {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid fade-out($color-white, 0.5);
    font-size: 14px;
    color: rgba($color-white, 0.75);

    @media (min-width: $bootstrap-lg) {
      margin-bottom: 0;
      padding-bottom: 30px;
      border: none;
    }
  }

  .logo {
    width: 97px;
    height: 35px;
    background: url(~@images/barilla.svg) no-repeat 50% 50%;
    background-size: 100% 100%;
  }

  .copyright-text {
    font-size: 14px;
  }
</style>
