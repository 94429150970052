<template>
  <div className="Accordion">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data() {
    return {
      current: false
    };
  },
  props: {
    open: {
      type: Number | Boolean,
      default: false
    }
  },
  methods: {
    toggleOpen(idx) {
      this.current = this.current === idx ?
        false : idx;
    }
  },
  mounted() {
    this.$on('accordion-toggled', (idx) => this.toggleOpen(idx));
    this.$nextTick(() => this.toggleOpen(this.open));
  }
};
</script>