<template>
  <transition name="fade">
    <div v-if="loading && !isDev" class="Preloader">
      <div class="wrapper d-flex justify-content-center align-items-center">
        <radial-progress-bar v-if="start"
                             class="loader"
                             :diameter="diameter"
                             :completed-steps="value"
                             :total-steps="total"
                             inner-stroke-color="rgba(255,255,255,.25)"
                             start-color="#FFFFFF"
                             stop-color="#FFFFFF"
                             :stroke-width="2"
                             timing-func="ease-out"
                             :animate-speed="500"
        >
        </radial-progress-bar>
      </div>
    </div>
  </transition>
</template>

<script>
  import RadialProgressBar from 'vue-radial-progress'
  import imagesLoaded from 'imagesloaded'
  import { mapState } from 'vuex'
  import { EventBus } from '@helpers/event-bus'

  export default {
    name: 'Preloader',
    components: { RadialProgressBar },
    data () {
      return {
        start: false,
        total: 100,
        value: 0,
        loading: true,
      }
    },
    computed: {
      ...mapState(['appReady']),
      isMobile () {
        return this.$viewportWidth < 992
      },
      diameter () {
        return this.isMobile ? 120 : 252
      },
      isDev () {
        return process.env.NODE_ENV === 'development'
      },
    },
    watch: {
      async appReady (status) {
        if (status && !this.isDev) {
          await this.initLoader()
        }
        EventBus.$emit('preloader-dismissed')
      }
    },
    methods: {
      initLoader () {
        return new Promise(resolve => {
          const loader = imagesLoaded(this.$root.$el)
          this.total = loader.images.length
          this.start = true

          loader.on('progress', () => {
            this.value = loader.images.filter(item => item.isLoaded).length
          })

          loader.on('always', () => {
            this.value = this.total
            this.loading = false
            resolve()
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .Preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $color-blue;
    z-index: 10000000;
    overflow: hidden;
  }

  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }

  .fade-leave-active {
    transition: opacity .8s ease .5s, height .8s ease .5s;

    .loader {
      transition: transform .5s ease .5s;
    }
  }

  .fade-leave-to {
    opacity: 0;
    height: 0;

    .loader {
      transform: scale(.75);
    }
  }

</style>
