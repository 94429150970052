<template>
  <blockquote class="QuoteBox" :style="{maxWidth: maxWidth}">
    <slot name="text"></slot>
    <slot name="author"></slot>
  </blockquote>
</template>

<script>
  export default {
    name: 'QuoteBox',
    props: {
      maxWidth: {
        type: String,
        default: 'none',
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  .QuoteBox {
    quotes: '\201c' '\201d';
    padding-left: 80px;
    position: relative;

    @media (min-width: $bootstrap-md) {
      padding-left: 108px;
    }

    @media (min-width: $bootstrap-lg) {
      padding-right: 50px;
    }

    &:before {
      content: open-quote;
      @include fontCaecilia();
      font-size: 130px;
      line-height: 1;
      color: rgba($color-grey, .25);
      position: absolute;
      left: 0;
      top: -20px;

      @media (min-width: $bootstrap-md) {
        font-size: 180px;
        top: -26px;
      }
    }
  }

  .author {
    margin-top: 30px;
  }
</style>