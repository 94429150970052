var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FlexCol",style:({
    width: '100%',
    maxWidth: (_vm.percentageSize + "%"),
    flex: ("0 0 " + _vm.percentageSize + "%"),
    marginLeft: _vm.percentageOffset ? (_vm.percentageOffset + "%") : null,
    paddingRight: ((_vm.gutter/2) + "px"),
    paddingLeft: ((_vm.gutter/2) + "px"),
    paddingTop: ((_vm.verticalGutter/2) + "px"),
    paddingBottom: ((_vm.verticalGutter/2) + "px")
  })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }