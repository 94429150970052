<template>
  <article class="NewsArticle" aria-label="News article">
    <div :class="['container top-block', {'spacing-bottom': !hasAdditionalContent}]">
      <div class="row justify-content-center">
        <div class="top-col col-12 col-sm-11 col-lg-10">
          <flex-grid :columns="{xs: 12}">
            <flex-col :size="{xs: 12, sm: 10, lg: 8}" :offset="{xs: 0, sm: 1, lg: 2}">
              <div class="breadcrumbs-container" v-if="!!$slots['breadcrumbs']">
                <slot name="breadcrumbs"></slot>
              </div>
              <slot name="category"></slot>
              <slot name="title"></slot>
              <slot name="date"></slot>
              <separator class="heading-separator"></separator>
            </flex-col>
          </flex-grid>
          <flex-grid :columns="{xs: 12}">
            <flex-col
              class="share-side"
              :size="{xs: 12, sm: 10, lg: 8}"
              :offset="{xs: 0, sm: 1, lg: 2}"
            >
              <share-bar :share-options="shareOptions"></share-bar>
            </flex-col>
            <flex-col
              :size="{xs: 12, sm: 10, lg: 8}"
              :offset="{xs: 0, sm: 1, lg: 2}"
            >
              <div :class="wysiwygClasses">
                <slot name="first-block"></slot>
              </div>
            </flex-col>
          </flex-grid>
        </div>
      </div>
    </div>
    <carousel
      v-if="!!$slots['gallery-slides']"
      class="news-gallery coverflow"
      :options="galleryOptions"
    >
      <slot name="gallery-slides"></slot>
    </carousel>
    <div class="container" v-if="!!$slots['second-block'] || hasAdditionalContent">
      <div class="row justify-content-center">
        <div class="bottom-block col-12 col-sm-11 col-lg-10">
          <flex-grid :columns="{xs: 12}">
            <flex-col :size="{xs: 12, sm: 10, lg: 8}" :offset="{xs: 0, sm: 1, lg: 2}">
              <div v-if="!!$slots['second-block']" :class="['wysiwyg', typography === 'sans-serif' ? 'sans-serif' : '']">
                <slot name="second-block"></slot>
              </div>
              <div v-if="hasAdditionalContent" class="additional-content">
                <slot name="additional-content"></slot>
              </div>
            </flex-col>
          </flex-grid>
        </div>
      </div>
    </div>

    <container class="double-padding-bottom pt-5" v-if="!!$slots['last-block']">
      <div class="row">
        <div class="col-10 col-sm-8 offset-sm-1 col-lg-6 offset-lg-2">
          <slot name="last-block"></slot>
        </div>
      </div>
    </container>

  </article>
</template>

<script>
  import Separator from '@components/layout/separator'
  import Carousel from '@components/carousel/carousel'
  import CarouselItem from '@components/carousel/carousel-item'
  import SlotWrapper from '@components/layout/slot-wrapper'
  import ShareBar from '@components/share-bar'
  import Container from '@components/layout/container'
  import FlexGrid from '@components/layout/flex-grid'
  import FlexCol from '@components/layout/flex-col'

  export default {
    name: 'NewsArticle',
    components: {Separator, Carousel, CarouselItem, SlotWrapper, ShareBar, Container, FlexGrid, FlexCol},
    props: {
      typography: {type: String, default: ''},
      shareOptions: {type: Object, default: () => ({})},
      extraWysiwygClasses: {type: String, default: ''}
    },
    computed: {
      wysiwygClasses () {
        return [
          'wysiwyg',
          this.typography === 'sans-serif' ? 'sans-serif' : '',
          this.extraWysiwygClasses
        ]
      },
      hasAdditionalContent() {
        return !!(this.$slots['additional-content'] || [])[0];
      }
    },
    data () {
      return {
        galleryOptions: {
          effect: 'coverflow',
          slidesPerView: 1.3,
          centeredSlides: true,
          loop: true,
          navigation: true,
          pagination: true,
          autoHeight: true,
          coverflowEffect: {
            rotate: 0,
            stretch: -24,
            depth: 100,
            modifier: 1,
          },
          breakpoints: {
            576: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            }
          }
        },
      }
    },
    mounted () {
      this.$el.querySelectorAll('.news-slide-image').forEach(image => {
        const position = image.attributes.getNamedItem('data-object-position')
        if (position && position.value) {
          image.style.objectPosition = position.value
        }
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';
  @import '~$scss/utility';

  .NewsArticle {
    @include responsive-property(padding-bottom, $section-double-padding);

    &.padded {
      padding-top: 19px;

      @media (min-width: $bootstrap-md) {
        padding-top: 54px;
      }
    }

    .top-col {
      @include responsive-property(padding-top, $section-padding)
    }

    /deep/ .breadcrumbs-container {
      margin-bottom: 40px;

      @media (min-width: $bootstrap-md) {
        margin-bottom: 72px;
      }

      a, span {
        font-size: 13px;
        line-height: 22px;
        font-weight: $light;

        @media (min-width: $bootstrap-md) {
          line-height: 24px;
        }
      }
    }

    /deep/ .category {
      display: block;
      font-size: 14px;
      font-weight: $extra-bold;
      color: $color-grey;
      margin-bottom: 32px;
    }

    /deep/ .title {
      margin-bottom: 20px;

      @media (min-width: $bootstrap-md) {
        margin-bottom: 8px;
      }
    }

    /deep/ .date {
      color: fade-out($color-dark, 0.5);
      font-size: 16px;
    }

    .heading-separator {
      margin-top: 27px;
      margin-bottom: 30px;

      @media (min-width: $bootstrap-md) {
        margin-bottom: 34px;
      }
    }

    .top-block, .news-gallery {
      &.spacing-bottom {
        @include responsive-property(padding-bottom, $section-double-padding);
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .additional-content {
      margin-top: 60px;

      @media (min-width: $bootstrap-md) {
        margin-top: 80px;
      }
    }

  }

  // Theme: white
  .NewsArticle.white {
    .top-block, .top-col {
      background: $color-white;
    }

    @media (min-width: $bootstrap-md) {
      .top-block {
        background: transparent;
      }
    }
  }
</style>

<style lang="scss">
  .NewsArticle {
    .news-gallery {
      .swiper-slide {
        .news-slide {
          width: 100%;
          height: 0;
          padding-top: 100% * 9 / 16;
          opacity: .5;
          transition: opacity 0.3s ease-out;
          position: relative;

          .news-slide-image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            object-position: center center;
          }
        }

        &.swiper-slide-active .news-slide {
          opacity: 1;
        }
      }
    }
  }
</style>

<!-- Print SCSS -->
<style lang="scss">
  @media only print {
    .BaseHeader {
      display: none;
    }

    .header {
      display: none;
    }

    main {
      padding: 0;
    }

    .content-overlay {
      margin-top: 0 !important;
    }

    .share-side {
      aside {
        display: none;
      }
    }

    .Carousel {
      display: none;
    }

    .ContainerBlock {
      display: none;
    }

    .BaseFooter {
      display: none;
    }
  }
</style>
