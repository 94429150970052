<template>
  <div v-if="config" class="Perspective2030Item">
    <div class="bars">
      <span
        ref="$bar"
        :class="['bar', 'absolute', `bg-color-${colors.now}`, {showed}]"
        :style="{width: nowWidth}"
      ></span>
      <span
        :class="['bar', `bg-color-${colors.future}`, {showed}]"
        :style="{width: state === 'future' ? futureWidth : nowWidth}"
      ></span>
      <div class="numbers-wrapper">
        <p class="numbers">
          {{ state === 'now' ? nowNumbers : futureNumbers }}
        </p>
      </div>
    </div>
    <p class="text text-16 label">{{config.label}}</p>
  </div>
</template>

<script>
  import ViewportMixins from '@mixins/viewport_mixins'

  export default {
    name: 'Perspective2030Item',
    mixins: [ViewportMixins],
    props: {
      colors: { type: Object, default: () => ({ now: 'yellow-green', future: 'limeade' }) },
      config: { type: Object, required: true },
      state: {
        type: String,
        required: true,
        validator: (val) => ['now', 'future'].indexOf(val) > -1
      }
    },
    data () {
      return {
        barsTotalMaxWidth: 0.9,
        visible: false,
        showed: false,
      }
    },
    computed: {
      nowNumbers () {
        if(this.config.now_full_label) {
          return this.config.now_full_label;
        }
        const prefix = this.config.now_prefix ? this.config.now_prefix : ''
        const suffix = this.config.now_suffix ? this.config.now_suffix : ''
        return `${prefix}${this.config.now_label}${suffix} (${this.config.now_year})`
      },
      futureNumbers () {
        if(this.config.future_full_label) {
          return this.config.future_full_label;
        }
        const prefix = this.config.future_prefix ? this.config.future_prefix : ''
        const suffix = this.config.future_suffix ? this.config.future_suffix : ''
        return `${prefix}${this.config.future_label}${suffix} (${this.config.future_year})`
      },
      nowWidth () {
        if (!this.visible) {
          return '0'
        }
        if (this.config.now <= 0) {
          return '0'
        }

        return `${100 * this.barsTotalMaxWidth * this.config.now / this.config.base}%`
      },
      futureWidth () {
        if (!this.visible) {
          return '0'
        }
        if (this.config.future <= 0) {
          return '0'
        }

        return `${100 * this.barsTotalMaxWidth * this.config.future / this.config.base}%`
      }
    },
    methods: {
      isVisible () {
        if (!this.visible && this.slideIsActive() && this.isElementXPercentInViewport(this.$el, 100)) {
          this.visible = true
          window.removeEventListener('scroll', this.isVisible)
          if (this.isInSlider()) {
            this.getSwiper().off('slideChangeTransitionEnd', this.isVisible)
          }
          const afterShow = () => {
            this.showed = true
            this.$refs.$bar.removeEventListener('transitionend', afterShow)
          }
          this.$refs.$bar.addEventListener('transitionend', afterShow)
        }
      },
      isInSlider () {
        return this.$parent && this.$parent.$parent && this.$parent.$parent.$el.className.includes('swiper-slide')
      },
      slideIsActive () {
        if (!this.isInSlider()) {
          return true
        }
        return this.$parent.$parent.$el.className.includes('swiper-slide-active')
      },
      getSwiper () {
        return this.$parent.$parent.getSwiper()
      }
    },
    mounted () {
      setTimeout(() => {
        window.addEventListener('scroll', this.isVisible)
        this.isVisible()
      }, 500)
      if (this.isInSlider()) {
        this.getSwiper().on('slideChangeTransitionEnd', this.isVisible)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .bars {
    position: relative;
  }

  .bar, .numbers-wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  .bar {
    height: 30px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: width 1s ease;

    &.showed {
      transition-duration: .3s;
    }

    &.absolute {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
    }
  }

  .numbers {
    color: $color-grey;
    font-size: 16px;
    font-weight: $bold;
    margin-left: 8px;
  }

  .label {
    margin-top: 7px;
  }
</style>
