<template>
  <div class="MediaDownloader">
    <h4 class="title" v-if="!light">{{$gettext('Media')}}</h4>
    <p class="description" v-if="!light">{{mediaItem.description}}</p>
    <div class="download">
      <span class="info">
        {{extension}} ({{mediaItem.size}})
      </span>
      <icon-link
        class="cta"
        :href="mediaItem.url"
        target="_blank"
        icon="arrow-down"
      >{{ mediaItem.label ? mediaItem.label : $gettext('Download')}}</icon-link>
    </div>
  </div>
</template>

<script>
  import { capitalizeFirstLetter } from '@helpers/string-manipulation'

  import IconLink from '@components/atoms/icon-link'

  export default {
    name: 'MediaDownloader',
    components: {IconLink},
    props: {
      mediaItem: {type: Object, required: true},
      light: {type: Boolean, default: false}
    },
    computed: {
      extension () {
        return capitalizeFirstLetter(this.mediaItem.filename.split('.').pop())
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~$scss/variables';

  .title {
    margin-bottom: 15px;
    font-size: 20px;

    @media (min-width: $bootstrap-md) {
      margin-bottom: 30px;
    }
  }

  .description {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 1.4em;

    @media (min-width: $bootstrap-md) {
      margin-bottom: 15px;
    }
  }

  .download {
    font-size: 20px;
    line-height: 1.4em;

    .info {
      color: $color-grey;
      margin-right: 12px;

      @media (min-width: $bootstrap-md) {
        margin-right: 20px;
      }
    }
  }
</style>
