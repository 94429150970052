<template>
  <component
    class="ContainerBlock"
    :is="tag"
    :class="{
      'with-image': halfImage,
      'image-left': halfImagePosition === 'left' && !isSmall,
      'pt-0 pb-0': noPad
    }"
    :style="rootElStyle"
  >
    <div
      v-if="halfImage && !isSmall"
      :class="['half-image', imgAlignmentClasses]"
      :style="imgStyle"
    >
      &nbsp;
    </div>
    <img v-if="halfImage && isSmall" :src="halfImage" alt="" class="mobile-image resp-image">
    <div :class="{'container': centered, 'pl-0 pr-0': noPad}">
      <div class="wrapper">
        <template v-if="halfImage">
          <div class="row">
            <div class="col-lg-6" :class="{
                'pr-5': !isSmall && halfImage && halfImagePosition === 'right',
                'pl-5': !isSmall && halfImage && halfImagePosition === 'left',
                'offset-6': !isSmall && halfImagePosition === 'left'
              }">
              <slot></slot>
            </div>
          </div>
        </template>
        <template v-else>
          <slot></slot>
        </template>
      </div>
    </div>
  </component>
</template>

<script>
  import {MobileHandlers} from '@mixins/mobile-handlers';

  export default {
    name: 'Container',
    mixins: [
      MobileHandlers
    ],
    props: {
      backgroundImage: {type: String | null, default: null},
      tag: {type: String, default: 'div'},
      halfImage: {type: String},
      imgAlignmentClasses: {type: Array, default: () => ['left-middle', 'right-zero', 'bottom-zero', 'top-zero']},
      halfImagePosition: {type: 'right' | 'left', default: 'right'},
      zIndex: {type: Number, default: 0},
      noPad: {type: Boolean, default: false},
      centered: {type: Boolean, default: true},
      customImgStyle: {
        type: Object, default: () => {
        }
      }
    },
    computed: {

      imgStyle() {
        return {
          ...{
            backgroundImage: 'url(' + this.halfImage + ')'
          },
          ...this.customImgStyle
        };
      },

      isSmall() {
        return this.$viewportWidth < 992;
      },
      bgImageStyle() {
        /* Temporarily empty obj, TODO: calculate img height */
        return {};
        /*
        return {
          paddingTop: (this.isMobile) ? '30vh' : null,
          backgroundSize: '100%',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat'
        }*/
      },
      rootElStyle() {
        return {
          zIndex: this.zIndex,
          backgroundImage: this.backgroundImage ? `url(${this.backgroundImage})` : null,
          ...this.bgImageStyle
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  .ContainerBlock {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    @include responsive-property(padding-top, $section-padding);
    @include responsive-property(padding-bottom, $section-padding);

    // Theme: double-padding-top to be used when previous block has colored bg
    &.double-padding-top {
      @include responsive-property(padding-top, $section-double-padding);
    }

    // Theme: double-padding-bottom to be used when next block has colored bg
    &.double-padding-bottom {
      @include responsive-property(padding-bottom, $section-double-padding);
    }

    // Theme: half-padding-top
    &.half-padding-top {
      @include responsive-property(padding-top, $section-half-padding);
    }

    // Theme: half-padding-bottom
    &.half-padding-bottom {
      @include responsive-property(padding-bottom, $section-half-padding);
    }

    &.no-padding-top {
      @include responsive-property(padding-top, $section-no-padding);
    }

    &.no-padding-bottom {
      @include responsive-property(padding-bottom, $section-no-padding);
    }

    // Theme: last-section-padding-bottom
    &.last-section-padding-bottom {
      @include responsive-property(padding-bottom, $last-section-padding);
    }

    // Theme: first-section-padding-top
    &.first-section-padding-top {
      @include responsive-property(padding-top, $last-section-padding);
    }

    // Theme: regular-padding-top
    &.regular-padding-top {
      @include responsive-property(padding-top, $section-regular-padding);
    }

    // Theme: regular-padding-bottom
    &.regular-padding-bottom {
      @include responsive-property(padding-bottom, $section-regular-padding);
    }

    &.overflow-visible {
      overflow: visible;
    }

    &.height-fill {
      display: flex;
      flex-direction: column;

      > .container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        > .wrapper {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .with-image {
    position: relative;

    @media (max-width: $bootstrap-lg - 1) {
      padding-top: 0;

      .container {
        padding-top: 110px;
      }
    }

    .half-image {
      position: absolute;
      background: no-repeat 50% 50%;
      background-size: cover;
      z-index: 2;

      &.left-zero {
        left: 0;
      }

      &.left-middle {
        left: 50%;
      }

      &.right-zero {
        right: 0;
      }

      &.right-middle {
        right: 50%;
      }

      &.top-zero {
        top: 0;
      }

      &.top-middle {
        top: 50%;
      }

      &.bottom-zero {
        bottom: 0;
      }

      &.bottom-middle {
        bottom: 50%;
      }
    }

    .container {
      position: relative;
      z-index: 1;
    }

    &.image-left {
      .half-image {
        left: 0;
        right: 50%;
      }
    }
  }

  .ContainerBlock {
    // Grey theme
    &.grey {
      background: $color-grey-light;
    }

    // Merino theme
    &.merino {
      background: $color-merino;
    }

    // Yellow-ish theme
    &.champagne {
      background: fade-out($color-champagne, 0.75);

      &.homepage-champagne-pt {
        padding-top: 138px;
        padding-bottom:79px;

        @media (min-width: $bootstrap-md) {
          padding-top: 63px;
        }
      }
    }

    // Yellow-ish theme (solid)
    &.champagne-solid {
      background: $color-champagne;
    }

    // Blue theme
    &.blue {
      background: $color-blue;
      color: $color-white;
    }

    // Dark theme
    &.dark {
      background: $color-dark;
      color: $color-white;
    }

    // Pine tree theme
    &.pine-tree {
      background: $color-pine-tree;
      color: $color-white;
    }

    // green theme
    &.green {
      background: $color-green;
      color: $color-white;
    }

    // White theme
    &.white {
      background: $color-white;
    }

    // Overflow hidden theme
    &.overflow-hidden {
      overflow: hidden;
    }

    // Background fill left theme
    &.fill-left, &.fill-right {
      background: none;
      overflow: hidden;

      .container {
        position: relative;

        &:after {
          content: ' ';
          display: block;
          width: 3000px;
          right: 24px;
          top: -200px;
          bottom: -200px;
          position: absolute;
          z-index: 1;
        }
      }

      .wrapper {
        position: relative;
        z-index: 2;
        padding-right: 24px;
      }

      &.grey {
        .container:after {
          background: $color-grey-light;
        }
      }

      &.blue {
        .container:after {
          background: $color-blue;
        }
      }

      &.dark {
        .container:after {
          background: $color-dark;
        }
      }
    }

    // Background fill right theme
    &.fill-right {
      .container:after {
        right: auto;
        left: 24px;
      }

      .wrapper {
        padding-right: 0;
        padding-left: 24px;
      }
    }

    &.bg-image-md,
    &.bg-image-pb-0-md {
      @media(max-width: $bootstrap-md - 1px) {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100%;
        padding-top: 30vh;
      }
    }

    &.bg-image-pb-0-md {
      @media(max-width: $bootstrap-md - 1px) {
        padding-bottom: 0;
      }

      @media(max-width: $bootstrap-sm - 1px) {
        padding-top: 20vh;
      }
    }
  }
</style>
