<template>
  <row-col-responsive-cmp
    class="DistributorsAccordionContentCmp with-separator-between-rows"
    :data-cols-number="dataColsNumber"
    :data-layout-col-size="$viewportWidth >= 768 ? '2' : '1'"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </row-col-responsive-cmp>
</template>

<script>
import RowColResponsiveCmp from '@components/layout/row-col-responsive-cmp';

export default {
  name: 'DistributorsAccordionContentCmp',
  components: {RowColResponsiveCmp},
  props: {
    dataColsNumber: {
      Type: String,
      default: '3',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~$scss/_variables.scss";

.DistributorsAccordionContentCmp {
  & > /deep/ .row {
    .distributor-item-wrapper {
      padding-top: 32px;
      padding-bottom: 32px;

      @media (min-width: $bootstrap-md) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      a[href^="mailto:"] {
        color: $color-blue-lochmara;
        text-decoration: underline;
      }
    }

    &:first-child {
      .distributor-item-wrapper {
        padding-top: 28px !important;

        @media (min-width: $bootstrap-md) {
          padding-top: 36px !important;
        }

        @media (min-width: $bootstrap-lg) {
          padding-top: 26px !important;
        }
      }
    }

    &:last-child {
      .distributor-item-wrapper {
        padding-bottom: 0 !important;

        @media (min-width: $bootstrap-md) {
          padding-bottom: 8px !important;
        }
      }
    }
  }
}
</style>
