<template lang="pug">
  extends ./templates/multimedia-box/multimedia-box.pug
  block variables
    - const rootClass = 'VideoBox'

  block title
    span {{ytMeta.snippet.title}}

  block centered-icon
    svg.play-icon(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 64 64")
      circle(style="fill: #ffffff; opacity: 0.72;", cx="32", cy="32", r="32")
      path(style="fill: #00142f; opacity: 0.75;", d="M45,32,23,44V20Z")

  block sr-label
    template {{ytMeta.snippet.title}}

  block player
    youtube(
      :video-id="youtubeId",
      ref="player",
      :width="playerSize.width",
      :height="playerSize.height"
    )

  block statistics
    div.statistics-wrapper(v-if="!hideDuration")
      span.stat
        icon.stat-icon(name="clock1")
        | {{ytMeta.contentDetails['duration'] | duration().format('mm:ss', {trim: false})}}
      //span.stat
      //  icon.stat-icon(name="eye1")
      //  | {{ytMeta.statistics['viewCount']}}
      //span.stat(v-if="shareFromPreview")
      //  icon.stat-icon(name="share-alt1")

  block modal-title
    span(v-if="ytMeta") {{ ytMeta.snippet.title }}

  block modal-meta
    div(v-if="ytMeta")
      span.stat
        icon.stat-icon(name="clock1")
        | {{ytMeta.contentDetails['duration'] | duration().format('mm:ss', {trim: false})}}
      //span.stat
      //  icon.stat-icon(name="eye1")
      //  | {{ytMeta.statistics['viewCount']}}
      //span.stat
      //  icon(name="share-alt1")
</template>

<script>
  import MultimediaBox from './templates/multimedia-box/multimedia-box';
  import moment from 'moment';
  import axios from 'axios';

  export default {
    name: 'VideoBox',
    extends: MultimediaBox,
    props: {
      youtubeId: {type: String, required: true},
      small: {type: Boolean, default: false},
      xSmall: {type: Boolean, default: false},
      shareFromPreview: {type: Boolean, default: false},
      hideDuration: {type: Boolean, default: false}
    },
    data () {
      return {
        moment: moment,
        ytClient: axios.create({baseURL: 'https://www.googleapis.com/youtube/v3/videos'})
      };
    },
    computed: {
      posterImage () {
        // {url: string, width: number, height: number}
        if (this.ytMeta) {
          if (this.ytMeta.snippet['thumbnails']['maxres']) {
            return this.ytMeta.snippet['thumbnails']['maxres'];
          } else if (this.ytMeta.snippet['thumbnails']['standard']) {
            return this.ytMeta.snippet['thumbnails']['standard'];
          } else if (this.ytMeta.snippet['thumbnails']['high']) {
            return this.ytMeta.snippet['thumbnails']['high'];
          } else if (this.ytMeta.snippet['thumbnails']['medium']) {
            return this.ytMeta.snippet['thumbnails']['medium'];
          } else if (this.ytMeta.snippet['thumbnails']['default']) {
            return this.ytMeta.snippet['thumbnails']['default'];
          } else return null;
        } else return null;
      },
      imageAlt () {
        return '';
      }
    },
    asyncComputed: {
      async ytMeta () {
        const info = (await this.ytClient.get('', {
          params: {
            part: 'snippet,statistics,contentDetails',
            id: this.youtubeId,
            key: this.$context['apiKeys']['google']
          }
        })).data;

        return {
          snippet: info['items'][0]['snippet'],
          statistics: info['items'][0]['statistics'],
          contentDetails: info['items'][0]['contentDetails']
        };
      }
    },
    methods: {
      closeDialogHandler () {
        this.$refs['player'].player.stopVideo();
      },
      openDialogHandler () {
        this.$refs['player'].player.playVideo();
      }
    }
  };
</script>

<style lang="scss" src="./templates/multimedia-box/multimedia-box.scss" scoped></style>
<style lang="scss" scoped>
  @import '~$scss/variables';

  .VideoBox {
    .poster-wrapper {
      .play-icon {
        width: 64px;
        height: 64px;
      }
    }

    .poster {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        background-color: fade-out($color-dark, 0.75);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }
    }
  }

  .statistics {
    margin-top: 0 !important;

    .statistics-wrapper {
      margin-top: 70px;
    }
  }

  .stat {
    display: inline-block;
    margin-right: 22px;

    &:last-child {
      margin-right: 0;
    }

    .stat-icon {
      margin-right: 10px;
    }
  }

  .modal-info {
    .stat {
      margin-right: 27px;
      margin-bottom: 10px;

      @media (min-width: $bootstrap-md) {
        margin-bottom: 0;
      }

      &:last-child {
        margin: 0;
      }

      .stat-icon {
        margin-right: 10px;
      }
    }
  }

  // Theme: raw
  .VideoBox.raw {
    .info {
      display: none;
    }
  }
 // Theme: press-room
  .VideoBox{
    &.press-room{
      .statistics{
        display: none;
      }
    }
  }
</style>
