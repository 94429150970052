<template>
  <component
    class="AspectRatioBox"
    :is="tag"
    :style="backgroundStyle"
  >
    <slot></slot>
  </component>
</template>

<script>
  export default {
    name: 'AspectRatioBox',
    props: {
      tag: {type: String, default: 'div'},
      ratio: {type: Number, required: true},
      image: {type: String},
    },
    computed: {
      backgroundStyle () {
        return {
          paddingTop: `${100 / this.ratio}%`,
          backgroundImage: this.image ? `url(${this.image})` : null,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .AspectRatioBox {
    position: relative;
    overflow: hidden;
    background: no-repeat 50% 50%;
    background-size: cover;

    > * {
      position: absolute;
      max-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // Theme: vertical
    &.vertical > * {
      max-width: none;
      max-height: 100%;
    }
  }
</style>