<template>
    <div v-if="productsOrFixture" class="GoodForYouProduct">
      <h3 v-if="productsOrFixture.title" class="title title-40">{{productsOrFixture.title}}</h3>
      <p v-if="productsOrFixture.subtitle" class="text text-24">{{productsOrFixture.subtitle}}</p>
      <div v-if="productsOrFixture.values && productsOrFixture.values.length" class="values">
        <div class="row">
          <div v-for="product in productsOrFixture.values" class="value col-6 col-sm-4 col-md-3 d-flex flex-column">
            <div class="wrapper flex-fill">
              <h4 v-if="product.label" class="label text-14 uppercase text-color-grey text-extra-bold text-line-height-medium">{{product.label}}</h4>
              <h3 v-if="product.value" class="title title-52">{{product.value}}</h3>
              <p v-if="product.notes" class="text text-light text-20 text-color-blue text-line-height-medium">{{product.notes}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'GoodForYouProduct',
    props: {
      products: { type: Object },
      fixture: { type: String, default: 'dryBreads' },
    },
    asyncComputed: {
      async productsOrFixture () {
        return this.products ? this.products : (await import('@fixtures/mission-fixture'))[this.fixture]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";
  @import "~$scss/utility";

  $products-padding: (
    $bootstrap-sm: 30px,
    $bootstrap-md: 40px,
    $bootstrap-lg: 50px,
  );

  .GoodForYouProduct {
    @include responsive-property(padding-top, $products-padding);
    @include responsive-property(padding-bottom, $products-padding);

    &:first-child {
      padding-top: 20px !important;
    }

    &:last-child {
      padding-bottom: 20px !important;
    }
  }

  .text {
    margin-top: 20px;
  }

  .values {
    @include responsive-property(padding-top, $products-padding);

    .row {
      margin-top: -25px;
      margin-bottom: -25px;
    }

    .value {
      padding-top: 25px;
      padding-bottom: 25px;

      .wrapper {
        padding-left: 16px;
        border-left: 1px solid rgba($color-grey, .25);
      }

      .title {
        margin-top: 25px;
        line-height: 1;
      }

      .text {
        margin-top: 10px;
      }
    }
  }
</style>
