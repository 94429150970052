<template>
  <label v-if="listOrFixture && listOrFixture.length && current" class="Languages" :aria-labelledby="`language-choice-${_uid}`">
    <span :id="`language-choice-${_uid}`" class="sr-only">{{ $pgettext('Header', 'Language choice') }}</span>
    <span :class="['select', `flag-${current.code}`]" v-if="!isMobile">
      <select @change="onChange($event)">
        <option v-for="language in listOrFixture" :value="language.code" :selected="current.code === language.code">
          <template v-if="short">{{language.code3}}</template>
          <template v-else>{{language.label}}</template>
        </option>
      </select>
      <icon name="angle-down"></icon>
    </span>
    <div class="myClass" v-else>
      <a
        href="#"
        v-for="language in listOrFixture"
        :data-value="language.code"
        :class="['text-bold', 'mobile-language', current.code === language.code ? 'current' : '' ]"
        @click.prevent="onClickLanguage">
        <template v-if="short">{{language.code3}}</template>
        <template v-else>{{language.label}}</template>
      </a>
    </div>
  </label>
</template>

<script>
  import Icon from '@components/atoms/icon'
  import { MobileHandlers } from "@mixins/mobile-handlers"

  export default {
    name: 'Languages',
    components: {Icon},
    mixins: [
      MobileHandlers
    ],
    data () {
      return {
        value: null
      }
    },
    props: {
      list: {type: Array},
      short: {type: Boolean, default: false}
    },
    methods: {
      onChange(e) {
        this.value = e.target.value
        document.location  = this.listOrFixture.find(item => item.code === this.value).link
      },
      onClickLanguage(e){
        this.value = e.currentTarget.dataset['value']
        document.location  = this.listOrFixture.find(item => item.code === this.value).link
      }
    },
    asyncComputed: {
      async current() {
        if (this.listOrFixture) {
          return this.listOrFixture.find(item => {
            return this.value ? (item.code === this.value) : item.current
          })
        }
      },
      async listOrFixture() {
        return this.list ? this.list : (await import('@fixtures/footer-fixture')).footerLangFixture
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .Languages {
    font-size: 14px;
    letter-spacing: 0.1em;
    display: inline-block;
    position: relative;

    .select {
      display: block;
      position: relative;

      select {
        cursor: pointer;
        display: block;
        padding-right: 20px;
        padding-left: 36px;
        color: $color-white;
        transition: color .3s ease;

        option {
          color: $color-black;
        }
      }

      &:before {
        content: ' ';
        display: block;
        height: 18px;
        width: 27px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -9px;
        background: no-repeat 50% 50%;
        background-size: 100% 100%;
        pointer-events: none;
      }

      &.flag-it:before {
        background-image: url(~@images/flags/it.svg)
      }

      &.flag-en:before {
        background-image: url(~@images/flags/gb.svg)
      }

      .icon {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        text-align: center;
        right: 0;
        top: 50%;
        margin-top: -8px;
        color: $color-white;
        pointer-events: none;
      }

      &:hover {
        select {
          color: rgba($color-white, .5);
        }
      }
    }
  }

  .mobile-language{
    padding: 15px;
    position: relative;
    color: rgba(255,255,255,0.5);
    &.current{
      color: $color-white
    }
    &:last-child{
      padding-right: 0;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 16px;
        margin-top: -8px;
        width: 1px;
        background: rgba(255,255,255,0.5);
      }
    }
  }

  // simple variant (for header)
  .simple {
    .select {
      &:before, .icon {
        display: none;
      }

      select {
        font-size: 14px;
        height: 16px;
        line-height: 1;
        font-weight: $extra-bold;
        padding: 0 15px;
        text-transform: uppercase;
        text-align: center;
        text-align-last: center;
        border-left: 1px solid rgba($color-white, .5);
        border-right: 1px solid rgba($color-white, .5);
      }
    }
  }
  // inverted variant
  .Languages.inverted {
    .select {
      select {
        color: $color-blue;
        border-color: rgba($color-dark, .25);
      }

      &:hover {
        select {
          color: rgba($color-blue, .75);
        }
      }
    }
  }

  .Languages.no-borders {
    .select {
      margin: 0 -10px;

      select {
        border: 0;
        padding: 0 10px;
      }
    }
  }
</style>
