<template>
  <span class="icon" :class="'icon-' + name"></span>
</template>

<script>
  export default {
    name: 'Icon',
    props: {
      name: {
        type: String,
        required: true,
      }
    }
  }
</script>