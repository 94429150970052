import * as queryString from 'query-string'

export function cleanQueryParams (params) {
  return Object.getOwnPropertyNames(params).reduce((acc, key) => {
    if (
      acc[key] === null ||
      acc[key] === undefined ||
      acc[key] === '' ||
      (Array.isArray(acc[key]) && acc[key].length === 0)
    ) {
      delete acc[key]
    }
    return acc
  }, params)
}

export function getQueryStringParams(url) {
  return queryString.parseUrl(url)
}

export function renderQueryStringToUrl (url, params) {
  return `${url}?${queryString.stringify(cleanQueryParams(params))}`
}

export function updateQueryString (params) {
  const locationParams = queryString.parseUrl(location.href)
  const newQueryParams = Object.assign(locationParams.query, params)
  const cleanQuery = cleanQueryParams(newQueryParams)

  const newUrl = Object.getOwnPropertyNames(cleanQuery).length > 0 ?
    `${locationParams.url}?${queryString.stringify(cleanQuery)}` :
    `${locationParams.url}`
  history.replaceState({}, document.title, newUrl)
}