<template>
  <div :class="['swiper-slide', classes]" :style="currentVerticalGutter">
    <slot></slot>
  </div>
</template>

<script>
  import { fallback } from '@helpers/responsive-utilities'

  export default {
    name: 'CarouselItem',
    methods: {
      getSwiper() {
        return this.$parent.swiper
      }
    },
    computed: {
      classes () {
        return this.$parent.disabled ? this.$parent.disabledColClasses : null
      },
      currentVerticalGutter () {
        if (this.$parent.disabled) {
          const gutter = fallback(this.$parent.disabledColVerticalGutter, this.$parent.currentStep)
          return {paddingTop: `${gutter}px`, paddingBottom: `${gutter}px`}
        } else {
          return null
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  // Theme: equal height
  .equal-height {
    height: auto;
  }
</style>