import Vue from 'vue'

Vue.config.ignoredElements = [
  'django-cms-template',
  'django-cms-plugin',
  'cms-template',
  'cms-plugin',
];

export default {
  data () {
    return {
      DjangoCMSPatch: null
    }
  },
  created () {
    this.DjangoCMSPatch = new VueDjangoCMSPatch(this)
  }
}

// Patch for Vue.js to enable DjangoCMS's "double click to edit" feature.
//
// To set it up you should call `new VueDjangoCMSPatch(this)` in a `created`
// hook on a Vue instance.
// And add 'django-cms-template' and 'cms-plugin' to `Vue.config.ignoredElements`.
const VueDjangoCMSPatch = function (instance) {
  this.instance = instance

  this._canBePatched = function () {
    return window.CMS !== undefined && window.CMS.config.mode === 'draft'
  }

  this._replaceTemplateTags = function () {
    let templates = document.querySelectorAll('template.cms-plugin')
    templates.forEach(t => {
      let cmsTemplate = document.createElement('django-cms-template')
      cmsTemplate.className = t.className
      t.parentNode.insertBefore(cmsTemplate, t)
      t.parentNode.removeChild(t)
    })
  }

  this._moveScriptTags = function () {
    let scripts = document.querySelectorAll(this.instance.$options.el + ' script[data-cms]')
    scripts.forEach(s => {
      s.parentNode.removeChild(s)
      document.body.appendChild(s)
    })
  }

  this._fixTemplateParents = function () {
    let templatePatches = document.querySelectorAll(this.instance.$options.el + ' template-patch')
    templatePatches.forEach(t => {
      const inner = t.innerHTML
      const newTemplate = document.createElement('template')
      newTemplate.innerHTML = inner
      newTemplate.setAttribute('slot', t.getAttribute('slot'))
      t.parentNode.appendChild(newTemplate)
      t.parentNode.removeChild(t)
    })
  }

  this._cleanTemplateTags = function () {
    let cmsTemplates = document.querySelectorAll('django-cms-template')
    cmsTemplates.forEach(t => t.parentNode.removeChild(t))
  }

  this.refresh = function () {
    if (this._canBePatched()) {
      this.instance.$destroy()
      delete this.instance.$options.render  // Force re-render.
      this.instance = new this.instance.constructor(this.instance.$options)
      window.CMS.Plugin._initializeTree()
      const scripts = []
      window.CMS.$('script').each(function (i, el) {
        if (
          el.src !== '' &&
          el.src.indexOf('bundle.toolbar') === -1 &&
          el.src.indexOf('cookieconsent') === -1
        ) {
          window.CMS.$(el).detach()
          scripts.push(el.src)
        }
      })

      window.CMS.$.ajaxSetup({
        cache: true
      })

      const loadScripts = function (scripts) {
        return scripts.reduce(function (cur, next) {
          return cur.then(window.CMS.$.getScript.bind(window.CMS.$, next))
        }, window.CMS.$.when())
      }
      loadScripts(scripts).then(function () { // example usage
      })
    }
  }

  this.patch = function () {
    if (this._canBePatched()) {
      this._replaceTemplateTags()
      this._moveScriptTags()
      this._fixTemplateParents()
      window.CMS.$(document).on('ready', this._cleanTemplateTags.bind(this))
      window.CMS.$(window).on('cms-content-refresh', this.refresh.bind(this))
    }
  }

  this.init = function () {
    if (this._canBePatched() && !this.instance.$options._cmsPatched) {
      this.patch()
      this.instance.$options._cmsPatched = true
    }
  }

  this.init()
}
