<template>
  <mounting-portal append :mount-to="portalTarget">
    <div :id="id" :class="classNames.base" ref="rootElement">
      <div
        data-a11y-dialog-hide
        tabIndex="-1"
        :class="classNames.overlay"
        @click="role === 'alertdialog' ? undefined : close"/>

      <component :is="dialogElement"
                 :role="role"
                 :class="classNames.element"
                 :aria-labelledby="titleId">
        <div role="document" :class="classNames.document">
          <button
            data-a11y-dialog-hide
            type="button"
            :aria-label="closeButtonLabel"
            @click="close"
            :class="classNames.closeButton">
            <slot name="closeButtonContent">
              {{ '\u00D7' }}
            </slot>
          </button>

          <component v-if="$slots['title']" :is="titleTag" :id="fullTitleId" :class="classNames.title">
            <slot name="title"/>
          </component>

          <slot/>
        </div>
      </component>
    </div>
  </mounting-portal>
</template>

<script>
  import A11yDialog from 'a11y-dialog';
  import { MountingPortal } from 'portal-vue';

  export default {
    name: 'AccessibleDialog',
    props: {
      id: {type: String, required: true},
      appRoot: {type: [String, Array], required: true},
      dialogRoot: {type: String, required: true},
      classNames: {type: Object, default: () => ({})},
      titleId: {type: String},
      closeButtonLabel: {type: String, default: 'Close this dialog window'},
      disableNative: {type: Boolean, default: false},
      role: {type: String, default: null},
      titleTag: {type: String, default: 'span'}
    },
    components: {
      MountingPortal
    },
    computed: {
      fullTitleId () {
        return this.titleId || this.id + '-title';
      },
      dialogElement () {
        return this.disableNative ? 'div' : 'dialog';
      },
      portalTarget () {
        return this.dialogRoot || this.appRoot;
      }
    },
    data () {
      return {
        dialog: null
      };
    },
    methods: {
      open () {
        this.dialog.show();
      },
      close () {
        this.dialog.hide();
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.dialog = new A11yDialog(this.$refs.rootElement, this.appRoot);
        this.$emit('dialog-ref', this.dialog);
        this.dialog.on('show', evt => this.$emit('a11y-dialog-show', evt));
        this.dialog.on('hide', evt => this.$emit('a11y-dialog-hide', evt));
      });
    },
    destroyed () {
      if (this.dialog) {
        this.dialog.destroy();
      }
      this.$emit('dialog-ref');
    }
  };
</script>
