<template>
  <div class="GoogleMap" aria-hidden="true" ref="mapcontainer">
    <div class="map-wrapper">
      <vue-map
        :class="['map', {ready}]"
        :options="mapSettings.config"
        :center="center"
        ref="map"
      >
        <vue-marker
          v-for="(marker, idx) in markers"
          :key="`marker-${idx}-${_uid}`"
          :position="marker.position"
          :options="markerSettings"
          :ref="`marker-${idx}-${_uid}`"
          :clickable="true"
          @click="markerClickHandler(marker)"
        ></vue-marker>
      </vue-map>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import { getMapSettings } from '@helpers/map-config'

  export default {
    name: 'GoogleMap',
    components: {
      VueMap: VueGoogleMaps.Map,
      VueMarker: VueGoogleMaps.Marker
    },
    props: {
      mapStyles: {type: String},
      center: {type: Object},
      markers: {type: Array}
    },
    data () {
      return {
        ready: false,
        map: undefined,
        gMapsApi: undefined
      }
    },
    computed: {
      mapSettings () {
        return getMapSettings(this.mapStyles)
      },
      markerSettings () {
        return {
          icon: {
            ...this.mapSettings.marker.icon,
            url: `${this.$context['assetsRoot']}img/${this.mapSettings.marker.icon.url}`
          }
        }
      }
    },
    methods: {
      markerClickHandler (marker) {
        // TODO marker click handler: currently noop
      },
      mapClickHandler ($evt) {
        console.log($evt)
        // TODO map click handler: currently noop
      },
      markerMouseoverHandler ($evt) {
        console.log($evt)
        // TODO map mouseover handler: currently noop
      },
      markerMouseoutHandler ($evt) {
        console.log($evt)
        // TODO map mouseout handler: currently noop
      }
    },
    created () {
      Vue.use(VueGoogleMaps, {
        load: {key: this.$context['apiKeys']['google_maps']},
        installComponents: false
      })
    },
    async mounted () {
      var accessibilityFix = () => {
        let container = this.$refs['mapcontainer'],
            iframeMap = container.getElementsByTagName('iframe');
        if (iframeMap.length) {
          iframeMap[0].title = 'google maps iframe';
        }
        ['gm-fullscreen-control', 'gm-ui-hover-effect'].forEach(function(className) {
          container.getElementsByClassName(className).forEach(function(elem) {
            elem.getElementsByTagName('img').forEach(function(imgTag) {
              if (!imgTag.alt) {
                imgTag.alt = 'UI Element';
              }
            });
          })
        })
      };
      var accessibilityInterval = setInterval(accessibilityFix, 1000);
      this.map = await this.$refs['map'].$mapPromise
      this.map.addListener('click', this.mapClickHandler)
      this.map.addListener('tilesloaded', () => {
        this.ready = true
        // Accessibility fix for google control img
        accessibilityFix();
        setTimeout(function(){clearInterval(accessibilityInterval)}, 5000);
      })
      this.gMapsApi = VueGoogleMaps.gmapApi().maps

      const markerRefs = Object.getOwnPropertyNames(this.$refs).filter(name => name.match(/^marker/))
      await markerRefs.asyncForEach(async (name) => {
        const marker = await this.$refs[name][0].$markerPromise
        marker.addListener('mouseover', this.markerMouseoverHandler)
        marker.addListener('mouseout', this.markerMouseoutHandler)
      })
      
    }
  }
</script>

<style lang="scss" scoped>
  .map-wrapper {
    position: relative;
    height: 560px;

    .map {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
