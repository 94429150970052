import { CountUp } from 'countup.js';
import ViewportMixins from '@mixins/viewport_mixins'

export default {
  install(Vue) {
    Vue.directive('number-transition', {
      bind (element, binding, vnode) {

        // Options https://github.com/inorganik/CountUp.js

        const defaults = {
          startVal: 0,
          duration: 500,
          useGrouping: false,
          easingFn: (t, b, c, d) => {
            t /= d/2; if (t < 1) return c/2*t*t + b; t--; return -c/2 * (t*(t-2) - 1) + b;
          }
        }
        const config = Object.assign(defaults, binding.value)
        const count = new CountUp(element, config.target, config)

        let fn = () => {
          if (ViewportMixins.methods.isElementXPercentInViewport(element, 100)) {
            count.start()
            window.removeEventListener('scroll', fn)
          }
        }

        element.innerText = '0'
        window.addEventListener('scroll', fn)
      },
    })
  }
}
