<template>
  <nav
    v-if="listOrFixture && listOrFixture.length"
    class="Socials"
    :aria-label="ariaLabel"
  >
    <ul :class="['socials-list', ulClasses]">
      <li v-for="item in listOrFixture" class="socials-item">
        <a :href="item.link" target="_blank" class="socials-item-link" :title="item.name">
          <icon :name="item.icon"></icon>
          <span class="hidden">{{item.name}}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import Icon from '@components/atoms/icon'

  export default {
    name: 'Socials',
    components: {Icon},
    props: {
      list: {
        type: Array
      },
      position: {
        type: String,
        default: null
      },
      ulClasses: {
        type: String,
        default: 'd-flex align-items-center justify-content-end justify-content-sm-start justify-content-md-end'
      }
    },
    computed: {
      ariaLabel () {
        if (this.position) {
          return this.$pgettext('Social links', `Social bar (${this.position})`)
        } else {
          return this.$pgettext('Social links', 'Social bar')
        }
      }
    },
    asyncComputed: {
      async listOrFixture () {
        return this.list ? this.list : (await import('@fixtures/footer-fixture')).footerSocialsFixture
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "~$scss/variables";

  .Socials {
    overflow: hidden;

    .socials-list {
      margin: 0 -6px;
    }

    .socials-item {
      padding: 0 6px;
    }

    @media (min-width: $bootstrap-lg) {
      .socials-list {
        margin: 0;
      }

      .socials-item {
        padding: 0 10px;
      }
    }

    .socials-item-link {
      display: block;
      font-size: 16px;
      padding: 4px;
      color: $color-white;
      transition: color .3s ease;

      &:hover {
        color: darken($color-grey-light, 10%);
      }
    }

    // inverted variant
    &.inverted {
      .socials-item-link {
        color: $color-blue;

        &:hover {
          color: rgba($color-blue, .75);
        }
      }
    }
  }
</style>
